import { Component } from '@angular/core';
import { Calc, CalcsService } from '@app/components/health-status/calcs.service';

@Component({
  selector: 'app-health-status',
  templateUrl: './health-status.component.html',
  styleUrls: ['./health-status.component.scss'],
  providers: [CalcsService]
})
export class HealthStatusComponent  {
  constructor(private calcsService: CalcsService) {}

  calcs: Calc[] = [];
  isShow: boolean = false;

  ngOnInit(){
    this.calcs = this.calcsService.getCalc();
  }

  onClick(event: any) {
    console.log('== event ==', event);
  }
}

