import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { Contact } from '@app/models/Contacts';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ContactsService {

    constructor(private http: HttpClient) {}

    getContacts() {
        return this.http.get<Contact[]>(`${environment.api_endpoint}/api/contacts`, {});
    }
}
