<div class="notificationContainer">

  <div *ngIf="items.length > 0">
    <div class="notificationContainer-title">
      <div class="notification-title">Уведомления</div>
      <div>
        <a (click)="markAsReadAll()" class="readAll">
          <img alt="logo" src="../../../assets/images/notification/Checkbox.png"/>Прочесть все</a>
      </div>
    </div>

    <div  class="notificationContainer-wrapper" >
    <div *ngFor="let doc of items">
      <div class="notificationContainer-notification" (click)="markAsReadRecord(doc.id)">

        <div class="flex-wrapper">
          <div class="notificationContainer-notification__icons">
          <div
            [ngClass]="{
              'notification-circle__read': doc.status === 'Read',
              'notification-circle': doc.status === 'Unread'}"
          ></div>
          <img class="notification-img" *ngIf="doc.status === 'Read'" alt="logo" src="../../../assets/images/notification/bellDisabled.png"/>
          <img class="notification-img" *ngIf="doc.status !== 'Read'" alt="logo" src="../../../assets/images/notification/bell.png"/>
          <!--      <img alt="logo" src="../../../assets/images/notification/message.png"/>-->
          </div>

          <div class="notification-flex">
            <div class="notification-date">{{getDateFormatted(doc.createdAt)}}</div>
            <div class="notificationContainer-notification__name">
              <div
                [ngClass]="{
                  'read notification-title': doc.status === 'Read',
                  'notification-title': doc.status === 'Unread'}"
              >{{doc.name}}</div>
              <div
                [ngClass]="{
                  'read notification-subtitle': doc.status === 'Read',
                  'notification-subtitle': doc.status === 'Unread'}"
              >{{doc.type}}</div>
            </div>
          </div>
        </div>

      </div>
    </div>

    </div>

  </div>


  <div *ngIf="!items.length" class="mt-5 text-center text-sm">
        Уведомления не найдены
    </div>
</div>
