import {Component, OnInit} from '@angular/core';
import {  MessageService } from 'primeng/api';
import { Constants } from '@app/helpers/constants';
import { StorageService } from '@app/services/storage.service';
import {NavigationEnd, Router} from "@angular/router";



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [MessageService],
})
export class HeaderComponent implements OnInit {

  currentUrl: string;
  constructor(
      private router: Router,
      private storage: StorageService
  ) {}

  getOrgName(): string {
    return Constants.ORG_NAME;
  }

  getOrgDeptName(): string {
    return Constants.ORG_DEPT_NAME;
  }

  isLoggedIn() {
    return this.storage.isLoggedIn() && this.storage.getLoaded();
  }

   ngOnInit(): void {
     this.router.events.subscribe(event => {
       if (event instanceof NavigationEnd) {
         this.currentUrl = event.url
       }
     });
  }


}
