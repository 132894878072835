<div class="my-profile-container">

  <div class="my-profile-container__mainFieldContainer">
    <div class="my-profile-container__mainFieldContainer__fieldContainer">
      <div>
        <div class="show-mobile">
          <div class="my-profile-container-name pt-3 w-max">Номер карты</div>
          <div class="my-profile-container-info"><div>{{getLastCase()}}</div></div>
        </div>

        <div class="my-profile-container-name pt-3 w-max">Имя</div>
        <div class="my-profile-container-info "><div>{{user.firstName}}</div></div>

        <div class="my-profile-container-name pt-3 w-max">Фамилия</div>
        <div class="my-profile-container-info "><div>{{user.lastName}}</div></div>

        <div class="my-profile-container-name pt-3 w-max">Отчество</div>
        <div class="my-profile-container-info "><div>{{user.middleName}}</div></div>

        <div class="small-field-container">

          <div class="small-field">
            <div>
              <div class="my-profile-container-name pt-3 w-max">Пол</div>
              <div class="my-profile-container-info__small"><div>{{user.gender}}</div></div>
            </div>
            <div>
              <div class="my-profile-container-name pt-3 w-max">Возраст</div>
              <div class="my-profile-container-info__small"><div>{{getAge(user.birthDate)}}</div></div>
            </div>
          </div>

          <div class="small-field">
            <div>
              <div class="my-profile-container-name pt-3 w-max">Дата рождения</div>
              <div class="my-profile-container-info__small"><div>{{getDateFormatted(user.birthDate)}}</div></div>
            </div>
            <div>
              <div class="my-profile-container-name pt-3 w-max">СНИЛС</div>
              <div class="my-profile-container-info__small"><div>{{user.snils}}</div></div>
            </div>
          </div>

        </div>
      </div>
      <div>
        <div class="show-desktop">
          <div class="my-profile-container-name pt-3 w-max">Номер карты</div>
          <div class="my-profile-container-info"><div>{{getLastCase()}}</div></div>
        </div>

        <div class="my-profile-container-name pt-3 w-max">Телефон</div>
        <div class="my-profile-container-info"><div>{{user.phone}}</div></div>

        <div class="my-profile-container-name pt-3 w-max">Место работы</div>
        <div class="my-profile-container-info tooltip">
          <div>{{getShortItemName(user.employeePlace)}}</div>
          <span class="tooltiptext">{{user.employeePlace}}</span>
        </div>

        <div class="my-profile-container-name pt-3 w-max">Должность</div>
        <div class="my-profile-container-info"><div>{{user.positionOfEmployment}}</div></div>
      </div>
    </div>
  </div>

  <div class="profile-address">
    <div class="profile-address-title">Адрес</div>
    <div class="my-profile-address tooltip">
      <div *ngIf="isMobile()">{{getShortItemName(user.address)}}</div>
      <div *ngIf="!isMobile()">{{user.address}}</div>
      <span class="tooltiptext">{{user.address}}</span>
    </div>
  </div>

  <div class="QR">
    <div>
      Ваш QR код СНИЛС
    </div>
    <qr-code [value]="user.snils" size="250" lightColor="#f8f8f9"></qr-code>
  </div>

</div>


