import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-gastritis',
  templateUrl: './gastritis.component.html',
  styleUrls: ['../calcStyle.component.scss']
})
export class GastritisComponent implements OnInit{
  form: FormGroup
  score: number = 0
  result: string = ''

  ngOnInit() {
    this.form = new FormGroup({
      0: new FormControl('', Validators.required),
      1: new FormControl('', Validators.required),
      2: new FormControl('', Validators.required),
      3: new FormControl('', Validators.required),
      4: new FormControl('', Validators.required),
      5: new FormControl('', Validators.required),
      6: new FormControl('', Validators.required),
      7: new FormControl('', Validators.required),
      8: new FormControl('', Validators.required),
      9: new FormControl('', Validators.required),
      10: new FormControl('', Validators.required)
    })
  }

  showResult(sum: number) {
    if (sum < 4) {
      this.result = 'Вероятнее всего, Ваше самочувствие ухудшилось не из-за гастрита.'
    } else if (sum >= 4 && sum <= 6) {
      this.result =  'У Вас присутствуют явные признаки начала эрозивного гастрита.'
    } else if (sum >= 7 && sum <= 8) {
      this.result = 'У Вас присутствуют признаки гастрита с повышенной кислотностью, необходима консультация врача.'
    } else {
      this.result = 'Вероятнее всего у Вас хронический гастрит'
    }
    return this.result
  }

  submit() {
    let value: any = ''
      const formData = {...this.form.value}
    let sum = 0;
    for (let key in formData) {
      sum += parseInt(formData[key]);
    }

    this.showResult(sum)
    this.score = sum;
    return sum
  }

}


