import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {Document} from "@app/models/Document";
import {Constants} from "@app/helpers/constants";
import {UserService} from "@app/services/user.service";
import {H} from "@app/helpers/helper";
import {StorageService} from "@app/services/storage.service";
import {SharedService} from "@app/services/shared.service";
import {environment} from '@environments/environment';
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
    tabs: MenuItem[];
    documents: Document[];
    isPdfViewerEnabled: boolean = false;
    pdfSrc: string;

    constructor(private userService: UserService,
                private storage: StorageService,
                private sharedService: SharedService,
                private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        const caseId: number = this.storage.getUser().lastCaseId

        let selectedCaseId: number = 0
        this.activatedRoute.params.subscribe(params => {
            selectedCaseId = params['caseId'];
        });

        this.tabs = H.getMedkartaTabs();
        if (selectedCaseId) {
            this.userService.getCaseDocuments(selectedCaseId, Constants.DOCUMENT_TYPE_RESULTS).subscribe({
                next: (docs) => {
                    this.documents = docs;
                }
            });
        } else {
            this.userService.getCaseDocuments(caseId, Constants.DOCUMENT_TYPE_RESULTS).subscribe({
                next: (docs) => {
                    this.documents = docs;
                }
            });
        }
        this.sharedService.sendBackButtonRoute('/my-data');
    }

    backToList() {
        this.isPdfViewerEnabled = false;
    }

    openDocument(doc: Document) {
        this.isPdfViewerEnabled = true;
        this.pdfSrc = `${environment.api_endpoint}/api/documents/${doc.id}/file` + '?access_token=' + this.storage.getUser().token;
    }

}
