<form class="form_container" [formGroup]="form" (ngSubmit)="submit(covid.value)" #covid="ngForm"> 
  <div class="form-control">
    <label class="calc-radio-label" for="1">
      <div class="d-grid">
        <div>1.</div>
        <div>Говорил ли Вам врач, что Вы болели или перенесли, в том числе «на ногах», новую коронавирусную инфекцию (COVID-19)?
          <div class="radio-container">
              <input class="radio" type="radio" name="1" formControlName="1" value="Да" ngModel>Да
              <input class="radio" type="radio" name="1" formControlName="1" value="Нет" ngModel>Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[1].value === 'Да'" class='optional'>
      <label class="calc-radio-label" for="2">
        Если «Да», то укажите ориентировочно сколько месяцев прошло с момента выздоровления и какой степени тяжести была новая коронавирусная инфекция (COVID-19)
      </label>
      <div class="radio-container">
        <input type="radio" name="2" formControlName="2" value="Легкая степень" class="radio" ngModel>Легкая степень
        <input type="radio" name="2" formControlName="2" value="Средняя степень и выше" class="radio" ngModel>Средняя степень и выше
        <input type="radio" name="2" formControlName="2" value="Не знаю" class="radio" ngModel>Не знаю
      </div>
      <input type="text" placeholder="Прошло месяцев..." formControlName="2.1" ngModel style="margin-bottom: 2rem; margin-top: -24px;">
      <div class="validation" *ngIf="form.controls[2.1].status === 'INVALID' && form.controls[2.1].touched">
        <small *ngIf="form.controls[2.1].errors?.pattern">Поле должно содержать только цифры</small>
      </div>
    </div>
    <label class="calc-radio-label" for="3">
      <div class="d-grid">
        <div>2.</div>
        <div>Ощущаете ли Вы в настоящее время снижение качества жизни (уровня здоровья) (КЖ) или работоспособности (РСП), связанное с перенесенной новой коронавирусной инфекцией (COVID-19)?
          <div class="radio-container">
          
              <input type="radio" name="3" formControlName="3" value="Да, ощущаю существенное снижение КЖ и/или РСП" class="radio" ngModel> Да, ощущаю существенное снижение КЖ и/или РСП
          
              <input type="radio" name="3" formControlName="3" value="Да, ощущаю незначительное снижение КЖ и/или РСП" class="radio" ngModel> Да, ощущаю незначительное снижение КЖ и/или РСП
         
              <input type="radio" name="3" formControlName="3" value="Нет, не ощущаю" class="radio" ngModel> Нет, не ощущаю
           
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="3.1">
      <div class="d-grid">
        <div>2.1.</div>
        <div>
          Оцените вклад появления/усиления одышки и снижения переносимости физической нагрузки и/или хронического кашля как причины снижения КЖ и/или РСП
          <div class="radio-container">
            <input type="radio" name="3.1" formControlName="3.1" value="Основной" class="radio" ngModel><div>Основной</div>
            <input type="radio" name="3.1" formControlName="3.1" value="Второстепенный" class="radio" ngModel><div>Второстепенный</div>
            <input type="radio" name="3.1" formControlName="3.1" value="Отсутствовал" class="radio" ngModel><div>Отсутствовал</div>
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="3.2">
      <div class="d-grid">
        <div>2.2.</div>
        <div>
          Оцените вклад появления/усиления болей в груди и/или сердцебиений, и/или отеков на ногах как причины снижения КЖ и/или РСП
          <div class="radio-container">
            <input type="radio" name="3.2" formControlName="3.2" value="Основной" class="radio" ngModel><div>Основной</div>
            <input type="radio" name="3.2" formControlName="3.2" value="Второстепенный" class="radio" ngModel><div>Второстепенный</div>
            <input type="radio" name="3.2" formControlName="3.2" value="Отсутствовал" class="radio" ngModel><div>Отсутствовал</div>
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="3.3">
      <div class="d-grid">
        <div>2.3.</div>
        <div>
          Оцените вклад появления/усиления усталости, и/или мышечных болей, и/или головной боли, и/или дизавтономии (функциональные нарушения регуляции деятельности желудочно-кишечного тракта, печени, почек, мочевого пузыря, легких, сердца, желез внутренней и внешней секреции, кровеносных и лимфатических сосудов), и/или когнитивных нарушений (снижение памяти, умственной работоспособности и других познавательных функций мозга) как причины снижения КЖ и/или РСП
          <div class="radio-container">
            <input type="radio" name="3.3" formControlName="3.3" value="Основной" class="radio" ngModel><div>Основной</div>
            <input type="radio" name="3.3" formControlName="3.3" value="Второстепенный" class="radio" ngModel><div>Второстепенный</div>
            <input type="radio" name="3.3" formControlName="3.3" value="Отсутствовал" class="radio" ngModel><div>Отсутствовал</div>
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="3.4">
      <div class="d-grid">
        <div>2.4.</div>
        <div>
          Оцените вклад нестабильного течения сахарного диабета (ранее протекавшего стабильно) или выявление сахарного диабета после перенесенного COVID-19 как причины снижения КЖ и/или РСП
          <div class="radio-container">
            <input type="radio" name="3.4" formControlName="3.4" value="Основной" class="radio" ngModel><div>Основной</div>
            <input type="radio" name="3.4" formControlName="3.4" value="Второстепенный" class="radio" ngModel><div>Второстепенный</div>
            <input type="radio" name="3.4" formControlName="3.4" value="Отсутствовал" class="radio" ngModel><div>Отсутствовал</div>
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="3.5">
      <div class="d-grid">
        <div>2.5.</div>
        <div>
          Оцените вклад выпадения волос или появление кожной сыпи как причины снижения КЖ и/или РСП
          <div class="radio-container">
            <input type="radio" name="3.5" formControlName="3.5" value="Основной" class="radio" ngModel><div>Основной</div>
            <input type="radio" name="3.5" formControlName="3.5" value="Второстепенный" class="radio" ngModel><div>Второстепенный</div>
            <input type="radio" name="3.5" formControlName="3.5" value="Отсутствовал" class="radio" ngModel><div>Отсутствовал</div>
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="3.6">
      <div class="d-grid">
        <div>2.6.</div>
        <div>
          Оцените вклад появления/усиления болей в суставах как причины снижения КЖ и/или РСП
          <div class="radio-container">
            <input type="radio" name="3.6" formControlName="3.6" value="Основной" class="radio" ngModel><div>Основной</div>
            <input type="radio" name="3.6" formControlName="3.6" value="Второстепенный" class="radio" ngModel><div>Второстепенный</div>
            <input type="radio" name="3.6" formControlName="3.6" value="Отсутствовал" class="radio" ngModel><div>Отсутствовал</div>
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="3.7">
      <div class="d-grid">
        <div>2.7.</div>
        <div>
          Оцените вклад потери вкуса и/или обоняния как причины снижения КЖ и/или РСП
          <div class="radio-container">
            <input type="radio" name="3.7" formControlName="3.7" value="Основной" class="radio" ngModel><div>Основной</div>
            <input type="radio" name="3.7" formControlName="3.7" value="Второстепенный" class="radio" ngModel><div>Второстепенный</div>
            <input type="radio" name="3.7" formControlName="3.7" value="Отсутствовал" class="radio" ngModel><div>Отсутствовал</div>
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="3.8">
      <div class="d-grid">
        <div>2.8.</div>
        <div>
          Отмечаете ли Вы сохранение повышенной температуры с момента выздоровления от новой коронавирусной инфекции (COVID-19)?
          <div class="radio-container">
            <input type="radio" name="3.8" formControlName="3.8" value="Основной" class="radio" ngModel><div>Основной</div>
            <input type="radio" name="3.8" formControlName="3.8" value="Второстепенный" class="radio" ngModel><div>Второстепенный</div>
            <input type="radio" name="3.8" formControlName="3.8" value="Отсутствовал" class="radio" ngModel><div>Отсутствовал</div>
          </div>
        </div>
      </div>
    </label>
    <div class="btn-container">
      <button class="btn" type="submit" [disabled]="form.invalid">Сохранить и отправить</button>
    </div>
  </div>
</form>
