<form
  [formGroup]="form"
  #dataForm="ngForm"
  class="form_container"
  (ngSubmit)="submit(dataForm.value)"
>
  <div class="form-control">
    <label class="calc-radio-label">
      <div class="d-grid">
        <div>
          1.
        </div>
        <div>
          Говорил ли Вам врач когда-либо, что у Вас имеется:
        </div>
      </div>
    </label>
    <label class="calc-radio-label mt-2" for="1.1">
      <div class="d-grid">
        <div>
          1.1.
        </div>
        <div>
          Гипертоническая болезнь (повышенное артериальное давление)?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.1"
              type="radio"
              class="radio"
              formControlName="1.1"
            />
            Да
            <input
              ngModel
              name="1.1"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.1"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[1.1].value === 'Да'" class="optional">
      <label class="calc-radio-label" for="1.1.1">Если «Да», то принимаете ли Вы препараты для снижения давления?</label>      
      <div class="radio-container">
        <input
          ngModel
          value="Да"
          name="1.1.1"
          type="radio"
          class="radio"
          formControlName="1.1.1"
        />
        Да
        <input
          ngModel
          value="Нет"
          name="1.1.1"
          type="radio"
          class="radio"
          formControlName="1.1.1"
        />
        Нет
      </div>
    </div>
    <label class="calc-radio-label" for="1.2">
      <div class="d-grid">
        <div>
          1.2.
        </div>
        <div>
          Ишемическая болезнь сердца (стенокардия)?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.2"
              type="radio"
              class="radio"
              formControlName="1.2"
            />
            Да
            <input
              ngModel
              name="1.2"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.2"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="1.3">
      <div class="d-grid">
        <div>
          1.3.
        </div>
        <div>
          Цереброваскулярное заболевание (заболевание сосудов головного мозга)?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.3"
              type="radio"
              class="radio"
              formControlName="1.3"
            />
            Да
            <input
              ngModel
              name="1.3"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.3"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="1.4">
      <div class="d-grid">
        <div>
          1.4.
        </div>
        <div>
          Хроническое заболевание бронхов или легких (хронический бронхит, эмфизема, бронхиальная астма)?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.4"
              type="radio"
              class="radio"
              formControlName="1.4"
            />
            Да
            <input
              ngModel
              name="1.4"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.4"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="1.5">
      <div class="d-grid">
        <div>
          1.5.
        </div>
        <div>
          Туберкулез (легких или иных локализаций)?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.5"
              type="radio"
              class="radio"
              formControlName="1.5"
            />
            Да
            <input
              ngModel
              name="1.5"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.5"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="1.6">
      <div class="d-grid">
        <div>
          1.6.
        </div>
        <div>
          Сахарный диабет или повышенный уровень сахара в крови?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.6"
              type="radio"
              class="radio"
              formControlName="1.6"
            />
            Да
            <input
              ngModel
              name="1.6"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.6"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[1.6].value === 'Да'" class="optional">
      <label class="calc-radio-label" for="1.6.1">Если «Да», то принимаете ли Вы препараты для снижения уровня сахара?</label>
      <div class="radio-container">
        <input
          ngModel
          value="Да"
          name="1.6.1"
          type="radio"
          class="radio"
          formControlName="1.6.1"
        />
        Да
        <input
          ngModel
          value="Нет"
          name="1.6.1"
          type="radio"
          class="radio"
          formControlName="1.6.1"
        />
        Нет
      </div>
    </div>
    <label class="calc-radio-label" for="1.7">
      <div class="d-grid">
        <div>
          1.7.
        </div>
        <div>
          Заболевания желудка (гастрит, язвенная болезнь)?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.7"
              type="radio"
              class="radio"
              formControlName="1.7"
            />
            Да
            <input
              ngModel
              name="1.7"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.7"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="1.8">
      <div class="d-grid">
        <div>
          1.8.
        </div>
        <div>
          Хроническое заболевание почек?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.8"
              type="radio"
              class="radio"
              formControlName="1.8"
            />
            Да
            <input
              ngModel
              name="1.8"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.8"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="1.9">
      <div class="d-grid">
        <div>
          1.9.
        </div>
        <div>
          Злокачественное новообразование?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.9"
              type="radio"
              class="radio"
              formControlName="1.9"
            />
            Да
            <input
              ngModel
              name="1.9"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.9"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[1.9].value === 'Да'" class="optional">
      <label class="calc-radio-label" for="1.9.1">Если «Да», то какое?</label>
        <input
          ngModel
          type="text"
          formControlName="1.9.1"
          placeholder="Злокачественное новообразование..."
        />
    </div>
    <label class="calc-radio-label" for="1.10">
      <div class="d-grid">
        <div>
          1.10.
        </div>
        <div>
          Повышенный уровень холестерина?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.10"
              type="radio"
              class="radio"
              formControlName="1.10"
            />
            Да
            <input
              ngModel
              value="Нет"
              name="1.10"
              type="radio"
              class="radio"
              formControlName="1.10"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[1.10].value === 'Да'" class="optional">
      <label class="calc-radio-label" for="1.10.1">
        <div class="d-grid">
          <div>
            Если «Да», то принимаете ли Вы препараты для снижения уровня холестерина?
          </div>
        </div>
      </label>
      <div class="radio-container">
        <input
          ngModel
          value="Да"
          type="radio"
          name="1.10.1"
          class="radio"
          formControlName="1.10.1"
        />
        Да
        <input
          ngModel
          value="Нет"
          type="radio"
          name="1.10.1"
          class="radio"
          formControlName="1.10.1"
        />
        Нет
      </div>
    </div>
    <label class="calc-radio-label" for="2">
      <div class="d-grid">
        <div>
          2.
        </div>
        <div>
          Был ли у Вас инфаркт миокарда?
          <div class="radio-container">
            <input
              ngModel
              name="2"
              value="Да"
              type="radio"
              class="radio"
              formControlName="2"
            />
            Да
            <input
              ngModel
              name="2"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="2"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="3">
      <div class="d-grid">
        <div>
          3.
        </div>
        <div>
          Был ли у Вас инсульт?
          <div class="radio-container">
            <input
              ngModel
              name="3"
              value="Да"
              type="radio"
              class="radio"
              formControlName="3"
            />
            Да
            <input
              ngModel
              name="3"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="3"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="4">
      <div class="d-grid">
        <div>
          4.
        </div>
        <div>
          Был ли инфаркт миокарда или инсульт у Ваших близких родственников в молодом или среднем возрасте (до 65 лет у матери или родных сестер или до 55 лет у отца или родных братьев)?
          <div class="radio-container">
            <input
              ngModel
              name="4"
              value="Да"
              type="radio"
              class="radio"
              formControlName="4"
            />
            Да
            <input
              ngModel
              name="4"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="4"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="5">
      <div class="d-grid">
        <div>
          5.
        </div>
        <div>
          Были ли у Ваших близких родственников в молодом или среднем возрасте злокачественные новообразования (легкого, желудка, кишечника, толстой или прямой кишки, предстательной железы, молочной железы, матки, опухоли других локализаций) или полипоз желудка, семейный аденоматоз/диффузный полипоз толстой кишки?
          <div class="radio-container">
            <input
              ngModel
              name="5"
              value="Да"
              type="radio"
              class="radio"
              formControlName="5"
            />
            Да
            <input
              ngModel
              name="5"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="5"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="6">
      <div class="d-grid">
        <div>
          6.
        </div>
        <div>
          Возникает ли у Вас, когда поднимаетесь по лестнице, идете в гору или спешите, или при выходе из теплого помещения на холодный воздух, боль или ощущение давления, жжения, тяжести или явного дискомфорта за грудиной и (или) в левой половине грудной клетки, и (или) в левом плече, и (или) в левой руке?
          <div class="radio-container">
            <input
              ngModel
              name="6"
              value="Да"
              type="radio"
              class="radio"
              formControlName="6"
            />
            Да
            <input
              ngModel
              name="6"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="6"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[6].value === 'Да'" class="optional">
      <label class="calc-radio-label" for="7">Если на вопрос 6 ответ «Да», то указанные боли/ощущения/дискомфорт исчезают сразу или через 5 – 10 мин после прекращения ходьбы/адаптации к холоду и (или) после приема нитроглицерина?</label>
      <div class="radio-container">
        <input
          ngModel
          name="7"
          value="Да"
          type="radio"
          class="radio"
          formControlName="7"
        />
        Да
        <input
          ngModel
          name="7"
          value="Нет"
          type="radio"
          class="radio"
          formControlName="7"
        />
        Нет
      </div>
    </div>
    <label class="calc-radio-label" for="8">
      <div class="d-grid">
        <div>
          7.
        </div>
        <div>
          Возникала ли у Вас когда-либо внезапная кратковременная слабость или неловкость при движении в одной руке (ноге) либо руке и ноге одновременно так, что Вы не могли взять или удержать предмет, встать со стула, пройтись по комнате?
          <div class="radio-container">
            <input
              ngModel
              name="8"
              value="Да"
              type="radio"
              class="radio"
              formControlName="8"
            />
            Да
            <input
              ngModel
              name="8"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="8"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="9">
      <div class="d-grid">
        <div>
          8.
        </div>
        <div>
          Возникало ли у Вас когда-либо внезапное без явных причин кратковременное онемение в одной руке, ноге или половине лица, губы или языка?
          <div class="radio-container">
            <input
              ngModel
              name="9"
              value="Да"
              type="radio"
              class="radio"
              formControlName="9"
            />
            Да
            <input
              ngModel
              name="9"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="9"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="10">
      <div class="d-grid">
        <div>
          9.
        </div>
        <div>
          Возникала ли у Вас когда-либо внезапно кратковременная потеря зрения на один глаз?
          <div class="radio-container">
            <input
              ngModel
              name="10"
              value="Да"
              type="radio"
              class="radio"
              formControlName="10"
            />
            Да
            <input
              ngModel
              name="10"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="10"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="11">
      <div class="d-grid">
        <div>
          10.
        </div>
        <div>
          Бывают ли у Вас ежегодно периоды ежедневного кашля с отделением мокроты на протяжении примерно 3-х месяцев в году?
          <div class="radio-container">
            <input
              ngModel
              name="11"
              value="Да"
              type="radio"
              class="radio"
              formControlName="11"
            />
            Да
            <input
              ngModel
              name="11"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="11"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="12">
      <div class="d-grid">
        <div>
          11.
        </div>
        <div>
          Бывают ли у Вас свистящие или жужжащие хрипы в грудной клетке при дыхании, не проходящие при откашливании?
          <div class="radio-container">
            <input
              ngModel
              name="12"
              value="Да"
              type="radio"
              class="radio"
              formControlName="12"
            />
            Да
            <input
              ngModel
              name="12"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="12"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="13">
      <div class="d-grid">
        <div>
          12.
        </div>
        <div>
          Бывало ли у Вас когда-либо кровохарканье?
          <div class="radio-container">
            <input
              ngModel
              name="13"
              value="Да"
              type="radio"
              class="radio"
              formControlName="13"
            />
            Да
            <input
              ngModel
              name="13"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="13"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="14">
      <div class="d-grid">
        <div>
          13.
        </div>
        <div>
          Беспокоят ли Вас боли в области верхней части живота (в области желудка), отрыжка, тошнота, рвота, ухудшение или отсутствие аппетита?
          <div class="radio-container">
            <input
              ngModel
              name="14"
              value="Да"
              type="radio"
              class="radio"
              formControlName="14"
            />
            Да
            <input
              ngModel
              name="14"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="14"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="15">
      <div class="d-grid">
        <div>
          14.
        </div>
        <div>
          Бывает ли у Вас неоформленный (полужидкий) черный или дегтеобразный стул?
          <div class="radio-container">
            <input
              ngModel
              name="15"
              value="Да"
              type="radio"
              class="radio"
              formControlName="15"
            />
            Да
            <input
              ngModel
              name="15"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="15"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="16">
      <div class="d-grid">
        <div>
          15.
        </div>
        <div>
          Похудели ли Вы за последнее время без видимых причин (т.е. без соблюдения диеты или увеличения физической активности и пр.)?
          <div class="radio-container">
            <input
              ngModel
              name="16"
              value="Да"
              type="radio"
              class="radio"
              formControlName="16"
            />
            Да
            <input
              ngModel
              name="16"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="16"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="17">
      <div class="d-grid">
        <div>
          16.
        </div>
        <div>
          Бывает ли у Вас боль в области заднепроходного отверстия?
          <div class="radio-container">
            <input
              ngModel
              name="17"
              value="Да"
              type="radio"
              class="radio"
              formControlName="17"
            />
            Да
            <input
              ngModel
              name="17"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="17"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="18">
      <div class="d-grid">
        <div>
          17.
        </div>
        <div>
          Бывают ли у Вас кровяные выделения с калом?
          <div class="radio-container">
            <input
               ngModel
               name="18"
               value="Да"
               type="radio"
               class="radio"
               formControlName="18"
             />
             Да
             <input
               ngModel
               name="18"
               value="Нет"
               type="radio"
               class="radio"
               formControlName="18"
             />
             Нет
           </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="19">
      <div class="d-grid">
        <div>
          18.
        </div>
        <div>
          Курите ли Вы? (курение одной и более сигарет в день)
          <div class="radio-container">
            <input
              ngModel
              name="19"
              value="Да"
              type="radio"
              class="radio"
              formControlName="19"
            />
            Да
           <input
              ngModel
              name="19"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="19"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[19].value === 'Да'" class='optional'>
      <label class="calc-radio-label" for="20">Если Вы курите, то сколько в среднем сигарет в день выкуриваете?</label>
        <input
          ngModel
          type="text"
          formControlName="20"
          placeholder="Сигарет в день..."
        />
    </div>
    <label class="calc-radio-label" for="21">
      <div class="d-grid">
        <div>
          19.
        </div>
        <div>
          Сколько минут в день Вы тратите на ходьбу в умеренном или быстром темпе (включая дорогу до места работы и обратно)?
          <div class="radio-container">
            <input
              ngModel
              name="21"
              type="radio"
              class="radio"
              value="До 30 минут"
              formControlName="21"
            />
              До 30 минут
            <input
              ngModel
              name="21"
              type="radio"
              class="radio"
              formControlName="21"
              value="30 минут и более"
            />
              30 минут и более
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="22">
      <div class="d-grid">
        <div>
          20.
        </div>
        <div>
          Присутствует ли в Вашем ежедневном рационе 2 и более порции фруктов или овощей? (1 порция фруктов = 200 г. 1 порция овощей, не считая картофеля = 200 г.)
          <div class="radio-container">
            <input
              ngModel
              name="22"
              value="Да"
              type="radio"
              class="radio"
              formControlName="22"
            />
            Да
            <input
              ngModel
              name="22"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="22"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="23">
      <div class="d-grid">
        <div>
          21.
        </div>
        <div>
          Имеете ли Вы привычку подсаливать приготовленную пищу, не пробуя ее?
          <div class="radio-container">
            <input
              ngModel
              name="23"
              value="Да"
              type="radio"
              class="radio"
              formControlName="23"
            />
            Да
            <input
              ngModel
              name="23"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="23"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="24">
      <div class="d-grid">
        <div>
          22.
        </div>
        <div>
          Принимали ли Вы за последний год психотропные или наркотические вещества без назначения врача?
          <div class="radio-container">
            <input
              ngModel
              name="24"
              value="Да"
              type="radio"
              class="radio"
              formControlName="24"
            />
            Да
            <input
              ngModel
              name="24"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="24"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="25">
      <div class="d-grid">
        <div>
          23.
        </div>
        <div>Как часто Вы употребляете алкогольные напитки?
          <div class="radio-container">
            <input
              ngModel
              name="25"
              type="radio"
              class="radio"
              value="Никогда"
              formControlName="25"
            />
              Никогда
            <input
              ngModel
              name="25"
              type="radio"
              class="radio"
              formControlName="25"
              value="Раз в месяц и реже"
            />
              Раз в месяц и реже
            <input
              ngModel
              name="25"
              type="radio"
              class="radio"
              formControlName="25"
              value="2 - 4 раза в месяц"
            />
              2 - 4 раза в месяц
            <input
              ngModel
              name="25"
              type="radio"
              class="radio"
              formControlName="25"
              value="2 - 3 раза в неделю"
            />
              2 - 3 раза в неделю
            <input
              ngModel
              name="25"
              type="radio"
              class="radio"
              formControlName="25"
              value="4 и более раз в неделю"
            />
              4 и более раз в неделю
          </div>
        </div>
      </div>
    </label>
    <div *ngIf=" (form.controls[25].value !== 'Никогда') && (form.controls[25].value !== '')" class='optional'>
      <label class="calc-radio-label" for="26">Какое количество алкогольных напитков (сколько порций) вы выпиваете обычно за один раз? 1 порция равна ИЛИ 30 мл крепкого алкоголя (водки) ИЛИ 100 мл сухого вина ИЛИ 300 мл пива</label>
      <div class="radio-container">
        <input
        ngModel
        name="26"
        type="radio"
        class="radio"
        value="1 - 2 порции"
        formControlName="26"
        />
        1 - 2 порции
        <input
        ngModel
        name="26"
        type="radio"
        class="radio"
        value="3 - 4 порции"
        formControlName="26"
        />
        3 - 4 порции
        <input
        ngModel
        name="26"
        type="radio"
        class="radio"
        value="5 - 6 порций"
        formControlName="26"
        />
        5 - 6 порций
        <input
        ngModel
        name="26"
        type="radio"
        class="radio"
        value="7 - 9 порций"
        formControlName="26"
        />
        7 - 9 порций
        <input
        ngModel
        name="26"
        type="radio"
        class="radio"
        formControlName="26"
        value="10 или более порций"
        />
        10 или более порций
      </div>
    </div>
    <label class="calc-radio-label" for="27">
      <div class="d-grid">
        <div>
          24.
        </div>
        <div>
          Есть ли у Вас другие жалобы на свое здоровье, не вошедшие в настоящую анкету и которые Вы бы хотели сообщить врачу (фельдшеру)?
          <div class="radio-container">
            <input
              ngModel
              name="27"
              value="Да"
              type="radio"
              class="radio"
              formControlName="27"
            />
            Да
            <input
              ngModel
              name="27"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="27"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[27].value === 'Да'" class="optional">
      <label class="calc-radio-label" for="28">Опишите Ваши жалобы</label>
        <input
          ngModel
          type="text"
          formControlName="28"
          placeholder="Дополнительно..."
        />
    </div>
    <div class="btn-container">
      <button class="btn" type="submit" [disabled]="form.invalid">
        Сохранить и отправить
      </button>
    </div>
  </div>
</form>
