<form class="calcCard" [formGroup]="form" (ngSubmit)="submit()">
        <div class="calcWrapper">
          <div class="radio-field">

      <div class="radio-control">
        <label class="calc-radio-label" for="0">1. У вас появляется ранняя близорукость?</label>
        <div class="radio-input-container">
          <input type="radio" name="0" formControlName="0" value="1" class="radio"> Да
          <input type="radio" name="0" formControlName="0" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="1">2. Ваши зубы стали более хрупкими?</label>
        <div class="radio-input-container">
          <input type="radio" name="1" formControlName="1" value="1" class="radio"> Да
          <input type="radio" name="1" formControlName="1" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="2">3. Страдаете ли Вы бессонницей?</label>
        <div class="radio-input-container">
          <input type="radio" name="2" formControlName="2" value="1" class="radio"> Да
          <input type="radio" name="2" formControlName="2" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="3">4. Появились боли в суставах?</label>
        <div class="radio-input-container">
          <input type="radio" name="3" formControlName="3" value="1" class="radio"> Да
          <input type="radio" name="3" formControlName="3" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="4">5. Вы стали более раздражительны?</label>
        <div class="radio-input-container">
          <input type="radio" name="4" formControlName="4" value="1" class="radio"> Да
          <input type="radio" name="4" formControlName="4" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="5">6. Вас беспокоит жжение во рту и в горле?</label>
        <div class="radio-input-container">
          <input type="radio" name="5" formControlName="5" value="1" class="radio"> Да
          <input type="radio" name="5" formControlName="5" value="0" class="radio"> Нет
        </div>
      </div>
    </div>

    <div class="radio-field">
      <div class="radio-control">
        <label class="calc-radio-label" for="6">7. Замечаете ли за собой резкие спады настроения?</label>
        <div class="radio-input-container">
          <input type="radio" name="6" formControlName="6" value="1" class="radio"> Да
          <input type="radio" name="6" formControlName="6" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="7">8. Вас беспокоит диарея?</label>
        <div class="radio-input-container">
          <input type="radio" name="7" formControlName="7" value="1" class="radio"> Да
          <input type="radio" name="7" formControlName="7" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="8">9 Вы стали более возбудимы?</label>
        <div class="radio-input-container">
          <input type="radio" name="8" formControlName="8" value="1" class="radio"> Да
          <input type="radio" name="8" formControlName="8" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="9">10. Появилась мышечная слабость?</label>
        <div class="radio-input-container">
          <input type="radio" name="9" formControlName="9" value="1" class="radio"> Да
          <input type="radio" name="9" formControlName="9" value="0" class="radio"> Нет
        </div>
      </div>

      <button class="btn" type="submit" [disabled]="form.invalid">Рассчитать</button>
      <div class="calc-response-container">
        <div class="calc-response-title" style="padding-top: 1rem">Ответ:</div>
        <div class="result">{{result}}</div>
        <div *ngIf="score >= 4 && score <= 5" class="hint">Скорректируйте свой рацион- начните добавлять морепродукты, особенно, сельдь, лосось, тунец, сардины, а также молоко, орехи, яйца и печень.</div>

      </div>

    </div>
  </div>

  <div class="warning-text">
    *Результат проведенного тестирования носит рекомендательный характер и предоставляется в ознакомительных целях.
    <br> За более подробной информацией обратитесь к врачу.
  </div>
</form>
