<div class="bookingsContainer">

  <p-tabView
    *ngIf="!showRecord"
    [activeIndex]="activeTabIndex"
    (onChange)="onTabChanged($event)"
    styleClass="b-innerTabs"
  >
    <p-tabPanel header="Предстоящие" [selected]="true" >
      <div class="b-referralList">
        <div class="scroll-vertical">
          <div *ngIf="bookingsUncompleted.length > 0" class="b-referralList__grid">
            <div *ngFor="let doc of bookingsUncompleted.slice(0, offsetUncompleted)"
                [ngClass]="{'b-referralList__item': true, 'p-card': true}">
              <div style="position: relative;">
                <div class="bookingsCancel">
                  <button class="bookingsCancelBtn" (click)="confirm(doc)">Отменить</button>
                </div>
                <div class="b-referralList__item__name" style="margin-top: 15px;">
                  {{doc.slot.resource.name}}
                </div>
                <div class="doctorName">
                  {{doc.slot.resource.doctorName}}
                </div>
                <div class="b-referralList__item__cols">
                  <div>Назначено:</div>
                  <div>{{getDateTimeFormatted(doc.slot.startDate)}}</div>
                </div>
                <div class="b-referralList__item__cols">
                  <div>Кабинет:</div>
                  <div>{{doc.slot.resource.room}}</div>
                </div>
                <div class="b-referralList__item__cols">
                  <div>Адрес:</div>
                  <div>{{doc.slot.medicalInstitution.address}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button *ngIf="offsetUncompleted <= bookingsUncompleted.length" class="show-more-button" (click)="getBookingsUncompleted()">Показать еще</button>
        <div *ngIf="!showRecord && !bookingsUncompleted.length">
          <div class="pa-mt-5 text-sm text-center">Предстоящих записей на прием не найдено.</div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="/" headerStyle="color: red" [disabled]="true">
    </p-tabPanel>
    <p-tabPanel header="Прошедшие">
      <div class="b-referralList">
        <div class="scroll-vertical">
          <div *ngIf="bookingsCompleted.length > 0" class="b-referralList__grid">
            <div *ngFor="let doc of bookingsCompleted.slice(0, offsetCompleted)"
                [ngClass]="{'b-referralList__item': true, 'p-card': true, 'completed': true }">
              <div>
                  <div class="b-referralList__item__name" style="margin-top: 15px;">
                    {{doc.slot.resource.name}}
                  </div>
                  <div class="doctorName">
                    {{doc.slot.resource.doctorName}}
                  </div>
                <div class="b-referralList__item__cols">
                  <div>Назначено:</div>
                  <div>{{getDateTimeFormatted(doc.slot.startDate)}}</div>
                </div>
                <div class="b-referralList__item__cols">
                  <div>Кабинет:</div>
                  <div>{{doc.slot.resource.room}}</div>
                </div>
                <div class="b-referralList__item__cols">
                  <div>Адрес:</div>
                  <div>{{doc.slot.medicalInstitution.address}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        <button *ngIf="offsetCompleted <= bookingsCompleted.length" class="show-more-button" (click)="getBookingsCompleted()">Показать еще</button>
        <div *ngIf="!showRecord && !bookingsCompleted.length">
          <div class="pa-mt-5 text-sm text-center">Прошедших записей на прием не найдено.</div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

  <p-confirmDialog
    acceptButtonStyleClass="acceptButton"
    rejectButtonStyleClass="rejectButton"
  ></p-confirmDialog>

  <div class="cancelBookSuccess" *ngIf="isBookCanceled" (click)="onClose()">
    <img alt="booking canceled" src="../../../../assets/images/canceledBooking.png"/>
  </div>
</div>