<form class="calcCard" [formGroup]="form" (ngSubmit)="submit()">
  <div class="calcWrapper">

    <div class="radio-field">
      <div class="radio-control">

        <label class="calc-radio-label" for="0">1. Вы заметили что у Вас снизился аппетит?</label>
        <div class="radio-input-container">
          <input type="radio" name="0" formControlName="0" value="1" class="radio"> Да
          <input type="radio" name="0" formControlName="0" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control">
        <label class="calc-radio-label" for="1">2. Бывают ли ночные боли в области желудка?</label>
        <div class="radio-input-container">
          <input type="radio" name="1" formControlName="1" value="1" class="radio"> Да
          <input type="radio" name="1" formControlName="1" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control">
        <label class="calc-radio-label" for="2">3. Начали беспокоить головные боли?</label>
        <div class="radio-input-container">
          <input type="radio" name="2" formControlName="2" value="1" class="radio"> Да
          <input type="radio" name="2" formControlName="2" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="3">4. Вы стали более утомляемы?</label>
        <div class="radio-input-container">
          <input type="radio" name="3" formControlName="3" value="1" class="radio"> Да
          <input type="radio" name="3" formControlName="3" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="4">5. В последнее время появилась апатия ко всему?</label>
        <div class="radio-input-container">
          <input type="radio" name="4" formControlName="4" value="1" class="radio"> Да
          <input type="radio" name="4" formControlName="4" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="5">6. Появилась сухость губ, начали трескаться?</label>
        <div class="radio-input-container">
          <input type="radio" name="5" formControlName="5" value="1" class="radio"> Да
          <input type="radio" name="5" formControlName="5" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="6">7. Вас беспокоит резь в глазах?</label>
        <div class="radio-input-container">
          <input type="radio" name="6" formControlName="6" value="1" class="radio"> Да
          <input type="radio" name="6" formControlName="6" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="7">8. Появились язвочки во рту?</label>
        <div class="radio-input-container">
          <input type="radio" name="7" formControlName="7" value="1" class="radio"> Да
          <input type="radio" name="7" formControlName="7" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="8">9. Стали страдать бессонницей?</label>
        <div class="radio-input-container">
          <input type="radio" name="8" formControlName="8" value="1" class="radio"> Да
          <input type="radio" name="8" formControlName="8" value="0" class="radio"> Нет
        </div>
      </div>
    </div>

    <div class="radio-field">
      <div class="radio-control">
        <label class="calc-radio-label" for="9">10. Стали быстро утомляться глаза?</label>
        <div class="radio-input-container">
          <input type="radio" name="9" formControlName="9" value="1" class="radio"> Да
          <input type="radio" name="9" formControlName="9" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="10">11. Появилось шелушение на крыльях носа?</label>
        <div class="radio-input-container">
          <input type="radio" name="10" formControlName="10" value="1" class="radio"> Да
          <input type="radio" name="10" formControlName="10" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="11">12. Появился неприятный запах изо рта?</label>
        <div class="radio-input-container">
          <input type="radio" name="11" formControlName="11" value="1" class="radio"> Да
          <input type="radio" name="11" formControlName="11" value="0" class="radio"> Нет
        </div>
      </div>


      <button class="btn" type="submit" [disabled]="form.invalid">Рассчитать</button>

      <div class="calc-response-container">
        <div class="calc-response-title" style="padding-top: 1rem">Ответ:</div>
        <div class="result">{{result}}</div>
        <div *ngIf="score > 5" class="hint">А потому вам следует добавить в свой пищевой рацион хлеб грубого помола, и особенно, ржаной хлеб, а также гречку, овсянку, перловку (в том числе и в виде хлопьев), рыбу, куриные яйца, кисломолочные продукты, цветную капусту, шпинат и зеленый горошек, телятину, говяжью печень, стручки бобовых растений, а также поливитамины, содержащие рибофлавин. В обязательном порядке исследуйте функции желудка, поджелудочной железы и кишечника, поскольку именно при их заболеваниях чаще всего и возникает недостаточность Витамина В2.</div>
        <div *ngIf="score <= 5 && score > 2" class="hint">Рекомендовано включить в свой рацион хлеб грубого помола, и особенно, ржаной хлеб, а также гречку, овсянку, перловку (в том числе и в виде хлопьев), рыбу, куриные яйца, кисломолочные продукты, цветную капусту, шпинат и зеленый горошек.</div>
      </div>

    </div>
  </div>

  <div class="warning-text">
    *Результат проведенного тестирования носит рекомендательный характер и предоставляется в ознакомительных целях.
    <br> За более подробной информацией обратитесь к врачу.
  </div>
</form>
