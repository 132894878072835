<form class="calcCard" [formGroup]="form" (ngSubmit)="submit()">
  <div class="calcWrapper">
    <div class="form-field">
      <div class="form-control">
        <label>Пол</label>
        <select
            id="gender"
            name="gender"
            formControlName="gender"
        >
          <option value={{user.gender.toLowerCase()}}>{{user.gender}}</option>
          <option value={{setValue(user.gender)}}>{{setValue(user.gender) | titlecase }}</option>
        </select>
      </div>
      <div class="form-control">
        <label>Рост, см.</label>
        <input type="text" formControlName="height">
        <div
            class="validation"
            *ngIf="form.controls.height.status === 'INVALID' && form.controls.height.touched"
        >
          <small *ngIf="form.controls.height.errors?.required">Поле не может быть пустым или состоять из одних пробелов</small>
          <small *ngIf="form.controls.height.errors?.pattern">Поле должно содержать только цифры</small>
        </div>
      </div>
      <button class="btn" type="submit" [disabled]="form.invalid">Рассчитать</button>
    </div>

    <div class="calc-response-container">
      <div class="calc-response-title">Ответ:</div>
      <div class="result">Ваш идеальный вес <span style="color:#FA4022">{{result | number:'1.0-0'  }}</span> кг.</div>
    </div>
  </div>

   <div class="warning-text">
        *Результат проведенного тестирования носит рекомендательный характер и предоставляется в ознакомительных целях.
        <br> За более подробной информацией обратитесь к врачу.
      </div>
</form>
