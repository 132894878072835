import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StorageService } from '@app/services/storage.service';
import { User } from '@app/models/User';

@Component({
  selector: 'app-bmi',
  templateUrl: './bmi.component.html',
  styleUrls: ['../calcStyle.component.scss'],
})
export class BMIComponent implements OnInit {
 
  constructor(private storage: StorageService) {}
  result: number = 0
  form: FormGroup
  user: User;

  ngOnInit() {
    this.user = this.storage.getUser();
    this.form = new FormGroup({
      gender: new FormControl(this.user.gender.toLowerCase()),
      height: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*[.,]?[0-9]+$/)])
    })
  }
  
  setValue(g: string) {
    return g.toLowerCase() === 'женский' ? 'мужской' : 'женский' 
  }

  submit() {
    if (this.form.valid) {
      const formData = {...this.form.value}
      if (formData.gender.toLowerCase() === 'женский' ) {
        this.result = (45.5 + 2.3 * (0.394 * (formData.height.replace(/,/, '.')) - 60))
      } else {
        this.result = (50 + 2.3 * (0.394 * (formData.height.replace(/,/, '.')) - 60))
      }
    }
    return this.result
  }
}
