import { Component, Injectable, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { Diagram } from '@app/models/Diagram';

@Injectable({
  providedIn: "root"
})
@Component({
  selector: 'app-dialogs',
  templateUrl: './dialogs.component.html',
  styleUrls: ['./dialogs.component.scss']
})
export class DialogsComponent implements OnInit {
  isShowModalDialog: boolean = false;
  @Input() dialogHtmlMessage: string = '';

  isNavigateRoute: boolean = false;
  @Input() navigateRoute: string = ''

  diagram: Diagram;
  @Input() isHomePage: boolean = false;

  constructor(
    private router: Router) {
  }

  ngOnInit(): void {
    
  }
  
  showModalDialog(htmlMessage: string) {
    this.dialogHtmlMessage = htmlMessage;
    this.isShowModalDialog = true;
  }

  showModalDialogWithRoute(htmlMessage: string, navigateRoute: string) {
    this.dialogHtmlMessage = htmlMessage;
    this.isShowModalDialog = true;

    if (navigateRoute) {
      this.navigateRoute = navigateRoute;
      this.isNavigateRoute = true;
    }
  }

  hideModalDialog() {
    this.isShowModalDialog = false;
  }

  onHideModalDialog() {
    if (this.isNavigateRoute && this.navigateRoute) {
      this.navigateToRoute();
    }
  }

  navigateToRoute() {
    this.router.navigate([this.navigateRoute]);
  }
}