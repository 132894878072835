import {Component} from '@angular/core';


@Component({
    selector: 'app-book',
    templateUrl: './book.component.html',
    styleUrls: ['./book.component.scss']
})
export class BookComponent {
    // allProfiles: Profile[] = [];
    // profiles: string[] = [];
    // selectedProfile: string = '';
    //
    // allProfileResources: Resource[] = [];
    // profileResources: string[] = [];
    // selectedResource: string = '';
    // isProfileResourcesLoaded: boolean = false;
    //
    // resourceSchedule: Schedule[] = [];
    // isResourceScheduleLoaded: boolean = false;
    // scheduleDates: Date[] = [];
    //
    // selectedScheduleDate: Date;
    // scheduleDateTimeUnits: string[] = [];
    // isScheduleDateSelected: boolean = false;
    //
    // selectedProfileResourceName: string = '';
    //
    // constructor(private sharedService: SharedService,
    //             private router: Router,
    //             private userService: UserService,
    //             private messageService: MessageService,
    //             private confirmationService: ConfirmationService) {
    // }
    //
    // ngOnInit(): void {
    //     this.sharedService.setShowCallButton.emit(true);
    //     this.userService.getProfiles().subscribe({
    //         next: (data) => {
    //             this.allProfiles = data;
    //         }
    //     });
    // }
    //
    // ngOnDestroy() {
    //     this.sharedService.setShowCallButton.emit(false);
    // }
    //
    // getProfileByName(name: string): Profile | null {
    //     let p = this.allProfiles.filter(el => el.name === name);
    //     if (p.length > 0) {
    //         return p[0];
    //     }
    //     return null;
    // }
    //
    // getProfileResourceByName(name: string): Resource | null {
    //     let p = this.allProfileResources.filter(el => (el.doctorName + ' (' + el.name + ')') === name);
    //     if (p.length > 0) {
    //         return p[0];
    //     }
    //     return null;
    // }
    //
    // profileSelected(selectedProfileName: string) {
    //     let profile = this.getProfileByName(selectedProfileName);
    //     if (profile) {
    //         this.userService.getProfileResources(profile.id).subscribe({
    //             next: (data) => {
    //                 this.allProfileResources = data;
    //                 this.isProfileResourcesLoaded = true;
    //                 this.isResourceScheduleLoaded = false;
    //                 this.resourceSchedule = [];
    //                 this.scheduleDates = [];
    //                 this.isScheduleDateSelected = false;
    //                 this.selectedResource = '';
    //             }
    //         })
    //     }
    // }
    //
    // profileResourceSelected(selectedProfileResourceName: string, cb: any = null) {
    //     this.selectedProfileResourceName = selectedProfileResourceName;
    //     let profileResource = this.getProfileResourceByName(selectedProfileResourceName);
    //     if (profileResource) {
    //         this.userService.getProfileResourceSchedule(profileResource.id).subscribe({
    //             next: (data) => {
    //                 this.resourceSchedule = data;
    //                 this.isResourceScheduleLoaded = true;
    //                 this.scheduleDates = data.map(el => new Date(el.date));
    //                 if (cb) {
    //                     cb();
    //                 }
    //             }
    //         });
    //     }
    // }
    //
    // selectDate(e: Date) {
    //     const formatDate = (date: Date) => {
    //         return [date.getDate(), (date.getMonth() + 1), date.getFullYear()].join('.');
    //     }
    //     this.selectedScheduleDate = e;
    //     let units: string[] = [];
    //     let _selectedScheduleDate: Schedule[] = this.resourceSchedule.filter(el => {
    //         return formatDate(new Date(el.date)) === formatDate(e);
    //     });
    //     if (_selectedScheduleDate.length > 0) {
    //         for (let k in _selectedScheduleDate[0].slots) {
    //             if (_selectedScheduleDate[0].slots.hasOwnProperty(k)) {
    //                 let _slot: Slot = _selectedScheduleDate[0].slots[k];
    //                 if (_slot.units.length > 0) {
    //                     units = units.concat(_slot.units);
    //                 }
    //             }
    //         }
    //     }
    //     this.isScheduleDateSelected = true;
    //     this.scheduleDateTimeUnits = units;
    // }
    //
    // getTime(d: string): string {
    //     let _d: Date = new Date(d);
    //     return _d.getHours() + ':' + ('0' + _d.getMinutes()).slice(-2);
    // }
    //
    // searchProfile(e: any) {
    //     if (e.hasOwnProperty('query') && e['query'].length > 0) {
    //         this.profiles = this.allProfiles.filter(el => {
    //             return el.name.toLowerCase().indexOf(e['query'].toLowerCase()) !== -1;
    //         }).map(el => {
    //             return el.name;
    //         });
    //
    //     } else {
    //         this.profiles = this.allProfiles.map(el => {
    //             return el.name;
    //         });
    //     }
    // }
    //
    // searchProfileResource(e: any) {
    //     if (e.hasOwnProperty('query') && e['query'].length > 0) {
    //         this.profileResources = this.allProfileResources.filter(el => {
    //             return el.doctorName.toLowerCase().indexOf(e['query'].toLowerCase()) !== -1;
    //         }).map(el => {
    //             return el.doctorName + ' (' + el.name + ')';
    //         });
    //
    //     } else {
    //         this.profileResources = this.allProfileResources.map(el => {
    //             return el.doctorName + ' (' + el.name + ')';
    //         });
    //     }
    // }
    //
    // book(unit: string) {
    //     let resource: Resource | null = this.getProfileResourceByName(this.selectedResource);
    //     if (resource) {
    //         this.userService.book(resource.id, unit).subscribe({
    //             next: (resp) => {
    //                 this.router.navigate(['/my-data/bookings']);
    //             },
    //             error: (err: any) => {
    //                 if (err.hasOwnProperty('error') && err.error.hasOwnProperty('error')) {
    //                     if (err.error.error.code === "TALON_RESOURCE_SAME_DATE") {
    //                         this.messageService.add({severity: 'error', summary: 'Ошибка', detail: 'У вас уже есть запись к врачу на эту дату. Выберите другую дату.', life: 5000});
    //                     }
    //                     if (err.error.error.code === "TALON_DATE") {
    //                         this.messageService.add({severity: 'error', summary: 'Ошибка', detail: 'Запись на это время к врачу уже существует. Выберите другое время.', life: 5000});
    //                     }
    //                     this.profileResourceSelected(this.selectedProfileResourceName, () => {
    //                         this.selectDate(this.selectedScheduleDate);
    //                     });
    //                 }
    //             }
    //         });
    //     }
    // }
    //
    // confirm(unit: string) {
    //     let doctor: Resource | null = this.getProfileResourceByName(this.selectedResource);
    //     let _message = 'Вы подтверждаете запись';
    //     if (doctor) {
    //         _message += ' к ' + doctor.doctorName + ' (' + doctor.name + ')';
    //     }
    //     _message += ' <br/>' + H.getDateFormatted(unit) + ' в ' + this.getTime(unit) + '?';
    //     this.confirmationService.confirm({
    //         message: _message,
    //         accept: () => {
    //             this.book(unit);
    //         },
    //         acceptLabel: 'Да',
    //         rejectLabel: 'Нет'
    //     });
    // }

}
