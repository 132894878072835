import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    backButtonRoute: EventEmitter<string> = new EventEmitter<string>();

    setShowCallButton: EventEmitter<boolean> = new EventEmitter<boolean>();

        _refreshNotifications: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    sendBackButtonRoute(route: string) {
        return this.backButtonRoute.emit(route);
    }

    showCallButton(value: boolean) {
        return this.setShowCallButton.emit(value);
    }

    refreshNotifications() {
        return this._refreshNotifications.emit();
    }
}
