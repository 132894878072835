import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-vitamin-a',
  templateUrl: './vitamin-a.component.html',
  styleUrls: ['../calcStyle.component.scss']
})

export class VitaminAComponent implements OnInit {
  form: FormGroup
  score: number = 0
  result: string = ''

  ngOnInit() {
    this.form = new FormGroup({
      0: new FormControl('', Validators.required),
      1: new FormControl('', Validators.required),
      2: new FormControl('', Validators.required),
      3: new FormControl('', Validators.required),
      4: new FormControl('', Validators.required),
      5: new FormControl('', Validators.required),
      6: new FormControl('', Validators.required),
      7: new FormControl('', Validators.required),
      8: new FormControl('', Validators.required),
      9: new FormControl('', Validators.required),
      10: new FormControl('', Validators.required),
      11: new FormControl('', Validators.required),
      12: new FormControl('', Validators.required),
      13: new FormControl('', Validators.required)
    })
  }

  showResult(sum: number) {
    if (sum < 4) {
      this.result = 'Вероятнее всего, витамина А Вашему организму вполне хватает, в первую очередь за счет правильного, рационального, сбалансированного питания и здорового образа жизни. Продолжайте в том же духе.'
       
    } else if (sum > 5) {
      this.result =  'Вероятнее всего, у Вас уже имеется выраженная недостаточность витамина А.'
    } else {

      this.result = 'У Вас имеются первые признаки недостатка витамина А в организме. '
    }
    return this.result
  }

  submit() {
    let value: any = ''
    const formData = {...this.form.value}
    let sum = 0;
    for (let key in formData) {
      sum += parseInt(formData[key]);
    }

    this.showResult(sum)
    this.score = sum;
    return sum
  }

}
