<div class="containersOutlet">
  <div class="calc-title">
    <button class="ref-back-btn" routerLink="/my-data" [queryParams]="{ tab: 'Оцените состояние своего здоровья' }">
      <img alt="logo" src="../../../assets/images/ArrowBack.png"/>
      <span class="pl-3 uppercase p-button-label">Назад</span>
    </button>
    <div class="calc-title-name">&laquo;{{ calc?.name }}&raquo;</div>
  </div>
  <div>
    <app-calories *ngIf="calc?.id === 0"></app-calories>
    <app-appendix *ngIf="calc?.id === 1"></app-appendix>
    <app-water *ngIf="calc?.id === 2"></app-water>
    <app-vitamin-a *ngIf="calc?.id === 3"></app-vitamin-a>
    <app-bmi *ngIf="calc?.id === 4"></app-bmi>
    <app-copd *ngIf="calc?.id === 5"></app-copd>
    <app-vitamine-b1 *ngIf="calc?.id === 6"></app-vitamine-b1>
    <app-vitamine-b2 *ngIf="calc?.id === 7"></app-vitamine-b2>
    <app-vitamine-d *ngIf="calc?.id === 8"></app-vitamine-d>
    <app-gastritis *ngIf="calc?.id === 9"></app-gastritis>
  </div>
</div>
