<form class="calcCard" [formGroup]="form" (ngSubmit)="submit()">
  <div class="calcWrapper">
    <div class="radio-field">

      <div class="radio-control">
        <label class="calc-radio-label" for="0">1. Отмечаете у себя повышенную сухость кожи?</label>
        <div class="radio-input-container">
          <input class="radio" type="radio" name="0" formControlName="0" value="1"> Да
          <input class="radio" type="radio" name="0" formControlName="0" value="0"> Нет
        </div>
      </div>

      <div class="radio-control">
        <label class="calc-radio-label" for="1">2. Вы стали чаще болеть простудными и другими инфекционными заболеваниями?</label>
        <div class="radio-input-container">
          <input type="radio" name="1" formControlName="1" value="1" class="radio"> Да
          <input type="radio" name="1" formControlName="1" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control">
        <label class="calc-radio-label" for="2">3. Ухудшилось зрение?</label>
        <div class="radio-input-container">
          <input type="radio" name="2" formControlName="2" value="1" class="radio"> Да
          <input type="radio" name="2" formControlName="2" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control">
        <label class="calc-radio-label" for="3">4. Ваши волосы стали тусклыми, сухими и ломкими?</label>
        <div class="radio-input-container">
          <input type="radio" name="3" formControlName="3" value="1" class="radio"> Да
          <input type="radio" name="3" formControlName="3" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control">
        <label class="calc-radio-label" for="4">5. На коже стали появляться угри?</label>
        <div class="radio-input-container">
          <input type="radio" name="4" formControlName="4" value="1" class="radio"> Да
          <input type="radio" name="4" formControlName="4" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control">
        <label class="calc-radio-label" for="5">6. Появилась значительная перхоть?</label>
        <div class="radio-input-container">
          <input type="radio" name="5" formControlName="5" value="1" class="radio"> Да
          <input type="radio" name="5" formControlName="5" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control">
        <label class="calc-radio-label" for="6">7. Вы стали быстрее утомляться?</label>
        <div class="radio-input-container">
          <input type="radio" name="6" formControlName="6" value="1" class="radio"> Да
          <input type="radio" name="6" formControlName="6" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control">
        <label class="calc-radio-label" for="7">8. У Вас ломкие и медленно растущие ногти?</label>
        <div class="radio-input-container">
          <input type="radio" name="7" formControlName="7" value="1" class="radio"> Да
          <input type="radio" name="7" formControlName="7" value="0" class="radio"> Нет
        </div>
      </div>

    </div>

    <div class="radio-field">
      <div class="radio-control">
        <label class="calc-radio-label" for="8">9. Кожа по всему телу стала сильно шелушиться?</label>
        <div class="radio-input-container">
          <input type="radio" name="8" formControlName="8" value="1" class="radio"> Да
          <input type="radio" name="8" formControlName="8" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control">
        <label class="calc-radio-label" for="9">10. Вы стали терять вес?</label>
        <div class="radio-input-container">
          <input type="radio" name="9" formControlName="9" value="1" class="radio"> Да
          <input type="radio" name="9" formControlName="9" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control">
        <label class="calc-radio-label" for="10">11. В сумерках стали хуже видеть?</label>
        <div class="radio-input-container">
          <input type="radio" name="10" formControlName="10" value="1" class="radio"> Да
          <input type="radio" name="10" formControlName="10" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control">
        <label class="calc-radio-label" for="11">12.  Вас беспричинно возникает слюнотечение?</label>
        <div class="radio-input-container">
          <input type="radio" name="11" formControlName="11" value="1" class="radio"> Да
          <input type="radio" name="11" formControlName="11" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control">
        <label class="calc-radio-label" for="12">13. У Вас начала появляться светобоязнь?</label>
        <div class="radio-input-container">
          <input type="radio" name="12" formControlName="12" value="1" class="radio"> Да
          <input type="radio" name="12" formControlName="12" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control">
        <label class="calc-radio-label" for="13">14. У Вас появляется ороговение отдельных участков кожи?</label>
        <div class="radio-input-container">
          <input type="radio" name="13" formControlName="13" value="1" class="radio"> Да
          <input type="radio" name="13" formControlName="13" value="0" class="radio"> Нет
        </div>
      </div>

      <button class="btn" type="submit" [disabled]="form.invalid">Рассчитать</button>

      <div class="calc-response-container">
        <div class="calc-response-title" style="padding-top: 1rem">Ответ:</div>
        <div class="result">{{result}}</div>
        <div *ngIf="score > 5" class="hint">Вам необходимо вводить в свой пищевой рацион омегу 3, печень трески. Рекомендована консультация специалиста.</div>
        <div *ngIf="score <= 5 && score >= 4" class="hint">Рекомендуется включить в Ваш суточный рацион кисломолочные продукты, сливочное масло, сыр, зеленый лук, сладкий перец, зеленый горошек, петрушку, капусту, шпинат, тыкву, помидоры, крапиву, облепиху, абрикосы, персики, яичный желток и особенно наиболее богатую каротином (провитамином А) морковь.</div>

      </div>

    </div>
  </div>

  <div class="warning-text">
    *Результат проведенного тестирования носит рекомендательный характер и предоставляется в ознакомительных целях.
    <br> За более подробной информацией обратитесь к врачу.
  </div>

</form>
