<div class="medkarta-cards-container">

  <div class="medkarta-card" routerLink="/my-data/medkarta/conclusions">
    <img alt="conclusions" src="../../../../assets/images/medkarta/conclusions.svg"/>
    <div class="medkarta-card-title">Заключения</div>
  </div>

  <div class="medkarta-card" routerLink="/my-data/medkarta/results">
    <img alt="results" src="../../../../assets/images/medkarta/results.svg"/>
    <div class="medkarta-card-title">Анализы</div>
  </div>

  <div class="medkarta-card" routerLink="/my-data/medkarta/researches">
    <img alt="researches" src="../../../../assets/images/medkarta/researches.svg"/>
    <div class="medkarta-card-title">Исследования</div>
  </div>

  <div class="medkarta-card" routerLink="/my-data/medkarta/archive">
    <img alt="archive" src="../../../../assets/images/medkarta/archive.svg"/>
    <div class="medkarta-card-title">Архив</div>
  </div>

</div>




