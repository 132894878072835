<form class="calcCard" [formGroup]="form" (ngSubmit)="submit()">
  <div class="calcWrapper">
    <div class="radio-field">
      <div class="radio-control">
        <label class="calc-radio-label" for="0">1. Миграция боли в правую подвздошную область?</label>
        <div class="radio-input-container">
          <input type="radio" name="0" formControlName="0" value="1" class="radio"> Да
          <input type="radio" name="0" formControlName="0" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control" >
        <label class="calc-radio-label" for="1">2. Тошнота или рвота?</label>
        <div class="radio-input-container">
          <input type="radio" name="1" formControlName="1" value="1" class="radio"> Да
          <input type="radio" name="1" formControlName="1" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control" >
        <label class="calc-radio-label" for="2">3. Потеря аппетита?</label>
        <div class="radio-input-container">
          <input type="radio" name="2" formControlName="2" value="1" class="radio"> Да
          <input type="radio" name="2" formControlName="2" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control" >
        <label class="calc-radio-label" for="3">4. Болезненность в правой подвздошной области?</label>
        <div class="radio-input-container">
          <input type="radio" name="3" formControlName="3" value="1" class="radio"> Да
          <input type="radio" name="3" formControlName="3" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control" >
        <label class="calc-radio-label" for="4">5. Симптом Щеткина - резкое усиление боли в животе при быстром снятии пальпирующей руки с передней брюшной стенки после надавливания?</label>
        <div class="radio-input-container">
          <input type="radio" name="4" formControlName="4" value="1" class="radio"> Да
          <input type="radio" name="4" formControlName="4" value="0" class="radio"> Нет
        </div>
      </div>

      <div class="radio-control" >
        <label class="calc-radio-label" for="5">6. Повышение температуры более 37.3 C?</label>
        <div class="radio-input-container">
          <input type="radio" name="5" formControlName="5" value="1" class="radio"> Да
          <input type="radio" name="5" formControlName="5" value="0" class="radio"> Нет
        </div>
      </div>
      <button class="btn" type="submit" [disabled]="form.invalid">Рассчитать</button>
    </div>

    <div class="calc-response-container">
      <div class="calc-response-title">Ответ:</div>

      <div class="result">{{result}}</div>
    </div>

  </div>

  <div class="warning-text">
    *Результат проведенного тестирования носит рекомендательный характер и предоставляется в ознакомительных целях.
    <br> За более подробной информацией обратитесь к врачу.
  </div>

</form>
