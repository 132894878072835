import {Component, OnInit} from '@angular/core';
import {NotificationsService} from "@app/services/notifications.service";
import {Notification} from "@app/models/Notification";
import {H} from "@app/helpers/helper";
import {SharedService} from "@app/services/shared.service";

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

    items: Notification[] = [];

    constructor(
        private notificationsService: NotificationsService,
        private sharedService: SharedService
    ) {
    }

    ngOnInit(): void {
        this.getNotifications();
    }

    getNotifications() {
        this.notificationsService.getNotifications().subscribe({
            next: (_items) => {
                this.items = _items;
            }
        });
    }

    getDateFormatted(s: string): string {
        return H.getDateFormatted(s);
    }

    markAsReadRecord(id: number) {
        this.notificationsService.markAsReadRecord(id).subscribe({
            next: (resp) => {
                this.getNotifications();
                this.sharedService._refreshNotifications.emit();
            }
        });
    }

    markAsReadAll() {
        this.notificationsService.markAsReadAll().subscribe({
            next: (resp) => {
                this.getNotifications();
                this.sharedService._refreshNotifications.emit();
            }
        });
    }
}
