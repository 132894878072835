import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export interface Calc {
    id: number
    name?: string
    image?: string
}

@Injectable({providedIn: 'root'})

export class CalcsService{
  
    calculators: Calc[] = [
        {
            id: 0,
            name: 'Калькулятор расчета суточной нормы калорий',
            image: '/assets/images/calc_icons/calories.svg'
        },
        {
            id: 1,
            name: 'Оценка вероятности острого аппендицита',
            image: '/assets/images/calc_icons/appendix.svg'
        },
        {
            id: 2,
            name: 'Расчет суточного потребления воды',
            image: '/assets/images/calc_icons/water.svg'
        },
        {
            id: 3,
            name: 'Есть ли у вас недостаток витамина А',
            image: '/assets/images/calc_icons/vitamineA.svg'
        },
        {
            id: 4,
            name: 'Расчет идеальной массы тела по методу Devine',
            image: '/assets/images/calc_icons/BMI.svg'
        },
        {
            id: 5,
            name: 'Расчет индекса курения',
            image: '/assets/images/calc_icons/lungs.svg'
        },
        {
            id: 6,
            name: 'Есть ли у вас недостаток витамина В1',
            image: '/assets/images/calc_icons/vitamineB1.svg'
        },
        {
            id: 7,
            name: 'Есть ли у вас недостаток витамина В2',
            image: '/assets/images/calc_icons/vitamineB2.svg'
        },
        {
            id: 8,
            name: 'Есть ли у вас недостаток витамина Д',
            image: '/assets/images/calc_icons/vitamineD.svg'
        },
        {
            id: 9,
            name: 'Есть ли у вас гастрит',
            image: '/assets/images/calc_icons/stomach.svg'
        },
    ];

    constructor(private http: HttpClient) {}
      
    getCalc(): Calc[] {
        return this.calculators;
    }

    getById(id: number) {
        return this.calculators.find(calc => calc.id === id)
    }

    saveCalcResult(values: any) {
        console.log('== 1 ==', 1);       
    }
}