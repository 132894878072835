<div class="questionnaireContainer">
  <button class="ref-back-btn" routerLink="/my-data/questioning">
    <img alt="logo" src="./assets/images/ArrowBack.png"/>
    <span class="pl-3 uppercase p-button-label">Назад</span>
  </button>

  <div class="title">
    {{
      questionnaire?.id === 0
        ? getAge(user.birthDate) >= 65
          ? questionnaire?.name_after_65
          : questionnaire?.name_before_65
        : questionnaire?.name
    }}
  </div>
  <div class="questionnaire-container">
    <app-ncds-middle
      *ngIf="questionnaire?.id === 0 && getAge(user.birthDate) < 65"
    ></app-ncds-middle>
    <app-ncds-elderly
      *ngIf="questionnaire?.id === 0 && getAge(user.birthDate) >= 65"
    ></app-ncds-elderly>
    <app-covid *ngIf="questionnaire?.id === 1"></app-covid>
  </div>
</div>
