<p-dialog
    styleClass="p-dialog-diagram"
    (onShow)="onShowDiagramDialog()"
    [showHeader]="false"
    [(visible)]="isShowDiagramDialog"
    [modal]="true">
    <div class="pa-mt-5" style="text-align: end;">
        <button type="button" style="background: transparent; border: 0 none;" (click)="hideDiagramDialog()">
            <i class="pi pi-times"></i>
        </button>
    </div>
    <div class="d-grid-diagram">
        <div class="text-center pa-mb-4">
            <span class="text-black pa-fs-5">Ваши анализы</span>
        </div>
        <div class="mt-2">
            <div class="progress" routerLink="/my-data/medkarta/results">
                <svg class="m-2 progress-svg" viewBox="0 0 100 100">
                    <path id="diagramStroke" class="progress-stroke" d="M 50 93 a 20 20 0 0 1 0 -86 20 20 0 0 1 0 86" />
                    <path id="separatorStroke" class="separator-stroke" d="M 50 93 a 20 20 0 0 1 0 -86 20 20 0 0 1 0 86" />
                </svg>
                <div class="foreground-shadow">
                </div>
                <div class="foreground">
                    <span class="text-up mb-2">
                        Готово
                    </span>
                    <span class="text-down mt-2">
                        {{diagram.completedCount}}/{{diagram.analysisCount}}
                    </span>
                </div>
                <div class="start-position center">
                </div>
            </div>
        </div>
    </div>
</p-dialog>