import {Component, OnInit} from '@angular/core';
import {UserService} from "@app/services/user.service";
import {User} from "@app/models/User";
import {MenuItem} from "primeng/api";
import {Constants} from "@app/helpers/constants";
import {Document} from "@app/models/Document";
import {H} from "@app/helpers/helper";
import {Router} from "@angular/router";
import {StorageService} from "@app/services/storage.service";
import {SharedService} from "@app/services/shared.service";
import {environment} from '@environments/environment';

@Component({
    selector: 'app-medkarta',
    templateUrl: './medkarta.component.html',
    styleUrls: ['./medkarta.component.scss'],

})
export class MedkartaComponent  {

}
