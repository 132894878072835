import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import {Profile} from "@app/models/Profile";
import {Resource} from "@app/models/BookRecord";
import {Schedule, ScheduleDates} from "@app/models/Schedule";
import {SharedService} from "@app/services/shared.service";
import {Router} from "@angular/router";
import {UserService} from "@app/services/user.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {H} from "@app/helpers/helper";
import { DialogsComponent } from '@app/components/dialogs/dialogs.component';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-specialty',
  templateUrl: './specialty.component.html',
  styleUrls: ['./specialty.component.scss'],
  providers: [DatePipe]
})
export class SpecialtyComponent {
  allProfiles: Profile[] = [];
  profiles: string[] = [];
  selectedProfile: string = '';

  allProfileResources: Resource[] = [];
  profileResources: string[] = [];
  selectedResource: string = '';
  isProfileResourcesLoaded: boolean = false;

  resourceSchedule: Schedule[] = [];
  resourceScheduleDates: ScheduleDates[] = [];
  isResourceScheduleLoaded: boolean = false;
  isResourceScheduleDatesLoaded: boolean = false;
  startDate: Date;
  scheduleDates: Date[] = [];
  disabledDates: Date[] = [];

  selectedScheduleDate: Date;
  scheduleDateTimeUnits: string[] = [];
  isScheduleDateSelected: boolean = false;

  selectedProfileResourceName: string = '';

  selectedUnit: string = '';
  profileResourceId: number;
  selectedDate: string;
  units: string[] = [];

  @ViewChild(DialogsComponent, {static: true}) dialogs!: DialogsComponent;

  constructor(private sharedService: SharedService,
              private router: Router,
              private datePipe: DatePipe,
              private userService: UserService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    this.sharedService.setShowCallButton.emit(true);
    this.userService.getProfiles().subscribe({
      next: (data) => {
        this.allProfiles = data;
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.setShowCallButton.emit(false);
  }

  getProfileByName(name: string): Profile | null {
    let p = this.allProfiles.filter(el => el.name === name);
    if (p.length > 0) {
      return p[0];
    }
    return null;
  }

  getProfileResourceByName(name: string): Resource | null {
    let p = this.allProfileResources.filter(el => (el.doctorName + ' (' + el.name + ')') === name);
    if (p.length > 0) {
      return p[0];
    }
    return null;
  }

  profileSelected(selectedProfileName: string) {
    let profile = this.getProfileByName(selectedProfileName);
    if (profile) {
      this.userService.getProfileResources(profile.id).subscribe({
        next: (data) => {
          this.allProfileResources = data;
          this.isProfileResourcesLoaded = true;
          this.isResourceScheduleLoaded = false;
          this.isResourceScheduleDatesLoaded = false;
          this.selectedProfileResourceName = '';
          this.resourceSchedule = [];
          this.scheduleDates = [];
          this.isScheduleDateSelected = false;
          this.selectedResource = '';
          this.resetDate();
        }
      })
    }
  }

  profileResourceDatesSelected(selectedProfileResourceName: string, cb: any = null) {
    this.selectedProfileResourceName = selectedProfileResourceName;
    let profileResource = this.getProfileResourceByName(selectedProfileResourceName);
    if (profileResource) {
      this.profileResourceId = profileResource.id
      this.userService.getProfileResourceSlotsDatesSchedule(profileResource.id).subscribe({
        next: (data) => {
          this.resourceScheduleDates = data;
          this.isResourceScheduleDatesLoaded = true;
          this.startDate = new Date();
          this.scheduleDates = data.map(el => new Date(el.date));
          this.addDisabledDates();
          if (cb) {
            cb();
          }
        }
      });
    }
  }

  addDisabledDates() {
    const formatDate = (date: Date) => {
      return [date.getDate(), (date.getMonth() + 1), date.getFullYear()].join('.');
    }

    let dateCurrent: Date = new Date(this.scheduleDates[0]);
    let currentDate: ScheduleDates[] = [];

    while (dateCurrent < this.scheduleDates[this.scheduleDates.length - 1]) {
      currentDate = this.resourceScheduleDates.filter(el => {
        return formatDate(new Date(el.date)) === formatDate(dateCurrent);
      });

      if (currentDate.length < 1) {
        this.disabledDates.push(new Date(dateCurrent));
      }

      dateCurrent = new Date(dateCurrent.setDate(dateCurrent.getDate() + 1));
    }
  }

  selectDate(e: Date) {
    if (e) {
      this.selectedDate = this.datePipe.transform(new Date(e), "yyyy-MM-dd") ?? ''
      let profileResource = this.getProfileResourceByName(this.selectedProfileResourceName);
      if (profileResource) {
        this.profileResourceId = profileResource.id
        this.userService.getProfileResourceSchedule(profileResource.id, this.selectedDate).subscribe({
          next: (data) => {
            this.resourceSchedule = data;
            this.isResourceScheduleLoaded = true;
            this.startDate = new Date();
          }
        });
      }
      this.userService.getProfileResourceSchedule(this.profileResourceId, this.selectedDate).subscribe({
        next: (data) => {
          this.resourceSchedule = data;
          this.units = data.map(item => item.startDate)
          this.scheduleDateTimeUnits = this.units;
        }
      });
    }
    this.selectedScheduleDate = e;
    this.isScheduleDateSelected = true;
  }

  resetDate() {
    this.selectedScheduleDate = new Date();
    this.isResourceScheduleLoaded = false;
    this.isResourceScheduleDatesLoaded = false;
    this.selectedProfileResourceName = '';
    this.scheduleDateTimeUnits = [];
  }

  getTime(d: string): string {
    let _d: Date = new Date(d);
    return _d.getHours() + ':' + ('0' + _d.getMinutes()).slice(-2);
  }

  searchProfile(e: any) {
    if (e.hasOwnProperty('query') && e['query'].length > 0) {
      this.profiles = this.allProfiles.filter(el => {
        return el.name.toLowerCase().indexOf(e['query'].toLowerCase()) !== -1;
      }).map(el => {
        return el.name;
      });

    } else {
      this.profiles = this.allProfiles.map(el => {
        return el.name;
      });
    }
  }

  searchProfileResource(e: any) {
    if (e.hasOwnProperty('query') && e['query'].length > 0) {
      this.profileResources = this.allProfileResources.filter(el => {
        return el.doctorName.toLowerCase().indexOf(e['query'].toLowerCase()) !== -1;
      }).map(el => {
        return el.doctorName + ' (' + el.name + ')';
      });

    } else {
      this.profileResources = this.allProfileResources.map(el => {
        return el.doctorName + ' (' + el.name + ')';
      });
    }
  }

  book(unit: string, dialogMessage: string) {
    let slot: any = this.resourceSchedule.filter(item => item.startDate === unit)
    let slotId: number = slot[0].id
    if (slotId) {
      this.userService.book(slotId).subscribe({
        next: (resp) => {
          this.dialogs.showModalDialogWithRoute(dialogMessage, '/my-data/bookings');
        },
        error: (err: any) => {
          if (err.hasOwnProperty('error') && err.error.hasOwnProperty('error')) {
            if (err.error.error.code === "BOOKING_SAME_RESOURCE_SAME_DATE") {
              this.messageService.add({severity: 'error', summary: 'Ошибка', detail: 'У вас уже есть запись к врачу на эту дату. Выберите другую дату.', life: 5000});
            }
            if (err.error.error.code === "BOOKING_DATE") {
              this.messageService.add({severity: 'error', summary: 'Ошибка', detail: 'Запись на это время к врачу уже существует. Выберите другое время.', life: 5000});
            }
            this.profileResourceDatesSelected(this.selectedProfileResourceName, () => {
              this.selectDate(this.selectedScheduleDate);
            });
          }
        }
      });
    }
  }

  setSelectedUnit(unit: string) {
    this.selectedUnit = unit;
  }

  confirm() {
    let doctor: Resource | null = this.getProfileResourceByName(this.selectedResource);
    let _message = 'Вы подтверждаете запись';
    let dialogMessage = '';
    if (doctor) {
      _message += ' к ' + doctor.doctorName + ' (' + doctor.name + ')';

      dialogMessage = '<div class="grid">'
                       + '<div class="col font-bold text-black">Врач:</div>'
                       + '<div class="col">' + doctor.name + '<br>' + doctor.doctorName + '</div>'
                       + '</div>';
    }
    _message += ' <br/>' + H.getDateFormatted(this.selectedUnit) + ' в ' + this.getTime(this.selectedUnit) + '?';
    
    dialogMessage = '<div class="grid">'
                     + '<div class="col font-bold text-black">Дата и время:</div>'
                     + '<div class="col">' + H.getDateFormatted(this.selectedUnit) + ' ' + this.getTime(this.selectedUnit) + '</div>'
                     + '</div>'
                     + dialogMessage;
    
    // dialogMessage += '<div class="grid">'
    //                   + '<div class="col font-bold text-black">Адрес:</div>'
    //                   + '<div class="col">Адрес</div>'
    //                   + '</div>';

    dialogMessage = '<div class="text-center mt-3 mb-3"><span class="font-bold text-black fs-8">Спасибо!<br />Вы успешно записаны на приём</span></div>'
                     + '<div class="pl-4 pr-4">'
                     + dialogMessage
                     + '</div>';

    this.confirmationService.confirm({
      message: _message,
      accept: () => {
        this.book(this.selectedUnit, dialogMessage);
      },
      acceptLabel: 'Да',
      rejectLabel: 'Нет'
    });
  }
}
