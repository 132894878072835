import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-appendix',
  templateUrl: './appendix.component.html',
  styleUrls: ['../calcStyle.component.scss']
})
export class AppendixComponent implements OnInit {
  form: FormGroup
  score: number = 0
  result: string = ''

  ngOnInit() {
    this.form = new FormGroup({
      0: new FormControl('', Validators.required),
      1: new FormControl('', Validators.required),
      2: new FormControl('', Validators.required),
      3: new FormControl('', Validators.required),
      4: new FormControl('', Validators.required),
      5: new FormControl('', Validators.required)
    })
  }

  showResult(score: number) {
    if (score > 6) {
      this.result = 'У Вас явные признаки острого аппендицита. Требуется обратиться к врачу.'
       
    } else if (score > 4 && score < 7) {
      this.result =  'Высокая степень вероятности острого аппендицита.'
    } else if (score == 4) {
      this.result =  'Острый аппендицит возможен и пациент нуждается в наблюдении.'
    } else {
      this.result = 'Острый аппендицит маловероятен.'
    }
    return this.result
  }

  submit() {
    let value: any = ''
      const formData = {...this.form.value}
      for (value of Object.values(formData)) {
       this.score += (+value)
      }
      this.showResult(this.score)
      return this.score
  }
}
