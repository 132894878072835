import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StorageService } from '@app/services/storage.service';
import { User } from '@app/models/User';

@Component({
  selector: 'app-calories',
  templateUrl: './calories.component.html',
  styleUrls: ['../calcStyle.component.scss']
})
export class CaloriesComponent  implements OnInit {
  
  constructor(private storage: StorageService) {}
  user: User
  form: FormGroup
  result: number = 0

  ngOnInit() {
    this.user = this.storage.getUser();
    this.form = new FormGroup({
      activity: new FormControl('1.2'),
      gender: new FormControl(this.user.gender.toLowerCase()),
      age: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]+(?!.)/)]),
      weight: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*[.,]?[0-9]+$/)]),
      height: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*[.,]?[0-9]+$/)])
    })
  }

  setValue(g: string) {
    return g.toLowerCase() === 'женский' ? 'мужской' : 'женский' 
  }
  
  submit() {
    if (this.form.valid) {
      const formData = {...this.form.value}
      if (formData.gender.toLowerCase() === 'женский' ) {
        this.result = (10 * (formData.weight).replace(/,/, '.') + 6.25 * (formData.height.replace(/,/, '.')) - 5 * formData.age - 161) * formData.activity
      } else {
        this.result = (10 * (formData.weight).replace(/,/, '.') + 6.25 * (formData.height.replace(/,/, '.')) - 5 * formData.age + 5) * formData.activity
      }
    }

    return this.result
  }
}
