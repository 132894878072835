<div class="f-container">
  <div class="footer">
    <div>
      <a href="https://t.me/rzd_medicine_test_bot" target="_blank">
        <div [ngClass]="{'chat-bot-icon': isLoggedIn(), 'chat-bot-icon__logout': !isLoggedIn() }">
          <img
            class="tg-icon"
            src="/assets/images/telegram-icon-77-77.png"
            alt="Чат бот"
            pTooltip="Воспользуйтесь нашим чат-ботом для дополнительной информации"
            tooltipPosition="left"/>
        </div>
      </a>
    </div>
    <div class="line">
      <div class="grid">
        <div class="col text2 link contacts" routerLink="/contacts">Контакты</div>
        <div [ngClass]="{'col text2': true, 'hotline': isLoggedIn(), 'hotline__logout': !isLoggedIn() }">Единая диспетчерская служба</div>
        <div [ngClass]="{'col text2 chat-bot': isLoggedIn(), 'chat-bot__logout': !isLoggedIn() }">
          <a href="https://t.me/rzd_medicine_test_bot" target="_blank">Чат бот</a>
        </div>
      </div>
      <div class="grid">
        <div class="col text contacts"> © «РЖД-Медицина», 2023</div>
        <a [ngClass]="{'col': true, 'f-phone': isLoggedIn(), 'f-phone__logout': !isLoggedIn() }" href="tel:88007000701">8-800-700-0-701</a>
        <div [ngClass]="{'col text chat-bot': isLoggedIn(), 'chat-bot__logout': !isLoggedIn() }"></div>
      </div>
    </div>
  </div>
</div>
