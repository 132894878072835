import {Component} from '@angular/core';
import {User} from '@app/models/User';
import {UserService} from '@app/services/user.service';
import {Router} from "@angular/router";
import {Banner} from "@app/models/Banner";
import {OwlOptions} from "ngx-owl-carousel-o";
import {H} from '@app/helpers/helper';

@Component({
    selector: 'app-home',
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.scss']
})
export class HomeComponent {
    user: User;
    banners: Banner[];
    isMobile: boolean;

    constructor(private userService: UserService, private router: Router) {
    }

    ngOnInit() {
        this.userService.getBanners().subscribe({
            next: (res) => {
                this.banners = res;
            }
        });

        this.checkIsMobile();
    }

    ngAfterViewChecked() {
        this.checkIsMobile();
    }

    checkIsMobile() {
        this.isMobile = window.innerWidth < 768;
    }
}
