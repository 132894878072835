export class Constants {
    public static DOCUMENT_TYPE_DECISIONS: number = 0;
    public static DOCUMENT_TYPE_RESULTS: number = 1;
    public static DOCUMENT_TYPE_RESEARCHES: number = 2;

    public static REFERRAL_TYPE_REFERRAL: number = 0;
    public static REFERRAL_TYPE_RECOMENDATION: number = 1;

    public static ORG_NAME:string = 'Клиническая больница "РЖД-Медицина" имени Н. А. Семашко';
    public static ORG_DEPT_NAME:string = 'ЦЕНТР ПРОИЗВОДСТВЕННОЙ МЕДИЦИНЫ';
}
