import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {first, map, windowWhen} from 'rxjs/operators';
import {AuthService} from '@app/services/auth.service';
import {HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {StorageService} from "@app/services/storage.service";
import {Constants} from "@app/helpers/constants";

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    step: string = 'phone';

    phone: string = '';
    lastUserPhone: string = '';
    phoneButtonEnabled: boolean = false;

    sms: string = '';
    private _smsValue: string = '';
    smsButtonEnabled: boolean = false;

    @ViewChild('form') formElement: NgForm;
    @ViewChild('smsSendButton') smsSendButton: ElementRef;

    // @ts-ignore
    loginForm: FormGroup;
    _returnUrl!: string;
    loading: boolean = false;
    submitted: boolean = false;
    disabled: boolean = false;
    smsLength = 5;
    error = '';

    get smsValue(): string {
        return this._smsValue;
    }

    set smsValue(value: string) {
        this._smsValue = value;
        // this.smsButtonEnabled = this._smsValue.length === this.smsLength;
        // if (this._smsValue.length === this.smsLength) {
        //     this.smsButtonEnabled = true;
        //     // this.onSubmit();
        // } else {
        //     this.smsButtonEnabled = false;
        // }
    }

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private storageService: StorageService
    ) {

        if (this.authService.currentUserValue) {
            this.router.navigate(['/']);
            return;
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            phone: ['', Validators.required],
            sms: []
        });
        this._returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    get f() {
        return this.loginForm.controls;
    }

    showPhoneScreen() {
        this.step = 'phone';
        this.loading = false;
        this.loginForm.controls['phone'].enable();
        // this.loginForm.controls['phone'].;
        this.loginForm.controls['sms'].setValidators([]);
        this.loginForm.controls['sms'].updateValueAndValidity();
        this.lastUserPhone = this.storageService.getLastUsedPhone();
        this.loginForm.reset();
    }

    showSmsScreen() {
        this.getSmsCode()
            .subscribe({
                next: (resp) => {
                    if (resp.succeeded) {
                        this.loading = false;
                        this.loginForm.controls['sms'].setValidators([Validators.required, Validators.minLength(this.smsLength), Validators.maxLength(this.smsLength)]);
                        this.loginForm.controls['sms'].updateValueAndValidity();
                        this.f.phone.disable();
                        this.step = 'sms';
                        this.submitted = false;
                        this.storageService.saveLastUsedPhone(this.f.phone.value.replace(/\D/g, ''));
                    }
                },
                error: (error) => {
                    if (error.hasOwnProperty('status')) {
                        if (error.status == 0) {
                            this.f.phone.setErrors({server_error: 'Сервер не отвечает. Попробуйте войти позже'});
                        } else {
                            this.f.phone.setErrors({server_error: 'Указан неверный телефон. Повторите попытку'});
                        }
                    }
                    else {
                        this.f.phone.setErrors({server_error: 'Данный номер не привязан к карте пациента. Обратитесь в поликлинику.'});
                    }
                    this.loading = false;
                    this.submitted = false;
                    this.phoneButtonEnabled = false;
                }
            });
    }

    onPhoneInput(event: Event) {
        const input: HTMLInputElement | null = (event.target as HTMLInputElement);
        if (input.value) {
            this.phoneButtonEnabled = input.value.replace(/\D/g, '').length === 11;
        }
    }
    backToPhone() {
        this.showPhoneScreen();
    }
    onPasteSms(event: ClipboardEvent) {
        event.preventDefault();
        let clipboardData = event.clipboardData;
        if (clipboardData) {
            let pastedText: string = clipboardData.getData('text/plain');
            pastedText = pastedText.replace(/\D/ig, '');
            this.smsValue = pastedText.trim().substring(0, this.smsLength);
        }
    }

    onSmsInput(event: any) {
        const input: HTMLInputElement | null = (event.target as HTMLInputElement);
        // event.preventDefault();
        if (event.key === 'Backspace') {
            if (this.smsValue.length > 0) {
                this.smsValue = this.smsValue.substring(0, this.smsValue.length - 1);
            }
        } else {
            if (this.smsValue.length < this.smsLength) {
                this.smsValue += event.key.replace(/\D/ig, '');
            }
        }

        if (input.value) {
            this.smsButtonEnabled = input.value.replace(/\D/g, '').length === this.smsLength;
        }
    }

    getSmsCode() {
        return this.authService.login(this.f.phone.value.replace(/\D/g, ''));
    }

    getOrgName(): string {
        return Constants.ORG_NAME;
    }

    getOrgDeptName(): string {
        return Constants.ORG_DEPT_NAME;
    }

    login() {
        this.authService.token(
            String(this.f.phone.value).replace(/\D/g, ''),
            String(this.f.sms.value).replace(/\D/g, '')
        ).subscribe({
            next: (resp) => {
                this.loading = false;
                window.location.reload();
                return;
            },
            error: (error) => {
                this.f.sms.setErrors({server_error: 'Указан неверный СМС код. Повторите попытку.'});
                this.loading = false;
                this.submitted = false;
            }
        });
    }

    onSubmit() {
        if (this.submitted) {
            return;
        }
        this.submitted = true;
        this.loading = true;
        if (this.step === 'phone') {
            this.showSmsScreen();
        } else if (this.step === 'sms') {
            this.login();
        }
    }
}
