<div *ngIf="isLoggedIn()" style="width: 100%">
  <div class="flex align-items-center justify-content-between mobileMenu">
    <div>
      <button
        label="Выход"
        class="h-logout-btn"
        (click)="logout()"
        routerLink="/logout"
      >
        <img alt="logo" src="../../../assets/images/logout.svg"/>
      </button>
    </div>
    <div class="b-top__grid__notifications">
      <a routerLink="/notifications" routerLinkActive="is-active">
        <i class="pi pi-bell fs-8 mobile-bell">
          <span *ngIf="unreadNotificationsCount > 0" class="mobile-badge">
            <p-badge value="{{this.unreadNotificationsCount}}" severity="danger"></p-badge>
          </span>
        </i>
      </a>
    </div>
    <div>
      <p-menu #menu [model]="items" [popup]="true"></p-menu>
      <button
          pButton
          type="button"
          (click)="menu.toggle($event)"
          icon="pi pi-bars fs-8"
      ></button>
    </div>
  </div>

  <div class="desktopMenu">
      <div class="h-tabMenu" *ngIf="items.length > 0">
        <p-tabMenu
                  [model]="items"
                  [activeItem]="items[0]"
                  (activeItemChange)="onActiveItemChange($event)"
        >
          <ng-template pTemplate="item" let-item let-i="index">
            <span>{{item.label}}</span>
            <span *ngIf="item.label.includes('Уведомления') && this.unreadNotificationsCount > 0">
                <p-badge class="desktop-badge" value="{{this.unreadNotificationsCount}}"></p-badge>
            </span>
          </ng-template>
        </p-tabMenu>
        <button
                label="Выход"
                class="h-logout-btn"
                (click)="logout()"
                routerLink="/logout"
        >
          <img alt="logo" src="../../../assets/images/logout.svg" class="pr-2"/>
          <span>Выход</span>
        </button>
      </div>
  </div>
  <p-toast position="top-center" styleClass="b-toast"></p-toast>
</div>
