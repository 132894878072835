import { Component } from '@angular/core';
import { Questionnaire, QuestionnairesService } from '@app/components/questioning/questioning.service';

@Component({
  selector: 'app-questioning',
  templateUrl: './questioning.component.html',
  styleUrls: ['./questioning.component.scss'],
  providers: [QuestionnairesService]
})

export class QuestioningComponent{
  
  constructor(private questionnairesService: QuestionnairesService) {}

  questionnaires: Questionnaire[] = []

   ngOnInit(): void {
    this.questionnaires = this.questionnairesService.getQuestionnaire();
 }
}

