import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { NotificationsService } from '@app/services/notifications.service';
import { SharedService } from '@app/services/shared.service';
import { StorageService } from '@app/services/storage.service';
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [MessageService],
})
export class MenuComponent {
  title = 'cabinet';
  index: number = 0;
  items: MenuItem[] = [];
  activeItem: MenuItem;
  backButtonRoute: string = '';
  unreadNotificationsCount: number;
  showCallButton: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private renderer: Renderer2,
    private storage: StorageService,
    private primengConfig: PrimeNGConfig,
    private sharedService: SharedService,
    private notificationService: NotificationsService,
    private cdref: ChangeDetectorRef
  ) {
    let loader = this.renderer.selectRootElement('.preloaderContainer');
    this.renderer.setStyle(loader, 'display', 'none');

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (this.backButtonRoute.length > 0) {
          this.backButtonRoute = '';
          this.cdref.detectChanges();
        }
      }
    });
    this.sharedService.backButtonRoute.subscribe({
      next: (e: any) => {
        this.backButtonRoute = e;
        this.cdref.detectChanges();
      },
    });
    this.sharedService.setShowCallButton.subscribe({
      next: (data: any) => {
        this.showCallButton = data;
      },
    });
    this.sharedService._refreshNotifications.subscribe({
      next: (data: any) => {
        this.refreshNotifications();
      },
    });
    this.refreshNotifications();
  }

  refreshNotifications() {
    this.notificationService.getNotifications().subscribe({
      next: (_items) => {
        this.setMenuItems(
          _items.filter((el) => el.status !== 'Read')
            .length
        );
      },
      error: () => {
        this.setMenuItems();
      },
    });
  }

  ngOnInit() {
    this.primengConfig.setTranslation({
      dayNames: [
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
      ],
      dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      monthNames: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],
      monthNamesShort: [
        'Янв',
        'Фев',
        'Мар',
        'Апр',
        'Май',
        'Июнь',
        'Июль',
        'Авг',
        'Сен',
        'Окт',
        'Ноя',
        'Дек',
      ],
      dateFormat: 'dd.mm.yy',
      today: 'Сегодня',
      weak: 'Неделя',
      emptyMessage: 'Не найдено',
      emptyFilterMessage: 'Не найдено',
    });
    document.body.classList.add('page-loaded');
  }

  setMenuItems(unreadCount: number = 0) {
    this.unreadNotificationsCount = unreadCount;
    this.items = [
      {
        label: 'Главная',
        icon: 'pi pi-home',
        routerLink: '/home',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: 'Маршрутный лист',
        icon: 'pi pi-check-circle',
        routerLink: '/referrals',
      },
      { label: 'Запись', icon: 'pi pi-phone', routerLink: '/book' },
      { label: 'Контакты', icon: 'pi pi-building', routerLink: '/contacts' },
      { label: 'Мои данные', icon: 'pi pi-user', routerLink: '/my-data' },
      {
        label:
          'Уведомления',
        icon: 'pi pi-bell',
        routerLink: '/notifications',
        style: window.innerWidth <= 768 ? { display: 'none' } : {},
        styleClass: this.unreadNotificationsCount > 0 ? 'unread' : '',
      },

    //   {label: '', styleClass: 'itemSep'},
      {
          label: 'Выход', icon: 'pi pi-sign-out', styleClass: 'stickRight', routerLink: '/logout', command: (event) => {
              this.logout();
          },
          style: window.innerWidth > 768 ? { display: 'none' } : {},
      }
    ];
    this.activeItem = this.items[0];
  }

  onActiveItemChange(event: any) {
    this.activeItem = event;
    return this.activeItem;
  }

  currentRouteTitle() {
    if (this.router.config && this.router.routerState.snapshot.url) {
      let routeTitles: { [key: string]: string } = {};
      for (let k in this.router.config) {
        if (this.router.config.hasOwnProperty(k)) {
          let r: { [key: string]: any } = this.router.config[k];
          routeTitles['/' + String(r.path)] = r.hasOwnProperty('title')
            ? String(r.title)
            : '';

          if (r.hasOwnProperty('children')) {
            for (let i in r['children']) {
              if (r['children'].hasOwnProperty(i)) {
                let rr: any = r.children[i];
                routeTitles['/' + r.path + '/' + rr.path] = rr.title;
              }
            }
          }
        }
      }
      if (routeTitles.hasOwnProperty(this.router.routerState.snapshot.url)) {
        return routeTitles[this.router.routerState.snapshot.url];
      }
    }
    return '';
  }

  isLoggedIn() {
    return this.storage.isLoggedIn() && this.storage.getLoaded();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  isMobile(): boolean {
    return window.innerWidth < 768;
  }
}
