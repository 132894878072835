import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Questionnaire, QuestionnairesService } from '@app/components/questioning/questioning.service';
import { StorageService } from '@app/services/storage.service';
import { User } from '@app/models/User';
import { H } from '@app/helpers/helper';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  providers: [QuestionnairesService]
})
export class QuestionnaireComponent implements OnInit{
  
  questionnaire: Questionnaire | undefined

  constructor(
    private route: ActivatedRoute,
    private storage: StorageService,
    private questionnairesService: QuestionnairesService
  ) {}

  questionnaires: Questionnaire[] = []
  user: User
  
  
  ngOnInit(): void {
    this.user = this.storage.getUser();
    this.questionnaires = this.questionnairesService.getQuestionnaire();
    this.route.params.subscribe((params: Params) => {
    this.questionnaire = this.questionnairesService.getById(+params.id)
   })
 }

  getAge(s: string): number {
    let age = H.calculateAge(new Date(s));
    return age
  }
}
