<div class="referralsContainer">
  <p-tabView
    *ngIf="!isViewRefInfo"
    styleClass="b-innerTabs"
    [activeIndex]="activeTabIndex"
    (onChange)="onTabChanged($event)"
  >
    <p-tabPanel header="Предстоящие" [selected]="true">
      <div class="b-referralList">
        <div class="scroll-vertical">
          <div class="b-referralList__absolute">
            <div class="b-referralList__mask"></div>
          </div>
          <div
            *ngIf="documentsUnfinished.length > 0"
            class="b-referralList__grid"
          >
            <div
              *ngFor="let doc of documentsUnfinished.slice(0, offsetUnfinished)"
              class="b-referralList__item"
              [pTooltip]="doc.service"
              tooltipPosition="bottom"
            >
              <div class="b-referralList__item__name" (click)="openReferral(doc)">
                {{ doc.service }}
              </div>
              <div>
                <div class="b-referralList__item__cols">
                  <div>Назначено:</div>
                  <div>
                    {{ getDateFormatted(doc.assignedToDate) }}
                    <span
                      class="b-referralList__item__time"
                      *ngIf="doc.assignedToTime.length > 0"
                    >
                    ({{ doc.assignedToTime }})
                  </span>
                  </div>
                </div>
                <div class="b-referralList__item__cols">
                  <div>Кабинет:</div>
                  <div>{{ doc.room }}</div>
                </div>
                <div class="b-referralList__item__cols">
                  <div>Адрес:</div>
                  <div>
                    {{ doc.case.medicalInstitution.address }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button *ngIf="offsetUnfinished <= documentsUnfinished.length" class="show-more-button" (click)="getReferralsUnfinished()">Показать еще</button>
    </p-tabPanel>

    <p-tabPanel header="Прошедшие">
      <div class="b-referralList">
        <div class="scroll-vertical">
          <div class="b-referralList__absolute">
            <div class="b-referralList__mask"></div>
          </div>
          <div *ngIf="documentsFinished.length > 0" class="b-referralList__grid">
            <div
              *ngFor="let doc of documentsFinished.slice(0, offsetFinished)"
              [ngClass]="{
                'b-referralList__item': true,
                'p-card': true,
                completed: !!doc.conclusionDate
              }"
              [pTooltip]="doc.service"
              tooltipPosition="bottom"
            >
              <div class="b-referralList__item__name" (click)="openReferral(doc)">
                {{ doc.service }}
              </div>
              <div>
                <div class="b-referralList__item__cols">
                  <div>Назначено:</div>
                  <div>
                    {{ getDateFormatted(doc.assignedToDate) }}
                    <span
                      class="b-referralList__item__time"
                      *ngIf="doc.assignedToTime.length > 0"
                    >
                      {{ doc.assignedToTime }} )
                    </span>
                  </div>
                </div>
                <div class="b-referralList__item__cols">
                  <div>Кабинет:</div>
                  <div>{{ doc.room }}</div>
                </div>
                <div
                  class="b-referralList__item__cols b-referralList__item__conclusion"
                  *ngIf="doc.conclusionDate"
                >
                  <div>Дата заключения:</div>
                  <div>{{ getDateFormatted(doc.conclusionDate) }}</div>
                </div>
                <div class="b-referralList__item__cols">
                  <div>Адрес:</div>
                  <div>
                    {{ doc.case.medicalInstitution.address }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button *ngIf="offsetFinished <= documentsFinished.length" class="show-more-button" (click)="getReferralsFinished()">Показать еще</button>
    </p-tabPanel>

    <p-tabPanel header="Рекомендации" headerStyleClass="recommends">
      <div class="b-referralList">
        <div class="scroll-vertical">
          <div class="b-referralList__absolute">
            <div class="b-referralList__mask"></div>
          </div>
          <div
            *ngIf="documentsRecommends.length > 0"
            class="b-referralList__grid"
          >
            <div
              *ngFor="let doc of documentsRecommends.slice(0, offsetRecommends)"
              [ngClass]="{
                'b-referralList__item': true,
                'p-card': true,
                completed: !!doc.conclusionDate
              }"
              [pTooltip]="doc.service"
              tooltipPosition="bottom"
            >
              <div class="b-referralList__item__name" (click)="openReferral(doc)">
                {{ doc.service }}
              </div>
              <div>
                <div class="b-referralList__item__cols">
                  <div>Назначено:</div>
                  <div>
                    {{ getDateFormatted(doc.assignedToDate) }}
                    <span
                      class="b-referralList__item__time"
                      *ngIf="doc.assignedToTime.length > 0"
                    >
                      ({{ doc.assignedToTime }} )
                    </span>
                  </div>
                </div>
                <div class="b-referralList__item__cols">
                  <div>Кабинет:</div>
                  <div>{{ doc.room }}</div>
                </div>
                <div
                  class="b-referralList__item__cols b-referralList__item__conclusion"
                  *ngIf="doc.conclusionDate"
                >
                  <div>Дата заключения:</div>
                  <div>{{ getDateFormatted(doc.conclusionDate) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button *ngIf="offsetRecommends <= documentsRecommends.length" class="show-more-button" (click)="getReferralsRecomended()">Показать еще</button>
    </p-tabPanel>
  </p-tabView>

  <div *ngIf="isViewRefInfo" class="refinfo">
    <button class="ref-back-btn" (click)="backToList()">
      <img alt="logo" src="../../../assets/images/ArrowBack.png" />
      <span class="pl-3 uppercase p-button-label">Назад</span>
    </button>
    <div class="box box-width">
      <div class="text-center pa-mb-5 ref-address">
        <img *ngIf="barcodeUrl.length > 0" [src]="barcodeUrl" alt="barcode" class="ref-img">
        <div>
          {{ refInfo.case.medicalInstitution.name }}
        </div>
      </div>
      <div class="grid" style="position: relative">
        <div class="col-12 flex-order-1 ref-info">
          <div class="block">
            <div class="grid">
              <div class="col-4 ref-info__title">Направление</div>
              <div class="col ref-info__value">
                {{ refInfo.service }}
              </div>
            </div>
          </div>
          <div class="block">
            <div class="grid">
              <div class="col-4 ref-info__title">Отделение, кабинет</div>
              <div class="col ref-info__value">{{ refInfo.room }}</div>
            </div>
          </div>
          <div class="block">
            <div class="grid">
              <div class="col-4 ref-info__title">Дата назначения</div>
              <div class="col ref-info__value">
                {{ getDateFormatted(refInfo.assignedToDate)
                }}<span *ngIf="refInfo.assignedToTime.length > 0">
                  ({{ refInfo.assignedToTime }} )</span
                >
              </div>
            </div>
          </div>
          <div class="block">
            <div class="grid">
              <div class="col-4 ref-info__title">Назначил</div>
              <div class="col ref-info__value">
                {{ getFIOAssignedBy(refInfo.assignedBy) }}
              </div>
            </div>
          </div>
          <div class="block">
            <div class="grid">
              <div class="col-4 ref-info__title">Адрес</div>
              <div class="col ref-info__value">
                {{ refInfo.case.medicalInstitution.address }}
              </div>
            </div>
          </div>
          <div class="block" *ngIf="refInfo.completeBy">
            <div class="grid">
              <div class="col-4 ref-info__title">Выполнить до</div>
              <div class="col ref-info__value">
                {{ getDateFormatted(refInfo.completeBy) }}
              </div>
            </div>
          </div>
          <hr />
          <div class="block">
            <div class="grid">
              <div class="col-4 ref-info__title">ФИО</div>
              <div class="col ref-info__value">
                {{ getFIO(user) }}
              </div>
            </div>
          </div>
          <div class="block">
            <div class="grid">
              <div class="col-4 ref-info__title">АК, №</div>
              <div class="col ref-info__value">{{ case.caseNumber }}</div>
            </div>
          </div>
          <div class="block">
            <div class="grid">
              <div class="col-4 ref-info__title">Место работы</div>
              <div class="col ref-info__value">
                {{ user.employeePlace }}
              </div>
            </div>
          </div>
          <div class="block">
            <div class="grid">
              <div class="col-4 ref-info__title">Должность</div>
              <div class="col ref-info__value">
                {{ user.positionOfEmployment }}
              </div>
            </div>
          </div>
          <div class="block">
            <div class="grid">
              <div class="col-4 ref-info__title">Дата рождения</div>
              <div class="col ref-info__value">
                {{ getDateFormatted(user.birthDate) }}
              </div>
            </div>
          </div>
          <div class="block">
            <div class="grid">
              <div class="col-4 ref-info__title">Источник финансирования</div>
              <div class="col ref-info__value">
                {{ case.sourcesOfFinancing }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ref-logo">
        <img src="/assets/images/rzd_logo.png" alt="логотип" />
      </div>
    </div>
  </div>
</div>