import {Component, OnInit, HostListener, ChangeDetectorRef, ViewChild, AfterViewInit} from '@angular/core';
import {User} from "@app/models/User";
import {MenuItem} from "primeng/api";
import {Router} from "@angular/router";
import {AuthService} from "@app/services/auth.service";
import {StorageService} from "@app/services/storage.service";
import {ActivatedRoute} from '@angular/router';
import { TabView } from 'primeng/tabview';
@Component({
    selector: 'app-my-data',
    // selector: 'app-profile',
    templateUrl: './my-data.component.html',
    styleUrls: ['./my-data.component.scss']
})
export class MyDataComponent implements OnInit, AfterViewInit {

    user: User;
    items: MenuItem[];
    activeItem: MenuItem;
    loading = false;
    activeTabIndex: number = 0;
    isScrollable: boolean = true;
    @ViewChild('myDataTabView') myDataTabView!: TabView;

    constructor(
      private router: Router,
      private authService: AuthService,
      private storage: StorageService,
      private route: ActivatedRoute,
      private cdr: ChangeDetectorRef
    ) {
        this.isScrollable = window.innerWidth <= 1024;
    }
    ngOnInit(): void {
        this.loading = true;
        this.user = this.storage.getUser();
        this.items = [
            {label: 'Мой профиль'},
            {label: 'Медицинская карта'},
            {label: 'Мои записи'},
            {label: 'Оцените состояние своего здоровья'},
            {label: 'Анкеты диспансеризации'}
        ];
        this.activeItem = this.items[0];
    }

    ngAfterViewInit() {
        this.route.queryParams.subscribe(params => {
            this.activeTabIndex = this.getTabIndex(params['tab']);

            this.cdr.detectChanges();
        });
    }

    getTabIndex(tabName: string): number {
        // Default tab: 0 when tabName is null or undefined
        if (!tabName) return 0;
    
        let selectedIndex = this.myDataTabView.tabs.findIndex(
          x => x.header.toLowerCase() === tabName.toLowerCase()
        );
    
        if (selectedIndex > -1) return selectedIndex;
    
        // Default tab: 0 when tabName is not exist
        return 0;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.isScrollable = window.innerWidth <= 1024;
    }
    onTabChanged(event:any) {
        this.activeTabIndex = event.index;
    }

}
