
<form class="calcCard" [formGroup]="form" (ngSubmit)="submit()">
  <div class="calcWrapper">
    <div class="radio-field">

      <div class="radio-control">
        <label class="calc-radio-label" for="0">1. Появилась тяга к сладкому?</label>
        <div class="radio-input-container">
          <input type="radio" name="0" formControlName="0" value="1" class="radio"> Да
          <input type="radio" name="0" formControlName="0" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="1">2. Общая слабость организма?</label>
        <div class="radio-input-container">
          <input type="radio" name="1" formControlName="1" value="1" class="radio"> Да
          <input type="radio" name="1" formControlName="1" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="2">3. При ходьбе начали сильно утомляться?</label>
        <div class="radio-input-container">
          <input type="radio" name="2" formControlName="2" value="1" class="radio"> Да
          <input type="radio" name="2" formControlName="2" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="3">4. Возникает ощущение тяжести и слабости в ногах?</label>
        <div class="radio-input-container">
          <input type="radio" name="3" formControlName="3" value="1" class="radio"> Да
          <input type="radio" name="3" formControlName="3" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="4">5. Вы стали более раздражительны?</label>
        <div class="radio-input-container">
          <input type="radio" name="4" formControlName="4" value="1" class="radio"> Да
          <input type="radio" name="4" formControlName="4" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="5">6. У Вас отмечается снижение или даже потеря чувствительности в конечностях?</label>
        <div class="radio-input-container">
          <input type="radio" name="5" formControlName="5" value="1" class="radio"> Да
          <input type="radio" name="5" formControlName="5" value="0" class="radio"> Нет
        </div>
      </div>

    </div>

    <div class="radio-field">
      <div class="radio-control">
        <label class="calc-radio-label" for="6">7. Вас беспокоят головные боли?</label>
        <div class="radio-input-container">
          <input type="radio" name="6" formControlName="6" value="1" class="radio"> Да
          <input type="radio" name="6" formControlName="6" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="7">8. Стали замечать нарастающее ухудшение памяти?</label>
        <div class="radio-input-container">
          <input type="radio" name="7" formControlName="7" value="1" class="radio"> Да
          <input type="radio" name="7" formControlName="7" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="8">9. Часто ли у Вас случаются поносы?</label>
        <div class="radio-input-container">
          <input type="radio" name="8" formControlName="8" value="1" class="radio"> Да
          <input type="radio" name="8" formControlName="8" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="9">10. Снизился аппетит?</label>
        <div class="radio-input-container">
          <input type="radio" name="9" formControlName="9" value="1" class="radio"> Да
          <input type="radio" name="9" formControlName="9" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="10">11. Вы стали менее инициативны?</label>
        <div class="radio-input-container">
          <input type="radio" name="10" formControlName="10" value="1" class="radio"> Да
          <input type="radio" name="10" formControlName="10" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="11">12. Ваше настроение постоянно снижено?</label>
        <div class="radio-input-container">
          <input type="radio" name="11" formControlName="11" value="1" class="radio"> Да
          <input type="radio" name="11" formControlName="11" value="0" class="radio"> Нет
        </div>
      </div>

      <button class="btn" type="submit" [disabled]="form.invalid">Рассчитать</button>
      <div class="calc-response-container">
        <div class="calc-response-title" style="padding-top: 1rem">Ответ:</div>
        <div class="result">{{result}}</div>
        <div *ngIf="score > 7" class="hint">Вам следует нормализовать функцию кишечника, рекомендована консультация специалиста.</div>
        <div *ngIf="score >= 5 && score <= 7" class="hint"> Вам необходимо обратить внимание на свой пищевой рацион, включив в него мясо, почки, печень, мозг, яичный желток, орехи, кисломолочные продукты, орехи и зелень, хлеб ржаной и пшеничный простого помола, пивные дрожжи. В обязательном порядке следует резко ограничить (а еще лучше – полностью исключить) прием спиртных напитков.</div>

      </div>

    </div>
  </div>

  <div class="warning-text">
    *Результат проведенного тестирования носит рекомендательный характер и предоставляется в ознакомительных целях.
    <br> За более подробной информацией обратитесь к врачу.
  </div>
  
</form>
