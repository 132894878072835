import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Calc, CalcsService } from '@app/components/health-status/calcs.service';

@Component({
  selector: 'app-calc',
  templateUrl: './calc.component.html',
  styleUrls: ['./calc.component.scss']
})
export class CalcComponent implements OnInit{
  calc: Calc | undefined

  constructor(
    private route: ActivatedRoute,
    private calcsService: CalcsService
  ) {}

 ngOnInit(): void {
   this.route.params.subscribe((params: Params) => {
    this.calc = this.calcsService.getById(+params.id)
   })
 }
}
