import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Document} from "@app/models/Document";
import { Case } from '@app/models/Case';
import {H} from "@app/helpers/helper";
import {environment} from "@environments/environment";
import {StorageService} from "@app/services/storage.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'app-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {

    @Input() documents: Document[];
    @Output() openDocumentEvent = new EventEmitter<Document>();

    _selectedCase: Case;
    @Input()
        set selectedCase(selectedCase: Case) {
                this._selectedCase = selectedCase;
        }
        get selectedCase() { return this._selectedCase; }
    
    constructor(private http: HttpClient) {
    }

    ngOnInit(): void {
    }

    openDocument(doc: Document) {
        this.openDocumentEvent.emit(doc);
    }
    downloadDocument(doc: Document) {
        this.http.get(`${environment.api_endpoint}/api/documents/${doc.id}/file`, { responseType: 'blob' }).subscribe(blob => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = doc.name;
            link.click();
            URL.revokeObjectURL(link.href);
        });
    }

    getDateFormatted(inDate: string): string {
        return H.getDateFormatted(inDate);
    }

}
