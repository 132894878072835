<div class="myDataContainer">

  <p-tabView #myDataTabView
    styleClass="b-innerTabs"
    [scrollable]="isScrollable"
    [activeIndex]="activeTabIndex"
    (onChange)="onTabChanged($event)"
  >
  <p-tabPanel header="Мой профиль" [selected]="true" ><app-profile></app-profile></p-tabPanel>
  <p-tabPanel header="Медицинская карта"><app-medkarta></app-medkarta></p-tabPanel>
  <p-tabPanel header="Мои записи"><app-bookings></app-bookings></p-tabPanel>
  <p-tabPanel header="Оцените состояние своего здоровья"><app-health-status></app-health-status></p-tabPanel>
  <p-tabPanel header="Анкеты диспансеризации"><app-questioning></app-questioning></p-tabPanel>
</p-tabView>
</div>


