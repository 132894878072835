<form class="calcCard" [formGroup]="form" (ngSubmit)="submit()">
  <div class="calcWrapper">
    <div class="radio-field">


      <div class="radio-control">
        <label class="calc-radio-label" for="0">1. Бывает ли у Вас ноющая боль тупого характера после еды, особенно при употреблении острых или жирных блюд?</label>
        <div class="radio-input-container">
          <input type="radio" name="0" formControlName="0" value="1" class="radio"> Да
          <input type="radio" name="0" formControlName="0" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="1">2. Бывают ли ночные боли в области желудка?</label>
        <div class="radio-input-container">
          <input type="radio" name="1" formControlName="1" value="1" class="radio"> Да
          <input type="radio" name="1" formControlName="1" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="2">3. Возникает ли ощущение тяжести после любого приема пищи?</label>
        <div class="radio-input-container">
          <input type="radio" name="2" formControlName="2" value="1" class="radio"> Да
          <input type="radio" name="2" formControlName="2" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">

        <label class="calc-radio-label" for="3">4. Часто ли беспокоит изжога?</label>
        <div class="radio-input-container">
          <input type="radio" name="3" formControlName="3" value="1" class="radio"> Да
          <input type="radio" name="3" formControlName="3" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">

        <label class="calc-radio-label" for="4">5. Наступает ли облегчение изжоги после того, как выпит содовый раствор?</label>
        <div class="radio-input-container">
          <input type="radio" name="4" formControlName="4" value="0" class="radio"> Да
          <input type="radio" name="4" formControlName="4" value="1" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="5">6. Появляется ли временами кислая отрыжка?</label>
        <div class="radio-input-container">
          <input type="radio" name="5" formControlName="5" value="1" class="radio"> Да
          <input type="radio" name="5" formControlName="5" value="0" class="radio"> Нет
        </div>
      </div>
    </div>

    <div class="radio-field">
      <div class="radio-control">
        <label class="calc-radio-label" for="6">7. Участились нарушения со стулом (запор, понос)?</label>
        <div class="radio-input-container">
          <input type="radio" name="6" formControlName="6" value="1" class="radio"> Да
          <input type="radio" name="6" formControlName="6" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="7">8. Появились ли нарушения сна (Проблематично заснуть, частые просыпания)?</label>
        <div class="radio-input-container">
          <input type="radio" name="7" formControlName="7" value="1" class="radio"> Да
          <input type="radio" name="7" formControlName="7" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="8">9. Стали замечать за собой повышенную утомляемость?</label>
        <div class="radio-input-container">
          <input type="radio" name="8" formControlName="8" value="1" class="radio"> Да
          <input type="radio" name="8" formControlName="8" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="9">10. Стали более раздражительны?</label>
        <div class="radio-input-container">
          <input type="radio" name="9" formControlName="9" value="1" class="radio"> Да
          <input type="radio" name="9" formControlName="9" value="0" class="radio"> Нет
        </div>
      </div>
      <div class="radio-control">
        <label class="calc-radio-label" for="10">11. Появилось ли повышенное потоотделение?</label>
        <div class="radio-input-container">
          <input type="radio" name="10" formControlName="10" value="1" class="radio"> Да
          <input type="radio" name="10" formControlName="10" value="0" class="radio"> Нет
        </div>
      </div>

      <button class="btn" type="submit" [disabled]="form.invalid">Рассчитать</button>
      <div class="calc-response-container">
        <div class="calc-response-title" style="padding-top: 1rem">Ответ:</div>
        <div class="result">{{result}}</div>
      </div>

    </div>
  </div>

  <div class="warning-text">
    *Результат проведенного тестирования носит рекомендательный характер и предоставляется в ознакомительных целях.
    <br> За более подробной информацией обратитесь к врачу.
  </div>
</form>





