<div class="archiveContainer">

  <button class="ref-back-btn" routerLink="/my-data" [queryParams]="{ tab: 'Медицинская карта' }">
    <img alt="logo" src="../../../../assets/images/ArrowBack.png"/>
    <span class="pl-3 uppercase p-button-label">Назад</span><span *ngIf="!isPdfViewerEnabled && selectedCase" style="color: #FF4123"> / Посещение &laquo;{{selectedCase.caseNumber}}&raquo;</span>
  </button>

  <div *ngIf="!selectedCase" class="archiveContainer-title">Архив</div>

  <div>
    <div class="tableContainer" *ngIf="!selectedCase">
      <table class="table is-fullwidth" *ngIf="cases && cases.length > 0">
        <thead>
        <tr>
          <th>Посещение</th>
          <th>Дата открытия</th>
          <th>Дата закрытия</th>
          <th>Дата заключения</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let archiveCase of cases" (click)="selectCase(archiveCase)">
          <td>{{archiveCase.caseNumber}}</td>
          <td>{{getDateFormatted(archiveCase.startDate)}}</td>
          <td>{{getDateFormatted(archiveCase.endDate)}}</td>
          <td>{{getDateFormatted(archiveCase.conclusionDate)}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="selectedCase">
    <div class="medkarta-cards-container">
      <div class="medkarta-card" [routerLink]="['/my-data/medkarta/conclusions', selectedCase.id]">
        <img alt="conclusions" src="./assets/images/medkarta/conclusions.svg"/>
        <div class="medkarta-card-title">Заключения</div>
      </div>

      <div class="medkarta-card" [routerLink]="['/my-data/medkarta/results', selectedCase.id]">
        <img alt="results" src="./assets/images/medkarta/results.svg"/>
        <div class="medkarta-card-title">Анализы</div>
      </div>

      <div class="medkarta-card" [routerLink]="['/my-data/medkarta/researches', selectedCase.id]">
        <img alt="researches" src="./assets/images/medkarta/researches.svg"/>
        <div class="medkarta-card-title">Исследования</div>
      </div>
    </div>
  </div>

</div>
