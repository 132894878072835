<div class="container">
    <div class="button-container">
        <button
            class="toggle"
            (click)="isEng = true"
            [ngClass]="{
                'active': isEng
            }"
        >
            ENG
        </button>
        <button
            class="toggle"
            (click)="isEng = false"
            [ngClass]="{
                'active': !isEng
            }"
        >
            RU
        </button>
    </div>
    <div class="logo">
        <img src="/assets/images/favicon.png" width="128" alt="logo">
    </div>
        <div *ngIf="!isEng">
        <h1>Политика конфиденциальности</h1>
        <div class="text">
            <div>
                ЧУЗ «КБ «РЖД-Медицина» им. Н.А. Семашко» сделал приложение бесплатным. Данная УСЛУГА предоставляется ЧУЗ «КБ «РЖД-Медицина» им. Н.А. Семашко» на безвозмездной основе и предназначен для использования как есть.
            </div>
            <div>
                Эта страница используется для информирования посетителей о наших правилах сбора, использования и раскрытия Личной информации, если кто-либо решил воспользоваться нашим Сервисом.
            </div>
            <div>
                Если вы решите использовать наш Сервис, вы соглашаетесь на сбор и использование информации в связи с этой политикой. Личная информация, которую мы собираем, используется для предоставления и улучшения Сервиса. Мы не будем использовать или передавать вашу информацию кому-либо, кроме случаев, описанных в настоящей Политике конфиденциальности.
            </div>
            <div>
                Термины, используемые в настоящей Политике конфиденциальности, имеют те же значения, что и в наших Условиях и положениях, которые доступны по адресу, если иное не определено в настоящей Политике конфиденциальности.
            </div>
            <h2>Сбор и использование информации</h2>
            <div>
                Для лучшего опыта при использовании нашего Сервиса Мы можем потребовать от вас предоставить нам определенную личную информацию (имя пользователя, адрес, местоположение, фотографии). Информация, которую мы запрашиваем, будет храниться на вашем устройстве и никоим образом не собирается нами.
            </div>
            <h2>Данные журнала</h2>
            <div>
                Мы хотим сообщить вам, что всякий раз, когда вы используете наш Сервис, в случае ошибки в приложении мы собираем данные и информацию (через сторонние продукты) на вашем телефоне под названием «Данные журнала». Эти данные журнала могут включать в себя такую информацию, как адрес интернет-протокола («IP») вашего устройства, имя устройства, версия операционной системы, конфигурация приложения при использовании нашего Сервиса, время и дата использования вами Сервиса и другие статистические данные.
            </div>
            <h2>Файлы cookie</h2>
            <div>
                Файлы cookie — это файлы с небольшим объемом данных, которые обычно используются в качестве анонимных уникальных идентификаторов. Они отправляются в ваш браузер с веб-сайтов, которые вы посещаете, и сохраняются во внутренней памяти вашего устройства.
            </div>
            <div>
                Эта Служба не использует эти «куки» в явном виде. Однако приложение может использовать сторонний код и библиотеки, которые используют файлы cookie для сбора информации и улучшения своих услуг. У вас есть возможность либо принять, либо отказаться от этих файлов cookie и узнать, когда файл cookie отправляется на ваше устройство. Если вы решите отказаться от наших файлов cookie, вы не сможете использовать некоторые части этой Услуги.
            </div>
            <h2>Поставщики услуг</h2>
            <div>Мы можем нанимать сторонние компании и частных лиц по следующим причинам:</div>
            <ul>
                <li> - Для облегчения нашего Сервиса;</li>
                <li> - Для оказания услуг, связанных с Сервисом; </li>
                <li> - Для предоставления Сервиса от нашего имени;</li>
                <li> - Чтобы помочь нам проанализировать, как используется наш Сервис.</li>
            </ul>
            <div>
                Мы хотим сообщить пользователям этого Сервиса, что эти третьи стороны имеют доступ к их Личной информации. Причина в том, чтобы выполнять возложенные на них задачи от нашего имени. Однако они обязаны не разглашать и не использовать информацию для каких-либо других целей.
            </div>
            <h2>Безопасность</h2>
            <div>
                Мы ценим ваше доверие в предоставлении нам вашей личной информации, поэтому мы стремимся использовать коммерчески приемлемые средства ее защиты. Но помните, что ни один метод передачи через Интернет или метод электронного хранения не является на 100% безопасным и надежным, и Мы не можем гарантировать его абсолютную безопасность.
            </div>
            <h2>Ссылки на другие сайты</h2>
            <div>
                Эта Служба может содержать ссылки на другие сайты. Если вы нажмете на стороннюю ссылку, вы будете перенаправлены на этот сайт. Обратите внимание, что эти внешние сайты не управляются нами. Поэтому мы настоятельно рекомендуем вам ознакомиться с Политикой конфиденциальности этих веб-сайтов. Мы не контролируем и не несем ответственности за содержание, политику конфиденциальности или действия любых сторонних сайтов или служб.
            </div>
            <h2>Конфиденциальность сторонних сервисов</h2>
            <div>
                Приложение использует сторонние сервисы, которые могут собирать информацию, используемую для вашей идентификации.
            </div>
            <h2>Изменения в настоящей Политике конфиденциальности</h2>
            <div>
                Мы можем время от времени обновлять нашу Политику конфиденциальности. Таким образом, вам рекомендуется периодически просматривать эту страницу на предмет любых изменений. Мы сообщим вам о любых изменениях, опубликовав новую Политику конфиденциальности на этой странице.
                <br/>
                Эта политика действует с 13 декабря 2022 г
            </div>
            <h2>Свяжитесь с нами</h2>
            <div>
                Если у вас есть какие-либо вопросы или предложения относительно нашей Политики конфиденциальности, не стесняйтесь обращаться к нам по адресу info@semashko.com.
            </div>
        </div>
    </div>
    <div *ngIf="isEng">
    <h1>Privacy Policy</h1>
    <div class="text">
        <div>
            KB RZHD-MEDITSINA IM.N.A.SEMASHKO built the app as free app. This SERVICE is provided by KB RZHD-MEDITSINA IM.N.A.SEMASHKO at no cost and is intended for use as is.
        </div>
        <div>
            This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
            If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that We collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
        </div>
        <div>
            The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at unless otherwise defined in this Privacy Policy.
        </div>   
        <h2>Information Collection and Use</h2>
        <div>
            For a better experience, while using our Service, We may require you to provide us with certain personally identifiable information (users name, address, location, pictures). The information that We request will be etained on your device and is not collected by us in any way.
        </div>
        <h2>Log Data</h2>
        <div>
            We want to inform you that whenever you use our Service, in a case of an error in the app We collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
        </div>
        <h2>Cookies</h2>
        <div>
            Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
        </div>
        <div>
            This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
        </div>
        <h2>Service Providers</h2>
        <div>We may employ third-party companies and individuals due to the following reasons:</div>
        <ul>
            <li> - To facilitate our Service;</li>
            <li> - To provide the Service on our behalf;</li>
            <li> - To perform Service-related services;</li>
            <li> - To assist us in analyzing how our Service is used.</li>
        </ul>
        <div>
            We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
        </div>
        <h2>Security</h2>
        <div>
            We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and We cannot guarantee its absolute security.
        </div>
        <h2>Links to Other Sites</h2>
        <div>
            This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, We strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
        </div>
        <h2>Third Party Privacy</h2>
        <div>
            The app does use third-party services that may collect information used to identify you.
        </div>
        <h2>Changes to This Privacy Policy</h2>
        <div>
            We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
            <br/>
            This policy is effective as of 2022-12-13
        </div>
        <h2>Contact Us</h2>
        <div>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at info@semashko.com.</div>
    </div>
</div>
    
</div>