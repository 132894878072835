import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@app/services/alert.service';
import { QuestionnairesService } from '@app/components/questioning/questioning.service';

@Component({
  selector: 'app-ncds-middle',
  templateUrl: './ncds.component.html',
  styleUrls: ['../../q_style.component.scss'],
})
export class NCDsComponent {
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(
    private router: Router,
    public alertService: AlertService,
    private questionnairesService: QuestionnairesService,
  ){}

  form: FormGroup;

  ngOnInit() {

    this.form = new FormGroup({
      '1.1': new FormControl('', Validators.required),
      '1.1.1': new FormControl(''),
      '1.2': new FormControl('', Validators.required),
      '1.3': new FormControl('', Validators.required),
      '1.4': new FormControl('', Validators.required),
      '1.5': new FormControl('', Validators.required),
      '1.6': new FormControl('', Validators.required),
      '1.6.1': new FormControl(''),
      '1.7': new FormControl('', Validators.required),
      '1.8': new FormControl('', Validators.required),
      '1.9': new FormControl('', Validators.required),
      '1.9.1': new FormControl(''),
      '1.10': new FormControl('', Validators.required),
      '1.10.1': new FormControl(''),
      '2': new FormControl('', Validators.required),
      '3': new FormControl('', Validators.required),
      '4': new FormControl('', Validators.required),
      '5': new FormControl('', Validators.required),
      '6': new FormControl('', Validators.required),
      '7': new FormControl(''),
      '8': new FormControl('', Validators.required),
      '9': new FormControl('', Validators.required),
      '10': new FormControl('', Validators.required),
      '11': new FormControl('', Validators.required),
      '12': new FormControl('', Validators.required),
      '13': new FormControl('', Validators.required),
      '14': new FormControl('', Validators.required),
      '15': new FormControl('', Validators.required),
      '16': new FormControl('', Validators.required),
      '17': new FormControl('', Validators.required),
      '18': new FormControl('', Validators.required),
      '19': new FormControl('', Validators.required),
      '20': new FormControl('', Validators.pattern(/^[0-9]*[.,]?[0-9]+$/)),
      '21': new FormControl('', Validators.required),
      '22': new FormControl('', Validators.required),
      '23': new FormControl('', Validators.required),
      '24': new FormControl('', Validators.required),
      '25': new FormControl('', Validators.required),
      '26': new FormControl(''),
      '27': new FormControl('', Validators.required),
    })
  }

  
  submit(data: any) {
    this.questionnairesService.submit(data).subscribe({
      next: (resp) => {
          this.router.navigate(['/my-data/questioning']);
          this.alertService.success('Анкета успешно отправлена', this.options)
      },
      error: (resp) => {
       resp.status === 404 
       ? this.alertService.error('Ошибка запроса', this.options)
       : this.alertService.error('Запрос не может быть обработан. Повторите позднее', this.options)
      }
    })
  }

}
