import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@app/services/alert.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionnairesService } from '@app/components/questioning/questioning.service';

@Component({
  selector: 'app-covid',
  templateUrl: './covid.component.html',
  styleUrls: ['../q_style.component.scss']
})
export class CovidComponent {
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(
    private router: Router,
    public alertService: AlertService,
    private questionnairesService: QuestionnairesService,
  ){}

  form: FormGroup

  ngOnInit() {   
    this.form = new FormGroup({
      "1": new FormControl('', Validators.required),
      "2": new FormControl(''),
      "2.1": new FormControl('',  Validators.pattern(/^[0-9]*[.,]?[0-9]+$/)),
      "3": new FormControl('', Validators.required),
      "3.1": new FormControl('', Validators.required),
      "3.2": new FormControl('', Validators.required),
      "3.3": new FormControl('', Validators.required),
      "3.4": new FormControl('', Validators.required),
      "3.5": new FormControl('', Validators.required),
      "3.6": new FormControl('', Validators.required),
      "3.7": new FormControl('', Validators.required),
      "3.8": new FormControl('', Validators.required)
    })
  }

  
  submit(data: any) {
    this.questionnairesService.submit(data).subscribe({
      next: (resp) => {
          this.router.navigate(['/my-data/questioning']);
          this.alertService.success('Анкета успешно отправлена', this.options)
      },
      error: (resp) => {
       resp.status === 404 
       ? this.alertService.error('Ошибка запроса', this.options)
       : this.alertService.error('Запрос не может быть обработан, повторите позднее', this.options)
      }
    })
  }

}
