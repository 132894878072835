<div
  class="{{cssClass(alert)}}"
  *ngFor="let alert of alerts"
  (click)="removeAlert(alert)"
>
  <div [innerHTML]="alert.message"></div>
  <img
    class="img"
    alt="close-icon"
    (click)="removeAlert(alert)"
    src="../../../assets/images/close.svg"
  />
</div>
