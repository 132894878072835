import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "@environments/environment";
import {Document} from "@app/models/Document";
import {Referral} from "@app/models/Referral";
import {Banner} from "@app/models/Banner";
import {BookRecord, Resource} from "@app/models/BookRecord";
import {Profile} from "@app/models/Profile";
import {Schedule, ScheduleDates} from "@app/models/Schedule";
import {ReferralInfo} from "@app/models/ReferralInfo";
import {Constants} from "@app/helpers/constants";
import {Case} from "@app/models/Case";
import {Diagram} from '@app/models/Diagram';

@Injectable({providedIn: 'root'})
export class UserService {
    constructor(private http: HttpClient) {
    }

    getCases() {
        return this.http.get<Case[]>(`${environment.api_endpoint}/api/cases`, {
            params: {
                limit: 300,
                offset: 0
            }
        });
    }

    getDocuments(type: number) {
        return this.http.get<Document[]>(`${environment.api_endpoint}/api/documents`, {
            params: {
                type: type
            }
        });
    }

    getCaseDocuments(caseId: number, type: number = 0) {
        return this.http.get<Document[]>(`${environment.api_endpoint}/api/cases/${caseId}/documents`, {
            params: {
                type: type
            }
        });
    }

    getDocument(documentId: number) {
        return this.http.get<Referral[]>(`${environment.api_endpoint}/api/documents/${documentId}/file`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        });
    }

    getReferrals(offset: number = 0, limit: number = 10, type: number = Constants.REFERRAL_TYPE_REFERRAL, completed: boolean = false) {
        return this.http.get<Referral[]>(`${environment.api_endpoint}/api/referrals`, {
            params: {
                limit: limit,
                offset: offset,
                completed: completed,
                type: type
            }
        });
    }

    getReferralInfo(ref: Referral) {
        return this.http.get<ReferralInfo>(`${environment.api_endpoint}/api/referrals/${ref.id}`);
    }

    getReferralBarcode(refId: number) {
        return this.http.get(`${environment.api_endpoint}/api/referrals/${refId}/barcode`, {
            responseType: 'blob'
        })
    }

    getBanners() {
        return this.http.get<Banner[]>(`${environment.api_endpoint}/api/banners`);
    }

    getBookings(offset: number = 0, limit: number = 300, completed: boolean = false) {
        return this.http.get<BookRecord[]>(`${environment.api_endpoint}/api/bookings`, {
            params: {
                limit: limit,
                offset: offset,
                completed: completed
            }
        });
    }

    cancelBooking(book: BookRecord) {
        return this.http.delete<any>(`${environment.api_endpoint}/api/bookings/${book.id}`);
    }

    getProfiles() {
        return this.http.get<Profile[]>(`${environment.api_endpoint}/api/profiles`, {
            params: {
                limit: 300,
                offset: 0
            }
        });
    }

    getProfileResources(profileId: number) {
        return this.http.get<Resource[]>(`${environment.api_endpoint}/api/profiles/${profileId}/resources`, {
            params: {
                limit: 300,
                offset: 0
            }
        });
    }

    getProfileResourceSchedule(resourceId: number, date: string = '') {
        return this.http.get<Schedule[]>(`${environment.api_endpoint}/api/resources/${resourceId}/slots/free`, {
            params: {
                limit: 300,
                offset: 0,
                start: date,
                end: date
            }
        });
    }

    getProfileResourceSlotsDatesSchedule(resourceId: number, date: string = '') {
        return this.http.get<ScheduleDates[]>(`${environment.api_endpoint}/api/resources/${resourceId}/slots/free/dates`, {
            params: {
                start: date,
                end: date
            }
        });
    }

    book(slotId: number) {
        return this.http.post<any>(`${environment.api_endpoint}/api/bookings`, {
            "slotId": slotId
        });
    }

    getDiagram() {
        return this.http.get<Diagram>(`${environment.api_endpoint}/api/diagram`);
    }
}
