
<form
  [formGroup]="form"
  class="form_container"
  #dataFormElderly="ngForm"
  (ngSubmit)="submit(dataFormElderly.value)"
>
  <div class="form-control">
    <label class="calc-radio-label">
      <div class="d-grid">
        <div>1.</div>
        <div>Говорил ли Вам врач когда-либо, что у Вас имеется:</div>
      </div>
    </label>
    <label class="calc-radio-label" for="1.1">
      <div class="d-grid">
        <div>1.1.</div>
        <div>Гипертоническая болезнь, повышенное артериальное давление (артериальная гипертония)?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.1"
              type="radio"
              class="radio"
              formControlName="1.1"
            />
            Да
            <input
              ngModel
              name="1.1"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.1"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[1.1].value === 'Да'" class="optional">
      <label class="calc-radio-label" for="1.1.1">Если «Да», то принимаете ли Вы препараты для снижения давления?</label>
      <div class="radio-container">
        <input
          ngModel
          value="Да"
          name="1.1.1"
          type="radio"
          class="radio"
          formControlName="1.1.1"
        />
        Да
        <input
          ngModel
          value="Нет"
          name="1.1.1"
          type="radio"
          class="radio"
          formControlName="1.1.1"
        />
        Нет
      </div>
    </div>
    <label class="calc-radio-label" for="1.2">
      <div class="d-grid">
        <div>1.2.</div>
        <div>Сахарный диабет или повышенный уровень глюкозы (сахара) в крови?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.2"
              type="radio"
              class="radio"
              formControlName="1.2"
            />
            Да
            <input
              ngModel
              name="1.2"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.2"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[1.2].value === 'Да'" class="optional">
      <label class="calc-radio-label" for="1.2.1">
        Если «Да», то принимаете ли Вы препараты для снижения уровня сахара?
      </label>
      <div class="radio-container">
        <input
          ngModel
          value="Да"
          name="1.2.1"
          type="radio"
          class="radio"
          formControlName="1.2.1"
        />
        Да
        <input
          ngModel
          name="1.2.1"
          value="Нет"
          type="radio"
          class="radio"
          formControlName="1.2.1"
        />
        Нет
      </div>
    </div>
    <label class="calc-radio-label" for="1.3">
      <div class="d-grid">
        <div>1.3.</div>
        <div>Злокачественное новообразование?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.3"
              type="radio"
              class="radio"
              formControlName="1.3"
            />
            Да
            <input
              ngModel
              name="1.3"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.3"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[1.3].value === 'Да'" class="optional">
      <label class="calc-radio-label" for="1.3.1">Если «Да», то какое?</label>
        <input
          ngModel
          type="text"
          formControlName="1.3.1"
          placeholder="Злокачественное новообразование..."
        />
    </div>
    <label class="calc-radio-label" for="1.4">
      <div class="d-grid">
        <div>1.4.</div>
        <div>Повышенный уровень холестерина?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.4"
              type="radio"
              class="radio"
              formControlName="1.4"
            />
            Да
            <input
              ngModel
              name="1.4"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.4"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[1.4].value === 'Да'" class="optional">
      <label class="calc-radio-label" for="1.4.1">Если «Да», то принимаете ли Вы препараты для снижения уровня холестерина?</label>
      <div class="radio-container">
      <input
        ngModel
        value="Да"
        name="1.4.1"
        type="radio"
        class="radio"
        formControlName="1.4.1"
      />
      Да
      <input
        ngModel
        name="1.4.1"
        value="Нет"
        type="radio"
        class="radio"
        formControlName="1.4.1"
      />
      Нет
      </div>
    </div>
    <label class="calc-radio-label" for="1.5">
      <div class="d-grid">
        <div>1.5.</div>
        <div>Перенесенный инфаркт миокарда?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.5"
              type="radio"
              class="radio"
              formControlName="1.5"
            />
            Да
            <input
              ngModel
              name="1.5"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.5"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="1.6">
      <div class="d-grid">
        <div>1.6.</div>
        <div>Перенесенный инсульт?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.6"
              type="radio"
              class="radio"
              formControlName="1.6"
            />
            Да
            <input
              ngModel
              name="1.6"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.6"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="1.7">
      <div class="d-grid">
        <div>1.7.</div>
        <div>Хронический бронхит или бронхиальная астма?
          <div class="radio-container">
            <input
              ngModel
              value="Да"
              name="1.7"
              type="radio"
              class="radio"
              formControlName="1.7"
            />
            Да
            <input
              ngModel
              name="1.7"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="1.7"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="2">
      <div class="d-grid">
        <div>2.</div>
        <div>Возникает ли у Вас, когда поднимаетесь по лестнице, идете в гору или
          спешите, или при выходе из теплого помещения на холодный воздух, боль или
          ощущение давления, жжения, тяжести или явного дискомфорта за грудиной и
          (или) в левой половине грудной клетки, и (или) в левом плече, и (или) в
          левой руке?
          <div class="radio-container">
            <input
              ngModel
              name="2"
              value="Да"
              type="radio"
              class="radio"
              formControlName="2"
            />
            Да
            <input
              ngModel
              name="2"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="2"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[2].value === 'Да'" class="optional">
      <label class="calc-radio-label" for="3">
        Если ответ «Да» по вопросу 2, то указанные боли/ощущения/дискомфорт
        исчезают сразу или через 5 - 10 мин после прекращения ходьбы/адаптации к
        холоду и (или) после приема нитроглицерина
      </label>
      <div class="radio-container">
        <input
        ngModel
        name="3"
        value="Да"
        type="radio"
        class="radio"
        formControlName="3"
        />
        Да
        <input
          ngModel
          name="3"
          value="Нет"
          type="radio"
          class="radio"
          formControlName="3"
        />
        Нет
      </div>
    </div>
    <label class="calc-radio-label" for="4">
      <div class="d-grid">
        <div>3.</div>
        <div>Возникала ли у Вас резкая слабость в одной руке и/или ноге так, что Вы не могли взять или удержать предмет, встать со стула, пройтись по комнате?
          <div class="radio-container">
            <input
              ngModel
              name="4"
              value="Да"
              type="radio"
              class="radio"
              formControlName="4"
            />
            Да
            <input
              ngModel
              name="4"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="4"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="5">
      <div class="d-grid">
        <div>4.</div>
        <div>Возникало ли у Вас внезапное без понятных причин кратковременное онемение в одной руке, ноге или половине лица, губы или языка?
          <div class="radio-container">
            <input
              ngModel
              name="5"
              value="Да"
              type="radio"
              class="radio"
              formControlName="5"
            />
            Да
            <input
              ngModel
              name="5"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="5"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="6">
      <div class="d-grid">
        <div>5.</div>
        <div>Возникала у Вас когда-либо внезапно кратковременная потеря зрения на один глаз?
          <div class="radio-container">
            <input
              ngModel
              name="6"
              value="Да"
              type="radio"
              class="radio"
              formControlName="6"
            />
            Да
            <input
              ngModel
              name="6"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="6"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="7">
      <div class="d-grid">
        <div>6.</div>
        <div>Бывают ли у Вас отеки на ногах к концу дня?
          <div class="radio-container">
            <input
              ngModel
              name="7"
              value="Да"
              type="radio"
              class="radio"
              formControlName="7"
            />
            Да
            <input
              ngModel
              name="7"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="7"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="8">
      <div class="d-grid">
        <div>7.</div>
        <div>Бывают ли у Вас ежегодно периоды ежедневного кашля с отделением мокроты на протяжении примерно 3-х месяцев в году?
          <div class="radio-container">
            <input
              ngModel
              name="8"
              value="Да"
              type="radio"
              class="radio"
              formControlName="8"
            />
            Да
            <input
              ngModel
              name="8"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="8"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="9">
      <div class="d-grid">
        <div>8.</div>
        <div>Бывают ли у Вас свистящие или жужжащие хрипы в грудной клетке при дыхании, не проходящие при откашливании?
          <div class="radio-container">
            <input
              ngModel
              name="9"
              value="Да"
              type="radio"
              class="radio"
              formControlName="9"
            />
            Да
            <input
              ngModel
              name="9"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="9"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="10">
      <div class="d-grid">
        <div>9.</div>
        <div>Бывало ли у Вас когда-либо кровохарканье?
          <div class="radio-container">
            <input
              ngModel
              name="10"
              value="Да"
              type="radio"
              class="radio"
              formControlName="10"
            />
            Да
            <input
              ngModel
              name="10"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="10"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="11">
      <div class="d-grid">
        <div>10.</div>
        <div>Беспокоят ли Вас боли в области верхней части живота (в области желудка), отрыжка, тошнота, рвота, ухудшение или отсутствие аппетита?
          <div class="radio-container">
            <input
              ngModel
              name="11"
              value="Да"
              type="radio"
              class="radio"
              formControlName="11"
            />
            Да
            <input
              ngModel
              name="11"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="11"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="12">
      <div class="d-grid">
        <div>11.</div>
        <div>Бывают ли у Вас кровяные выделения с калом?
          <div class="radio-container">
            <input
              ngModel
              name="12"
              value="Да"
              type="radio"
              class="radio"
              formControlName="12"
            />
            Да
            <input
              ngModel
              name="12"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="12"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="13">
      <div class="d-grid">
        <div>12.</div>
        <div>Курите ли Вы? (курение одной и более сигарет в день)
          <div class="radio-container">
            <input
              ngModel
              name="13"
              value="Да"
              type="radio"
              class="radio"
              formControlName="13"
            />
            Да
            <input
              ngModel
              name="13"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="13"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="14">
      <div class="d-grid">
        <div>13.</div>
        <div>Были ли у Вас переломы при падении с высоты своего роста, при ходьбе по ровной поверхности или перелом без видимой причины, в т.ч. перелом позвонка?
          <div class="radio-container">
            <input
              ngModel
              name="14"
              value="Да"
              type="radio"
              class="radio"
              formControlName="14"
            />
            Да
            <input
              ngModel
              name="14"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="14"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="15">
      <div class="d-grid">
        <div>14.</div>
        <div>Считаете ли Вы, что Ваш рост заметно снизился за последние годы?
          <div class="radio-container">
            <input
              ngModel
              name="15"
              value="Да"
              type="radio"
              class="radio"
              formControlName="15"
            />
            Да
            <input
              ngModel
              name="15"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="15"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="16">
      <div class="d-grid">
        <div>15.</div>
        <div>Присутствует ли в Вашем ежедневном рационе 2 и более порции фруктов или овощей? (1 порция фруктов = 200 г. 1 порция овощей, не считая картофеля = 200 г.)
          <div class="radio-container">
            <input
              ngModel
              name="16"
              value="Да"
              type="radio"
              class="radio"
              formControlName="16"
            />
            Да
            <input
              ngModel
              name="16"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="16"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="17">
      <div class="d-grid">
        <div>16.</div>
        <div>Употребляете ли Вы белковую пищу (мясо, рыбу, бобовые, молочные продукты) 3 раза или более в неделю?
          <div class="radio-container">
            <input
              ngModel
              name="17"
              value="Да"
              type="radio"
              class="radio"
              formControlName="17"
            />
            Да
            <input
              ngModel
              name="17"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="17"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="18">
      <div class="d-grid">
        <div>17.</div>
        <div>Тратите ли Вы ежедневно на ходьбу, утреннюю гимнастику и другие физические упражнения 30 минут и более?
          <div class="radio-container">
            <input
              ngModel
              name="18"
              value="Да"
              type="radio"
              class="radio"
              formControlName="18"
            />
            Да
            <input
              ngModel
              name="18"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="18"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="19">
      <div class="d-grid">
        <div>18.</div>
        <div>Были ли у Вас случаи падений за последний год?
          <div class="radio-container">
            <input
              ngModel
              name="19"
              value="Да"
              type="radio"
              class="radio"
              formControlName="19"
            />
            Да
          <input
              ngModel
              name="19"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="19"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="20">
      <div class="d-grid">
        <div>19.</div>
        <div>Испытываете ли Вы существенные ограничения в повседневной жизни из-за снижения зрения?
          <div class="radio-container">
            <input
              ngModel
              name="20"
              value="Да"
              type="radio"
              class="radio"
              formControlName="20"
            />
            Да
          <input
              ngModel
              name="20"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="20"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="21">
      <div class="d-grid">
        <div>20.</div>
        <div>Испытываете ли Вы существенные ограничения в повседневной жизни из-за снижения слуха?
          <div class="radio-container">
            <input
              ngModel
              name="21"
              value="Да"
              type="radio"
              class="radio"
              formControlName="21"
            />
            Да
          <input
              ngModel
              name="21"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="21"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="22">
      <div class="d-grid">
        <div>21.</div>
        <div>Чувствуете ли Вы себя подавленным, грустным или встревоженным в последнее время?
          <div class="radio-container">
            <input
              ngModel
              name="22"
              value="Да"
              type="radio"
              class="radio"
              formControlName="22"
            />
            Да
            <input
              ngModel
              name="22"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="22"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="23">
      <div class="d-grid">
        <div>22.</div>
        <div>Страдаете ли Вы недержанием мочи?
          <div class="radio-container">
            <input
              ngModel
              name="23"
              value="Да"
              type="radio"
              class="radio"
              formControlName="23"
            />
            Да
            <input
              ngModel
              name="23"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="23"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="24">
      <div class="d-grid">
        <div>23.</div>
        <div>Испытываете ли Вы затруднения при перемещении по дому, улице (ходьба на 100 м), подъем на 1 лестничный пролет?
          <div class="radio-container">
            <input
              ngModel
              name="24"
              value="Да"
              type="radio"
              class="radio"
              formControlName="24"
            />
            Да
            <input
              ngModel
              name="24"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="24"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="25">
      <div class="d-grid">
        <div>24.</div>
        <div>Есть ли у Вас проблемы с памятью, пониманием, ориентацией или способностью планировать?
          <div class="radio-container">
            <input
              ngModel
              name="25"
              value="Да"
              type="radio"
              class="radio"
              formControlName="25"
            />
            Да
            <input
              ngModel
              name="25"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="25"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="26">
      <div class="d-grid">
        <div>25.</div>
        <div>Считаете ли Вы, что заметно похудели за последнее время (не менее 5 кг за полгода)?
          <div class="radio-container">
            <input
              ngModel
              name="26"
              value="Да"
              type="radio"
              class="radio"
              formControlName="26"
            />
            Да
            <input
              ngModel
              name="26"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="26"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[26].value === 'Да'" class="optional">
      <label class="calc-radio-label" for="27">Если Вы похудели, считаете ли Вы, что это связано со специальным
        соблюдением диеты или увеличением физической активности?
      </label>
      <div class="radio-container">
        <input
        ngModel
        name="27"
        value="Да"
        type="radio"
        class="radio"
        formControlName="27"
        />
        Да
        <input
        ngModel
        name="27"
        value="Нет"
        type="radio"
        class="radio"
        formControlName="27"
        />
        Нет
      </div>
    </div>
    <div *ngIf="form.controls[26].value === 'Да'" class="optional">
      <label class="calc-radio-label" for="28">Если Вы похудели, считаете ли Вы, что это связано со снижением
        аппетита?
      </label>
      <div class="radio-container">
        <input
        ngModel
        name="28"
        value="Да"
        type="radio"
        class="radio"
        formControlName="28"
        />
        Да
        <input
        ngModel
        name="28"
        value="Нет"
        type="radio"
        class="radio"
        formControlName="28"
        />
        Нет
      </div>
    </div>
    <label class="calc-radio-label" for="29">
      <div class="d-grid">
        <div>26.</div>
        <div>Сколько лекарственных препаратов Вы принимаете ежедневно или несколько раз в неделю?
          <div class="radio-container">
            <input
              ngModel
              name="29"
              value="До 5"
              type="radio"
              class="radio"
              formControlName="29"
            />
            До 5
            <input
              ngModel
              name="29"
              value="5 и более"
              type="radio"
              class="radio"
              formControlName="29"
            />
            5 и более
          </div>
        </div>
      </div>
    </label>
    <label class="calc-radio-label" for="30">
      <div class="d-grid">
        <div>27.</div>
        <div>Есть ли у Вас другие жалобы на свое здоровье, не вошедшие в настоящую анкету и которые Вы бы хотели сообщить врачу (фельдшеру)?
          <div class="radio-container">
            <input
              ngModel
              name="30"
              value="Да"
              type="radio"
              class="radio"
              formControlName="30"
            />
            Да
            <input
              ngModel
              name="30"
              value="Нет"
              type="radio"
              class="radio"
              formControlName="30"
            />
            Нет
          </div>
        </div>
      </div>
    </label>
    <div *ngIf="form.controls[30].value === 'Да'" class="optional">
      <label class="calc-radio-label" for="31"> Опишите Ваши жалобы </label>
        <input
          ngModel
          type="text"
          formControlName="31"
          placeholder="Дополнительно..."
        />
    </div>
    <div class="btn-container">
      <button class="btn" type="submit" [disabled]="form.invalid" >
        Сохранить и отправить
      </button>
    </div>
  </div>
</form>
