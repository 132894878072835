import {Injectable} from '@angular/core';
import {Notification} from "@app/models/Notification";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    constructor(private http: HttpClient) {
    }

    getNotifications() {
        return this.http.get<Notification[]>(`${environment.api_endpoint}/api/notifications`, {
            params: {
                limit: 300,
                offset: 0
            }
        });
    }

    markAsReadRecord(id: number) {
        return this.http.post<any>(`${environment.api_endpoint}/api/notifications/${id}/status/read`, {});
    }

    markAsReadAll() {
        return this.http.post<any>(`${environment.api_endpoint}/api/notifications/status/read`, {});
    }
}

