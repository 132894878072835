import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {Document} from "@app/models/Document";
import {UserService} from "@app/services/user.service";
import {H} from "@app/helpers/helper";
import {StorageService} from "@app/services/storage.service";
import {SharedService} from "@app/services/shared.service";
import {environment} from '@environments/environment';
import {IDocList} from '@app/models/DocList';
import {Case} from "@app/models/Case";

@Component({
    selector: 'app-archive',
    templateUrl: './archive.component.html',
    styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {
    tabs: MenuItem[];
    cases: Case[];
    documents: Document[];
    openedDocument: Document | null;
    selectedCase: Case | null;
    isPdfViewerEnabled: boolean = false;
    pdfSrc: string;
    docsList: IDocList = {
        '0': {name: 'Заключения', isOpen: false, data: []},
        '1': {name: 'Анализы', isOpen: false, data: []},
        '2': {name: 'Исследования', isOpen: false, data: []}
    };

    constructor(private userService: UserService,
                private storage: StorageService,
                private sharedService: SharedService
    ) {
    }

    ngOnInit(): void {
        this.tabs = H.getMedkartaTabs();
        this.userService.getCases().subscribe({
            next: (cases) => {
                this.cases = cases;
            }
        });
        this.sharedService.sendBackButtonRoute('/my-data');
    }

    selectCase(caseData: Case) {
        this.selectedCase = caseData;
        this.userService.getCaseDocuments(caseData.id).subscribe({
            next: (docs) => {
                this.docsList['0'].data = docs;
            }
        })
        this.userService.getCaseDocuments(caseData.id, 1).subscribe({
            next: (docs) => {
                this.docsList['1'].data = docs;
            }
        })
        this.userService.getCaseDocuments(caseData.id, 2).subscribe({
            next: (docs) => {
                this.docsList['2'].data = docs;
            }
        })
    }

    gotoCases() {
        this.selectedCase = null;
        this.isPdfViewerEnabled = false;
        this.openedDocument = null;
    }

    gotoCaseDocuments() {
        this.isPdfViewerEnabled = false;
        this.openedDocument = null;
    }

    backToList() {
        this.isPdfViewerEnabled = false;
    }

    openCase(caseDoc: Case) {
    }

    getDateFormatted(inDate: string): string {
        return inDate && inDate.length > 0 ? H.getDateFormatted(inDate) : '';
    }

    openDocument(doc: Document) {
        this.isPdfViewerEnabled = true;
        this.pdfSrc = `${environment.api_endpoint}/api/documents/${doc.id}/file` + '?access_token=' + this.storage.getUser().token;
        this.openedDocument = doc;
    }

    getWidth(): string {
        return window.innerWidth <= 768 ? (window.innerWidth - 30) + 'px' : '100%';
    }

    getHeight(): number {
        return window.innerHeight - 180;
    }
  
    showDocsList(e: any) {
        this.docsList[e.target.id].isOpen = !this.docsList[e.target.id].isOpen;
    }

}
