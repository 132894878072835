import {MenuItem} from "primeng/api";

export class H {
    static months: { [key: number]: string } = {
        0: 'января',
        1: 'февраля',
        2: 'марта',
        3: 'апреля',
        4: 'мая',
        5: 'июня',
        6: 'июля',
        7: 'августа',
        8: 'сентября',
        9: 'октября',
        10: 'ноября',
        11: 'декабря',
    }

    static getDateFormatted(inDate: string, time: boolean = false): string {
        let d = new Date(inDate);
        let result = '';
        if (this.months.hasOwnProperty(d.getMonth())) {
            result = d.getDate() + ' ' + this.months[d.getMonth()] + ' ' + d.getFullYear();
        }
        if (time) {
            result += ' в ' + d.getHours() + ':' + ('0' + d.getMinutes()).slice(-2);
        }
        return result;
    }

    static getMedkartaTabs(): MenuItem[] {
        return [
            {label: 'Заключения', routerLink: ['/my-data/medkarta/conclusions'], routerLinkActiveOptions: {exact: true}},
            {label: 'Анализы', routerLink: ['/my-data/medkarta/results'], routerLinkActiveOptions: {exact: true}},
            {label: 'Исследования', routerLink: '/my-data/medkarta/researches', routerLinkActiveOptions: {exact: true}},
            {label: 'Архив', routerLink: '/my-data/medkarta/archive', routerLinkActiveOptions: {exact: true}},
        ];
    }

    static numTrans(number: number, cases: string[], withNumber: boolean = false): string {
        let showNumber = number + '';
        if (number > 99) {
            let tmp = number.toString();
            number = parseInt(tmp.substr(tmp.length - 2));
        }
        let phraseKey;
        if (number === 0) {
            phraseKey = 2;
        } else if (number === 1) {
            phraseKey = 0;
        } else if (number < 5) {
            phraseKey = 1;
        } else if (number < 21) {
            phraseKey = 2;
        } else {
            let check = parseInt(number.toString().substr(number.toString().length - 1));
            if (check === 0) {
                phraseKey = 2;
            } else if (check === 1) {
                phraseKey = 3;
            } else if (check < 5) {
                phraseKey = 1;
            } else {
                phraseKey = 2;
            }
        }
        if (!withNumber) {
            showNumber = '';
        } else {
            showNumber += ' ';
        }
        return showNumber + cases[phraseKey];
    }

    static calculateAge(birthday:any) {
        let ageDifMs = Date.now() - birthday;
        let ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    static arrayBufferToBase64(buffer:ArrayBuffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    static isMobile(): boolean {
        return window.innerWidth < 768;
    }

    static changeLimit(limit: number, step: number) {
        return limit += step;
    }
}