<div class="healthStatusContainer">
  <div class="healthStatusContainer-header">
    <div class="healthStatusContainer-header__textContainer">
        <div class="o-header">Управление индивидуальным<br>риском сердечно-сосудистых<br>заболеваний</div>
        <p class="o-header__subtitle">(Технология прошла клиническую апробацию)</p>
    </div>
    <button class="o-button" (click)="onClick($event)">Пройти обследование</button>
  </div>

  <div class="healthStatusContainer__title">Тесты</div>
  <div class="healthStatusContainer-calcWrapper">
    <div class="calc" *ngFor="let calc of calcs">
      <div class="calc-name__text">{{calc.name}}</div>
      <button [routerLink]="['/my-data/health', calc.id]" class="link-btn">Пройти тест</button>
      <img class="img" [src]="calc.image" alt={{calc.name}}/>
    </div>
  </div>

</div>