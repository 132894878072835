import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "@app/helpers/auth.guard";
import {HomeComponent} from "@app/components/home/home.component";
import {LoginComponent} from "@app/components/login/login.component";
import {MyDataComponent} from "@app/components/my-data/my-data.component";
import {MedkartaComponent} from "@app/components/my-data/medkarta/medkarta.component";
import {BookComponent} from "@app/components/book/book.component";
import {SpecialtyComponent} from "@app/components/book/specialty/specialty.component";
import {MessagesComponent} from "@app/components/messages/messages.component";
import {ResultsComponent} from "@app/components/my-data/medkarta/results/results.component";
import {ReferralsComponent} from "@app/components/referrals/referrals.component";
import {ResearchesComponent} from "@app/components/my-data/medkarta/researches/researches.component";
import {ProfileComponent} from "@app/components/my-data/profile/profile.component";
import {ContactsComponent} from "@app/components/contacts/contacts.component";
import {NotificationsComponent} from "@app/components/notifications/notifications.component";
import {BookingsComponent} from "@app/components/my-data/bookings/bookings.component";
import {ArchiveComponent} from "@app/components/my-data/medkarta/archive/archive.component";
import { PrivacyPolicyComponent } from '@app/components/privacy-policy/privacy-policy.component';
import {HealthStatusComponent} from '@app/components/health-status/health-status.component';
import { CalcComponent } from '@app/components/health-status/health-calculators/calc/calc.component';
import {ConclusionsComponent} from "@app/components/my-data/medkarta/conclusions/conclusions.component";
import { QuestioningComponent } from '@app/components/questioning/questioning.component';
import {QuestionnaireComponent} from '@app/components/questioning/questionnaires/questionnaire/questionnaire.component';

const routes: Routes = [
    {path: 'home', component: HomeComponent, canActivate: [AuthGuard], title: 'Главная'},
    {path: 'login', component: LoginComponent, title: 'Вход'},
    {path: 'book', component: BookComponent, canActivate: [AuthGuard], title: 'Запись на прием'},
    {path: 'book/specialty', component: SpecialtyComponent, canActivate: [AuthGuard], title: 'Запись на специализацию'},
    {path: 'messages', component: MessagesComponent, canActivate: [AuthGuard], title: 'Сообщения'},
    {path: 'referrals', component: ReferralsComponent, canActivate: [AuthGuard], title: 'Маршрутный лист'},
    {path: 'notifications', component: NotificationsComponent, title: 'Уведомления'},
    {path: 'contacts', component: ContactsComponent, title: 'Контакты'},
    {path: 'my-data', component: MyDataComponent, canActivate: [AuthGuard], title: 'Мои данные'},
    {path: 'my-data/profile', component: ProfileComponent, canActivate: [AuthGuard], title: 'Мой профиль'},
    {path: 'my-data/bookings', component: BookingsComponent, canActivate: [AuthGuard], title: 'Мои записи'},
    {path: 'my-data/health', component: HealthStatusComponent, canActivate: [AuthGuard], title: 'Состояние здоровья'},
    {path: 'my-data/questioning', component: QuestioningComponent, canActivate: [AuthGuard], title: 'Анкетирование'},
    {path: 'my-data/health/:id', component: CalcComponent},
    {path: 'my-data/questioning/:id', component: QuestionnaireComponent},
    {path: 'my-data/medkarta', component: MyDataComponent},
    {path: 'my-data/medkarta/conclusions', component: ConclusionsComponent, canActivate: [AuthGuard], title: 'Заключения'},
    {path: 'my-data/medkarta/conclusions/:caseId', component: ConclusionsComponent, canActivate: [AuthGuard], title: 'Заключения'},
    {path: 'my-data/medkarta/researches', component: ResearchesComponent, canActivate: [AuthGuard], title: 'Исследования'},
    {path: 'my-data/medkarta/researches/:caseId', component: ResearchesComponent, canActivate: [AuthGuard], title: 'Исследования'},
    {path: 'my-data/medkarta/results', component: ResultsComponent, canActivate: [AuthGuard], title: 'Анализы'},
    {path: 'my-data/medkarta/results/:caseId', component: ResultsComponent, canActivate: [AuthGuard], title: 'Анализы'},
    {path: 'my-data/medkarta/archive', component: ArchiveComponent, canActivate: [AuthGuard], title: 'Архив'},
    {path: 'privacy', component: PrivacyPolicyComponent},
    {path: '**', redirectTo: 'home'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
