<div class="contactsContainer">
  <div>
    <div class="contacts mb-4">
      <img
          class="c-logo pb-3"
          src="/assets/images/rzd_logo.png"
          alt="логотип"
      >
      <div class=" text-left contacts__title">{{getOrgName()}}</div>
      <div class=" text-left contacts__title2">{{getOrgDeptName()}}</div>
    </div>
    <div class="c-address-container">
      <p-autoComplete
          id="chooseContact"
          [dropdown]="true"
          [suggestions]="contacts"
          [dropdownMode]="'blank'"
          placeholder="Все клиники"
          [(ngModel)]="selectedContact"
          (onSelect)="contactSelected($event)"
          [ngClass]="{'selected': selectedContact}"
          (completeMethod)="searchContact($event)"
      >
      </p-autoComplete>
      <div>
        <div class="c-title">Адрес и контакты:</div>
        <div class="mt-1 c-info-container" *ngIf="contact">
          <div class="c-stations">
            <div *ngFor="let stop of contact?.contactTransportStops">
              <div class="stop_container mt-2">
                <img class="stop_icon" src="{{stop.imageUrl}}" width="120" alt="">
                <div>
                  <div>{{stop.name}}</div>
                  <div class="font-normal text-sm minutes">{{stop.walkTime}} мин. пешком</div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-schedule">
            <div class="mb-1 font-bold">Контактный центр</div>
            <div class="mb-2"><a href="tel:88007000701" style="text-decoration: none; color: #232323" class="font-normal text-base" >{{contact.phoneReception}}</a></div>
            <div *ngFor="let dayOfWeek of contact?.contactSchedules">
              <div *ngIf="dayOfWeek.type.toLowerCase() === 'default'; else elseBlock">
                <span class="font-semibold" style="font-size: 14px">{{dayOfWeek.dayOfWeek | i18nSelect: dayOfWeekMap}}:&nbsp;</span>
                <span class="font-normal" style="font-size: 14px">{{dayOfWeek.startTime}} &#8211; {{dayOfWeek.endTime}}</span>
              </div>
              <div class="mb-1">
                <ng-template #elseBlock>
                  <span class="font-semibold " style="font-size: 14px">{{dayOfWeek.dayOfWeek | i18nSelect: dayOfWeekMap}}:&nbsp;</span>
                  <span class="font-normal" style="font-size: 14px">{{dayOfWeek.type | i18nSelect: dayTypeMap}}&nbsp;</span>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div #yamaps id="map"></div>
</div>