import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@app/models/User';
import { StorageService } from '@app/services/storage.service';

@Component({
  selector: 'app-water',
  templateUrl: './water.component.html',
  styleUrls: ['../calcStyle.component.scss'],
})
export class WaterComponent implements OnInit {
 
  constructor(private storage: StorageService) {}
  result: number = 0
  form: FormGroup
  user: User;

  ngOnInit() {
    this.user = this.storage.getUser();
    this.form = new FormGroup({
      gender: new FormControl(this.user.gender.toLowerCase()),
      weight: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*[.,]?[0-9]+$/)]),
      height: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*[.,]?[0-9]+$/)])
    })
  }
  
  setValue(g: string) {
    return g.toLowerCase() === 'женский' ? 'мужской' : 'женский' 
  }

  // Заполнить поле идеальная (рекомендуемая) масса тела (кг) - если есть данные предыдущего теста - подставить значение и произвести расчет автоматически
  // Произвести расчет по формуле
  // для мужчин: 35 x вес (кг)
  // для женщин: 31 x вес (кг)

  submit() {
    // if (this.form.valid && 'есть bmi') {
    //   const formData = {...this.form.value}
    //   if (formData.gender.toLowerCase() === 'женский' ) {
    //     this.result = (31 * formData.weight)
    //   } else {
    //     this.result = (35 * formData.weight)
    //   }
    // }
    if (this.form.valid) {
      const formData = {...this.form.value}
      if (formData.gender.toLowerCase() === 'женский' ) {
        this.result = (45.5 + 2.3 * (0.394 * (formData.height.replace(/,/, '.')) - 60)) * 31
      } else {
        this.result = (50 + 2.3 * (0.394 * (formData.height.replace(/,/, '.')) - 60)) * 35
      }
    }
    return this.result
  }
}

