<div class="login-wrapper">
    <div class="login-form">
        <form #form [formGroup]="loginForm">
            <div>
                <div *ngIf="step == 'phone'">
                    <div class="form-group">
                        <div class="login-form-header pa-py-5">
                            <div class="transform-header">
                                Вход в личный кабинет ВЭК
                            </div>
                        </div>

                        <span class="p-float-label">
                            <p-inputMask
                                class="inputWide"
                                [disabled]="loading"
                                id="float-input-phone"
                                formControlName="phone"
                                mask="+7 (999) 999-9999"
                                (onInput)="onPhoneInput($event)"
                                [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                            ></p-inputMask>
                            <span class="icon pi" [ngClass]="{ 'pi-spin': loading, 'pi-sync': loading, 'mt-2': loading }">
                                <ng-container *ngIf="!loading">
                                    <div *ngIf="phoneButtonEnabled; else showAcceptDefault">
                                        <img alt="" src="../../../assets/images/Accept.png" class="pr-2"/>
                                    </div>
                                    <ng-template #showAcceptDefault>
                                        <img alt="" src="../../../assets/images/AcceptDefault.png" class="pr-2"/>
                                    </ng-template>
                                </ng-container>
                            </span>
                            <label for="float-input-phone">Телефон</label>
                        </span>
                        <div class="mt-3">
                            <div *ngIf="f.phone.errors" class="help-block text-red-600 is-size-7">
                                <div class="f-error" *ngIf="f.phone.errors.server_error">
                                    {{ f.phone.errors.server_error }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pa-mt-5 text-center">
                        <button
                            type="button"
                            class="login-button"
                            (click)="onSubmit()"
                            [disabled]="!phoneButtonEnabled"
                        >Продолжить</button>                
                    </div>
                </div>
                <div *ngIf="step == 'sms'">
                    <div class="form-group">
                        <div class="login-form-header pa-py-5">
                            Мы отправили СМС с кодом <br /> на номер {{ f.phone.value }}
                        </div>
                        <span class="p-float-label">
                            <input type="tel"
                                pInputText
                                maxlength="{{smsLength}}"
                                minlength="{{smsLength}}"
                                formControlName="sms"
                                id="float-input-sms"
                                class="inputWide"
                                (paste)="onPasteSms($event)"
                                (keydown)="onSmsInput($event)"
                                (input)="onSmsInput($event)"
                                [ngClass]="{ 'is-invalid': submitted && f.sms.errors }"
                                />
                            <span class="icon pi" [ngClass]="{ 'pi-spin': loading, 'pi-sync': loading, 'mt-2': loading }">
                                <ng-container *ngIf="!loading">
                                    <div *ngIf="smsButtonEnabled; else showSmsAcceptDefault">
                                        <img alt="" src="../../../assets/images/Accept.png" class="pr-2"/>
                                    </div>
                                    <ng-template #showSmsAcceptDefault>
                                        <img alt="" src="../../../assets/images/AcceptDefault.png" class="pr-2"/>
                                    </ng-template>
                                </ng-container>
                            </span>
                            <label for="float-input-sms">Код из СМС:</label>
                            <div class="is-hidden"><button #smsSendButton>Далее</button></div>
                        </span>
                        <div class="mt-3">
                            <div *ngIf="f.sms.errors" class="help-block text-red-600 is-size-7">
                                <div class="f-error" *ngIf="f.sms.errors.server_error">
                                    {{ f.sms.errors.server_error }}
                                </div>
                            </div>
                        </div>
                        <div class="mt-1">
                            <a class="backLink" (click)="backToPhone()">&larr;&nbsp;Назад</a>
                        </div>
                    </div>
                    <div class="mt-3 text-center">
                        <button
                            type="button"
                            class="login-button"
                            (click)="onSubmit()"
                            [disabled]="!smsButtonEnabled"
                        >Войти</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>