<div class="bookContainer">
<div class="bookTitle">Новая запись</div>
<div class="book-cards-container">

    <div class="book-card error" routerLink="/book/clinic">
        <img alt="conclusions" src="../../../assets/images/book/tag.svg"/>
        <div class="book-card-title">В клинику</div>
        <div class="book-card-subtitle">Начинайте запись с выбора клиники</div>
    </div>

    <div class="book-card error" routerLink="/book/doctor">
        <img alt="results" src="../../../assets/images/book/doctor.svg"/>
        <div class="book-card-title">К врачу</div>
        <div class="book-card-subtitle">Начинайте запись с выбора врача</div>
    </div>

    <div class="book-card" routerLink="/book/specialty">
        <img alt="researches" src="../../../assets/images/book/file.svg"/>
        <div class="book-card-title">На специализацию</div>
        <div class="book-card-subtitle">Начинайте запись с выбора специализации</div>
    </div>

</div>
</div>







<!--<div class="b-book mt-3 mb-5" *ngIf="allProfiles.length > 0">-->
<!--    <span class="p-fluid">-->
<!--        <label for="chooseSpec">Выберите специализацию:</label>-->
<!--        <p-autoComplete-->
<!--            id="chooseSpec"-->
<!--            [(ngModel)]="selectedProfile"-->
<!--            [suggestions]="profiles"-->
<!--            (completeMethod)="searchProfile($event)"-->
<!--            [dropdown]="true"-->
<!--            (onSelect)="profileSelected($event)">-->
<!--        </p-autoComplete>-->
<!--    </span>-->

<!--    <div class="mt-4" *ngIf="allProfileResources.length > 0">-->
<!--        <label for="chooseDoctor">Выберите врача:</label>-->
<!--        <span class="p-fluid">-->
<!--            <p-autoComplete-->
<!--                id="chooseDoctor"-->
<!--                [(ngModel)]="selectedResource"-->
<!--                [suggestions]="profileResources"-->
<!--                (completeMethod)="searchProfileResource($event)"-->
<!--                [dropdown]="true"-->
<!--                (onSelect)="profileResourceSelected($event)">-->
<!--            </p-autoComplete>-->
<!--        </span>-->
<!--    </div>-->

<!--    <div-->
<!--        class="mt-5 text-xs text-center"-->
<!--        *ngIf="isProfileResourcesLoaded && !allProfileResources.length">-->
<!--        Нет врачей по выбранному направлению.-->
<!--    </div>-->

<!--    <div class="mt-4" *ngIf="isResourceScheduleLoaded && resourceSchedule.length > 0">-->
<!--        <label for="chooseDate">Выберите дату и время посещения:</label>-->
<!--        <span class="p-fluid">-->
<!--            <p-calendar-->
<!--                id="chooseDate"-->
<!--                (onSelect)="selectDate($event)"-->
<!--                [(ngModel)]="selectedScheduleDate"-->
<!--                [inline]="true"-->
<!--                [showTime]="false"-->
<!--                [showOtherMonths]="false"-->
<!--                [firstDayOfWeek]="1"-->
<!--                [minDate]="scheduleDates[0]"-->
<!--                [maxDate]="scheduleDates[scheduleDates.length - 1]"-->
<!--                [hourFormat]="'24'">-->
<!--            </p-calendar>-->
<!--        </span>-->

<!--        <div class="b-timeUnites mt-4 mb-4" *ngIf="scheduleDateTimeUnits.length > 0">-->
<!--            <div *ngFor="let unit of scheduleDateTimeUnits">-->
<!--                <button-->
<!--                    (click)="confirm(unit)"-->
<!--                    pButton-->
<!--                    pRipple-->
<!--                    type="button"-->
<!--                    [label]="getTime(unit)"-->
<!--                    class="p-button-raised p-button-text p-button-plain"-->
<!--                ></button>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="mt-5 text-center text-xs" *ngIf="isScheduleDateSelected && !scheduleDateTimeUnits.length">-->
<!--            На выбранную дату нет приема.-->
<!--        </div>-->

<!--    </div>-->
<!--</div>-->