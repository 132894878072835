<p-dialog
    styleClass="p-dialog-modal"
    (onHide)="onHideModalDialog()"
    [showHeader]="false"
    [(visible)]="isShowModalDialog"
    [modal]="true">
    <div class="dialog-icon">
        <div class="circle">
            <i class="pi pi-check align-middle text-white fs-5"></i>
        </div>
    </div>
    <div class="pa-mt-5" style="text-align: end;">
        <button type="button" style="background: transparent; border: 0 none;" (click)="hideModalDialog()">
            <i class="pi pi-times"></i>
        </button>
    </div>
    <div [innerHTML]="dialogHtmlMessage">
    </div>
</p-dialog>