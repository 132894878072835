<div class="specialtyContainer">
  <div class="specialtyContainer-header">
    <div class="specialty-header-title">Новая запись</div>
    <div class="specialty-header-steps">
      <div class="header-step" [ngClass]="{'activeStep': !allProfileResources.length}">Специализация</div>
      <div class="header-step" [ngClass]="{'activeStep': allProfileResources.length > 0 && !resourceSchedule.length}">Врач</div>
<!--      <div class="header-step" [ngClass]="{'activeStep': selectedProfile}">Клиника</div>-->
      <div class="header-step" [ngClass]="{'activeStep': isResourceScheduleLoaded && resourceSchedule.length > 0}">Дата и время</div>
    </div>
  </div>

  <div class="specialty-input-container">

      <div
        class="specialty-autocomplete-wrapper"
        *ngIf="allProfiles.length > 0"
      >
        <span class="p-fluid">
          <label *ngIf="!selectedProfile" for="chooseSpec" class="specialty-autocomplete-label">Выберите специализацию:</label>
          <label *ngIf="selectedProfile.length > 0" for="chooseSpec" class="specialty-autocomplete-label">Выбранная вами специализация</label>
          <p-autoComplete
            id="chooseSpec"
            [dropdown]="true"
            [suggestions]="profiles"
            [dropdownMode]="'blank'"
            placeholder="Название специализации"
            [(ngModel)]="selectedProfile"
            (onSelect)="profileSelected($event)"
            [ngClass]="{'selected': selectedProfile}"
            (completeMethod)="searchProfile($event)"
          >
          </p-autoComplete>
        </span>
      </div>


      <div
        class="specialty-autocomplete-wrapper"
        *ngIf="allProfileResources.length > 0"
      >
        <span class="p-fluid">
          <label *ngIf="!selectedResource"  for="chooseDoctor" class="specialty-autocomplete-label">Поиск по фамилии врача:</label>
          <label *ngIf="selectedResource.length > 0" for="chooseDoctor" class="specialty-autocomplete-label">Выбранный вами врач</label>
          <p-autoComplete id="chooseDoctor"
            [(ngModel)]="selectedResource"
            [suggestions]="profileResources"
            (completeMethod)="searchProfileResource($event)"
            [dropdown]="true"
            (onSelect)="profileResourceDatesSelected($event)">
          </p-autoComplete>
        </span>

        <div
          class="mt-5 text-xs text-center"
          *ngIf="isProfileResourcesLoaded && !allProfileResources.length"
        >
          Нет врачей по выбранному направлению.
        </div>
      </div>


      <!-- Выбор клиники при записи еще не реализован    -->
      <!--<div class="clinic-doctor-input-wrapper">-->
      <!--      <div class="specialty-autocomplete-wrapper">-->
      <!--        <span class="p-fluid">-->
      <!--          <label for="chooseSpec" class="specialty-autocomplete-label">Выберите специализацию:</label>-->
      <!--          <label for="chooseSpec" class="specialty-autocomplete-label">Выбранная вами специализация</label>-->
      <!--          <p-autoComplete id="chooseSpec" [(ngModel)]="selectedProfile"-->
      <!--                          [suggestions]="profiles"-->
      <!--                          (completeMethod)="searchProfile($event)"-->
      <!--                          [dropdown]="true"-->
      <!--                          (onSelect)="profileSelected($event)">-->
      <!--              &lt;!&ndash;        <ng-template pTemplate="header">&ndash;&gt;-->
      <!--              &lt;!&ndash;          <div *ngIf="selectedContact" class="selectedContact">&ndash;&gt;-->
      <!--              &lt;!&ndash;            {{ selectedContact }}&ndash;&gt;-->
      <!--              &lt;!&ndash;          </div>&ndash;&gt;-->
      <!--              &lt;!&ndash;        </ng-template>&ndash;&gt;-->
      <!--          </p-autoComplete>-->
      <!--        </span>-->
      <!--      </div>-->
      <!--    <div #yamaps id="map"></div>-->
      <!--</div>-->
  </div>

  <div class="specialty-input-container">
  <div
    class="specialty-datepicker-wrapper"
    *ngIf="isResourceScheduleDatesLoaded && resourceScheduleDates.length > 0"
  >
    <label for="chooseDate" class="specialty-autocomplete-label">Дата и время:</label>
    <div class="specialty-calendar">
      <span class="p-fluid">
        <p-calendar
          id="chooseDate"
          (onSelect)="selectDate($event)"
          [(ngModel)]="selectedScheduleDate"
          [inline]="true"
          [showTime]="false"
          [showOtherMonths]="false"
          [firstDayOfWeek]="1"
          [minDate]="scheduleDates[0]"
          [maxDate]="scheduleDates[scheduleDates.length - 1]"
          [disabledDates]="disabledDates"
          [hourFormat]="'24'">
        </p-calendar>
      </span>
    </div>
  </div>

  <div
      style="width: 100%"
    *ngIf="isResourceScheduleLoaded && resourceSchedule.length > 0"
  >


    <div
      class="b-timeUnites mb-4"
        *ngIf="scheduleDateTimeUnits.length > 0"
    >
      <div *ngFor="let unit of scheduleDateTimeUnits">
        <button
          (click)="setSelectedUnit(unit)"
          pButton
          pRipple
          type="button"
          [label]="getTime(unit)"
          class="p-button-raised p-button-text font-bold"
          [ngClass]="{'selectedUnit': unit === selectedUnit}"
        ></button>
      </div>


      <div
        class="mt-5 text-center text-xs"
        *ngIf="isScheduleDateSelected && !scheduleDateTimeUnits.length"
      >
        На выбранную дату нет приема.
      </div>
    </div>
    <button
      *ngIf="isScheduleDateSelected && scheduleDateTimeUnits.length"
      (click)="confirm()"
      [ngClass]="{'disabled': !selectedUnit}"
      class="o-button"
    >Записаться</button>
  </div>
    </div>




<!--  <div class="b-book mt-3 mb-5" *ngIf="allProfiles.length > 0">-->
<!--    <span class="p-fluid">-->
<!--        <label for="chooseSpec">Выберите специализацию:</label>-->
<!--        <p-autoComplete-->
<!--            id="chooseSpec"-->
<!--            [(ngModel)]="selectedProfile"-->
<!--            [suggestions]="profiles"-->
<!--            (completeMethod)="searchProfile($event)"-->
<!--            [dropdown]="true"-->
<!--            (onSelect)="profileSelected($event)">-->
<!--        </p-autoComplete>-->
<!--    </span>-->

<!--    <div class="mt-4" *ngIf="allProfileResources.length > 0">-->
<!--      <label for="chooseDoctor">Выберите врача:</label>-->
<!--      <span class="p-fluid">-->
<!--            <p-autoComplete-->
<!--                id="chooseDoctor"-->
<!--                [(ngModel)]="selectedResource"-->
<!--                [suggestions]="profileResources"-->
<!--                (completeMethod)="searchProfileResource($event)"-->
<!--                [dropdown]="true"-->
<!--                (onSelect)="profileResourceSelected($event)">-->
<!--            </p-autoComplete>-->
<!--        </span>-->
<!--    </div>-->

<!--    <div-->
<!--        class="mt-5 text-xs text-center"-->
<!--        *ngIf="isProfileResourcesLoaded && !allProfileResources.length">-->
<!--      Нет врачей по выбранному направлению.-->
<!--    </div>-->

<!--    <div class="mt-4" *ngIf="isResourceScheduleLoaded && resourceSchedule.length > 0">-->
<!--      <label for="chooseDate">Выберите дату и время посещения:</label>-->
<!--      <span class="p-fluid">-->
<!--            <p-calendar-->
<!--                id="chooseDate"-->
<!--                (onSelect)="selectDate($event)"-->
<!--                [(ngModel)]="selectedScheduleDate"-->
<!--                [inline]="true"-->
<!--                [showTime]="false"-->
<!--                [showOtherMonths]="false"-->
<!--                [firstDayOfWeek]="1"-->
<!--                [minDate]="scheduleDates[0]"-->
<!--                [maxDate]="scheduleDates[scheduleDates.length - 1]"-->
<!--                [hourFormat]="'24'">-->
<!--            </p-calendar>-->
<!--        </span>-->

<!--      <div class="b-timeUnites mt-4 mb-4" *ngIf="scheduleDateTimeUnits.length > 0">-->
<!--        <div *ngFor="let unit of scheduleDateTimeUnits">-->
<!--          <button-->
<!--              (click)="confirm(unit)"-->
<!--              pButton-->
<!--              pRipple-->
<!--              type="button"-->
<!--              [label]="getTime(unit)"-->
<!--              class="p-button-raised p-button-text p-button-plain"-->
<!--          ></button>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="mt-5 text-center text-xs" *ngIf="isScheduleDateSelected && !scheduleDateTimeUnits.length">-->
<!--        На выбранную дату нет приема.-->
<!--      </div>-->

<!--    </div>-->
<!--  </div>-->

</div>



<p-confirmDialog header="Подтверждение записи" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<app-dialogs></app-dialogs>