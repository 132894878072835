import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export interface Questionnaire {
  id: number,
  title: string,
  name?: string,
  name_after_65?: string,
  name_before_65?: string,
}

@Injectable({providedIn: 'root'})

export class QuestionnairesService{
    constructor(private http: HttpClient) {}
  
    questionnaires: Questionnaire[] = [
        {
            id: 0,
            title: 'Выявление хронических неинфекционных заболеваний и факторов риска их развития',
            name_before_65: 'Анкета для работников ОАО «РЖД» в возрасте до 65 лет на выявление хронических неинфекционных заболеваний и факторов риска их развития',
            name_after_65: 'Анкета для граждан в возрасте 65 лет и старше на выявление хронических неинфекционных заболеваний и факторов риска',
        },
        {
            id: 1,
            title: 'Выявление постковидного COVID-19 синдрома',
            name: 'Выявление постковидного COVID-19 синдрома (последствий перенесенной новой коронавирусной инфекции)',
        },
    ];
    
    getQuestionnaire(): Questionnaire[] {
        return this.questionnaires;
    }

    getById(id: number) {
        return this.questionnaires.find(questionnaire => questionnaire.id === id)
    }

    submit(data: any) {
        return this.http.post<any>('/test', data)
    }
    
}