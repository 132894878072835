import { Component, Input } from '@angular/core';
import { UserService } from '@app/services/user.service';
import { Diagram } from '@app/models/Diagram';
import { Referral } from '@app/models/Referral';
import { Constants } from '@app/helpers/constants';

@Component({
  selector: 'app-diagram',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.scss']
})
export class DiagramComponent {
  diagram: Diagram;
  @Input() isShowDiagramDialog: boolean = false;
  @Input() isHomePage: boolean = false;
  analysisUnfinished: Referral[] = [];

  isNavigateRoute: boolean = false;
  @Input() navigateRoute: string = ''

  constructor(
    private userService: UserService) {
  }

  ngOnInit(): void {
    if (this.isHomePage) {
      this.getDataForShowingDiargam();
    }
  }

  getDataForShowingDiargam() {
    this.userService.getDiagram().subscribe({
      next: (diagram) => {
        this.diagram = diagram;

        if (this.diagram.analysisCount > 0) {
          this.showDiagramDialog();
        }
      }
    });
  }

  showDiagramDialog() {
    this.userService.getReferrals(Constants.REFERRAL_TYPE_REFERRAL).subscribe({
      next: (referrals) => {
        this.analysisUnfinished = referrals.filter((el: Referral) => !el.conclusionDate);
        
        if (this.analysisUnfinished.length > 0) {
          this.isShowDiagramDialog = true;
        }
      }
    });
  }

  onShowDiagramDialog() {
    this.setProgressPosition();
  }

  hideDiagramDialog() {
    this.isShowDiagramDialog = false;
  }

  setProgressPosition() {
    let analysisCount = this.diagram.analysisCount;
    let completedCount = this.diagram.completedCount;

    let dashOffset: number = 270;

    let diagramStroke = (document.getElementById("diagramStroke") as HTMLElement);
    let progressPosition: number = dashOffset -
                                   Math.ceil((dashOffset / analysisCount) * completedCount);
    diagramStroke["style"].strokeDashoffset = progressPosition.toString();

    let separatorStroke = (document.getElementById("separatorStroke") as HTMLElement);
    let strokeDasharray: number = (270 - analysisCount) / analysisCount;
    
    separatorStroke["style"].strokeDasharray = "1 " + strokeDasharray.toString();
  }
}
