<div *ngIf="documents && documents.length>0">
    <div *ngFor="let doc of documents" class="b-documentList__item" >
        <div class="b-documentList__item__card">
            <div>
                <div class="b-documentList__item__date">{{getDateFormatted(doc.createdDate)}}</div>
                <div class="b-documentList__item__name">{{doc.name}}</div>
                <div class="b-documentList__item__doctor">{{doc.doctorName}}</div>
            </div>
            <div class="b-documentList__item__icon">
                <div class="b-documentList__item__icon__case" *ngIf="selectedCase">Посещение &laquo;{{selectedCase.caseNumber}}&raquo;</div>              
                <img class="small-img" src="../../../assets/images/eyeIcon.png" alt="eye icon" (click)="openDocument(doc)"/>
                <img class="small-img" src="../../../assets/images/downloadIcon.png" alt="download icon" (click)="downloadDocument(doc)">
            </div>
        </div>
    </div>
</div>
