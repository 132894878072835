import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {environment} from '@environments/environment';
import {User} from '@app/models/User';
import {StorageService} from "@app/services/storage.service";

@Injectable({providedIn: 'root'})
export class AuthService {
    private user: BehaviorSubject<User>;

    constructor(private http: HttpClient, private storage: StorageService) {
        this.user = new BehaviorSubject<User>(this.storage.getUser());
    }

    public get currentUserValue(): User {
        return this.user.value;
    }

    login(phone: string) {
        return this.http.post<any>(`${environment.api_endpoint}/api/auth/login?phone=` + phone, {});
    }

    token(phone: string, code: string) {
        return this.http.post<User>(`${environment.api_endpoint}/api/auth/login/token`, {
            "phoneNumber": phone,
            "code": code
        }).pipe(
            tap((user) => {
                this.storage.saveUser(user);
                this.user.next(user);
            })
        )
    }

    public isLoggedIn() {
        return this.storage.isLoggedIn();
    }

    logout() {
        this.storage.clean();
        // @ts-ignore
        this.user.next(null);
    }
}
