import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { QuestionnairesService } from '@app/components/questioning/questioning.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app/services/alert.service';

@Component({
  selector: 'app-ncds-elderly',
  templateUrl: './ncds-elderly.component.html',
  styleUrls: ['../../q_style.component.scss'],
})
export class NcdsElderlyComponent {
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(
    private router: Router,
    public alertService: AlertService,
    private questionnairesService: QuestionnairesService,
  ){}

  form: FormGroup
  error: any
  
  ngOnInit() {
    this.form = new FormGroup({
      '1.1': new FormControl('', Validators.required),
      '1.1.1': new FormControl(''),
      '1.2': new FormControl('', Validators.required),
      '1.2.1': new FormControl(''),
      '1.3': new FormControl('', Validators.required),
      '1.3.1': new FormControl(''),
      '1.4': new FormControl('', Validators.required),
      '1.4.1': new FormControl(''),
      '1.5': new FormControl('', Validators.required),
      '1.6': new FormControl('', Validators.required),
      '1.7': new FormControl('', Validators.required),
      '2': new FormControl('', Validators.required),
      '3': new FormControl(''),
      '4': new FormControl('', Validators.required),
      '5': new FormControl('', Validators.required),
      '6': new FormControl('', Validators.required),
      '7': new FormControl('', Validators.required),
      '8': new FormControl('', Validators.required),
      '9': new FormControl('', Validators.required),
      '10': new FormControl('', Validators.required),
      '11': new FormControl('', Validators.required),
      '12': new FormControl('', Validators.required),
      '13': new FormControl('', Validators.required),
      '14': new FormControl('', Validators.required),
      '15': new FormControl('', Validators.required),
      '16': new FormControl('', Validators.required),
      '17': new FormControl('', Validators.required),
      '18': new FormControl('', Validators.required),
      '19': new FormControl('', Validators.required),
      '20': new FormControl('', Validators.required),
      '21': new FormControl('', Validators.required),
      '22': new FormControl('', Validators.required),
      '23': new FormControl('', Validators.required),
      '24': new FormControl('', Validators.required),
      '25': new FormControl('', Validators.required),
      '26': new FormControl('', Validators.required),
      '27': new FormControl(''),
      '28': new FormControl(''),
      '29': new FormControl('', Validators.required),
      '30': new FormControl('', Validators.required),
      '31': new FormControl(''),
    })
  }

  
  submit(data: any) {
    this.questionnairesService.submit(data).subscribe({
      next: (resp) => {
          this.router.navigate(['/my-data/questioning']);
          this.alertService.success('Анкета успешно отправлена', this.options)
      },
      error: (resp) => {
       resp.status === 404 
       ? this.alertService.error('Ошибка запроса', this.options)
       : this.alertService.error('Запрос не может быть обработан. Повторите позднее', this.options)
      }
    })
  }

}


  