import {Component, OnInit} from '@angular/core';
import {User} from "@app/models/User";
import {StorageService} from "@app/services/storage.service";
import {SharedService} from "@app/services/shared.service";
import {H} from "@app/helpers/helper";
import {UserService} from "@app/services/user.service";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    user: User;
    lastCaseNumber: string;

    constructor(
        private storage: StorageService,
        private sharedService: SharedService,
        private userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.user = this.storage.getUser();
        this.userService.getCases().subscribe({
            next: (cases) => {
                this.lastCaseNumber = cases.sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate))[cases.length - 1].caseNumber;
            }
        });
        this.sharedService.sendBackButtonRoute('/my-data');
    }

    getDateFormatted(s: string): string {
        return H.getDateFormatted(s);
    }

    getAge(s: string): number {
        return H.calculateAge(new Date(s))
        // return H.numTrans(age, ['год', 'года', 'лет', 'год'], true);
    }

    getShortItemName(s: string) {
        return s.length > 30 ? s.substring(0, 30) + '...' : s
    }

    isMobile(): boolean {
        return window.innerWidth < 768;
    }

    getLastCase() {
        return this.lastCaseNumber;
    }
}
