
<div class="resultsContainer" *ngIf="!isPdfViewerEnabled">
    <button class="ref-back-btn" routerLink="/my-data" [queryParams]="{ tab: 'Медицинская карта' }">
        <img alt="logo" src="../../../../assets/images/ArrowBack.png"/>
        <span class="pl-3 uppercase p-button-label">Назад</span>
    </button>
    <div class="resultsContainer-title">Анализы</div>
    <app-document-list [documents]="documents" (openDocumentEvent)="openDocument($event)"></app-document-list>
    <div *ngIf="!documents || !documents.length" class="mt-5 text-center text-xs">У вас нет подписанных анализов.</div>
</div>
<div class="b-documentPdfViewer resultsContainer" *ngIf="isPdfViewerEnabled">
    <div class="grid mb-2 mt-0">
        <div class="col text-sm">
            <button class="ref-back-btn" (click)="backToList()">
                <img alt="logo" src="../../../../assets/images/ArrowBack.png"/>
                <span class="pl-3 p-button-label">К документам</span>
            </button>
        </div>
<!--        <div class="col text-right">-->
<!--            <a [href]="pdfSrc" target="_blank" pButton type="button" label="Скачать" icon="pi pi-download" class=" p-button-sm"></a>-->
<!--        </div>-->
    </div>
    <pdf-viewer [src]="pdfSrc"
                [render-text]="true"
                [original-size]="false"
                [autoresize]="true"
                class="resultsContainer-pdfViewer"
    ></pdf-viewer>
</div>
