import {Component, HostListener, OnInit} from '@angular/core';
import {UserService} from "@app/services/user.service";
import {H} from "@app/helpers/helper";
import {Referral} from "@app/models/Referral";
import {ReferralInfo} from "@app/models/ReferralInfo";
import {StorageService} from "@app/services/storage.service";
import {User} from "@app/models/User";
import {ReferralAssignee} from "@app/models/ReferralAssignee";
import {Constants} from "@app/helpers/constants";
import {Case} from "@app/models/Case";

@Component({
    selector: 'app-referrals',
    templateUrl: './referrals.component.html',
    styleUrls: ['./referrals.component.scss']
})
export class ReferralsComponent implements OnInit {
    activeTabIndex: number = 0;

    documents: Referral[] = [];
    documentsUnfinished: Referral[] = [];
    documentsFinished: Referral[] = [];
    documentsRecommends: Referral[] = [];

    limitUnfinished: number = 0;
    limitFinished: number = 0;
    limitRecommends: number = 0;
    offsetUnfinished: number = 0;
    offsetFinished: number = 0;
    offsetRecommends: number = 0;
    limitStep: number = 0;

    isViewRefInfo: boolean = false;
    refInfo: ReferralInfo;
    user: User;
    case: Case;

    barcodeUrl: string = '';

    constructor(
        private userService: UserService,
        private storageService: StorageService) {
    }

    ngOnInit(): void {
        this.user = this.storageService.getUser();

        this.limitStep = H.isMobile() ? 3 : 300;
        this.limitUnfinished = this.limitStep;
        this.limitFinished = this.limitStep;
        this.limitRecommends = this.limitStep;

        this.getReferralsUnfinished();
    }

    onTabChanged(event:any) {
        this.activeTabIndex = event.index;
        if (this.activeTabIndex === 1) {
            this.getReferralsFinished();
        } else if (this.activeTabIndex === 2) {
            this.getReferralsRecomended();
        } else {
            this.getReferralsUnfinished();
        }
    }

    backToList() {
        this.isViewRefInfo = false;
    }

    getDateFormatted(s: string): string {
        return H.getDateFormatted(s);
    }

    getFIO(user: User): string {
        return [user.lastName, user.firstName, user.middleName].filter(el => el).join(' ');
    }

    getFIOAssignedBy(user: ReferralAssignee): string {
        return [user.lastName, user.firstName, user.middleName].filter(el => el).join(' ');
    }

    getReferralsUnfinished() {
        this.userService.getReferrals(this.offsetUnfinished, this.limitUnfinished, Constants.REFERRAL_TYPE_REFERRAL, false).subscribe({
            next: (referrals) => {
                this.documentsUnfinished = referrals;
            }
        });

        this.offsetUnfinished = H.changeLimit(this.offsetUnfinished, this.limitStep);
    }

    getReferralsFinished() {
        this.userService.getReferrals(this.offsetFinished, this.limitFinished, Constants.REFERRAL_TYPE_REFERRAL, true).subscribe({
            next: (referrals) => {
                this.documentsFinished = referrals;
            }
        });

        this.offsetFinished = H.changeLimit(this.offsetFinished, this.limitStep);
    }

    getReferralsRecomended() {
        this.userService.getReferrals(this.offsetRecommends, this.limitRecommends, Constants.REFERRAL_TYPE_RECOMENDATION).subscribe({
            next: (referrals) => {
                this.documentsRecommends = referrals;
            }
        });

        this.offsetRecommends = H.changeLimit(this.offsetRecommends, this.limitStep);
    }

    openReferral(ref: Referral) {
        this.barcodeUrl = '';

        this.userService.getReferralInfo(ref).subscribe({
            next: (refInfo) => {
                this.refInfo = refInfo;
                this.isViewRefInfo = true;
                this.userService.getCases().subscribe({
                    next: (cases) => {
                        this.case = <Case>cases.find(c => c.id === refInfo.case.id);
                    }
                });

                this.userService.getReferralBarcode(refInfo.id).subscribe({
                    next: (value: any) => {
                        const reader = new FileReader();
                        reader.readAsBinaryString(value);
                        const addPreview = (fileBase64: any) => {
                            this.barcodeUrl = `data:${value.type};base64,${btoa(fileBase64)}`
                        };
                        reader.onload = function (e: any) {
                            addPreview(e.target.result);
                        }
                    }
                })  

            }
        })
    }
}
