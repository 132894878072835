import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContactsService } from '@app/services/contacts.service';
import { StorageService } from "@app/services/storage.service";
import { Contact } from '@app/models/Contacts';
import { MenuItem } from "primeng/api";
import {Constants} from "@app/helpers/constants";

declare const ymaps: any;

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  dayOfWeekMap: any = {
    'Monday': 'ПН',
    'Tuesday': 'ВТ',
    'Wednesday': 'СР',
    'Thursday': 'ЧТ',
    'Friday': 'ПТ',
    'Saturday': 'СБ',
    'Sunday': 'ВС'
  };
  dayTypeMap: any = {
    'Offday': 'Выходной',
    'Holiday': 'Праздничный',
  };

  contacts: string[] = [];
  allContacts: Contact[] = [];
  contact: Contact | null;
  selectedContact: string = '';
 
  items: MenuItem[];
  activeItem: MenuItem;
  @ViewChild('yamaps') el!: ElementRef;
  map: any;

  constructor(private storage: StorageService,
              private contactService: ContactsService) {
  }

  ngOnInit(): void {
    this.items = [
      {label: 'Главная', icon: 'pi pi-home', routerLink: '/home', routerLinkActiveOptions: {exact: true}},
      {label: 'Марш.лист', icon: 'pi pi-check-circle', routerLink: '/referrals'},
      {label: 'Запись', icon: 'pi pi-phone', routerLink: '/book'},
      {label: 'Мои данные', icon: 'pi pi-user', routerLink: '/my-data'},
      {label: 'Контакты', icon: 'pi pi-building', routerLink: '/contacts'},
      {label: '', styleClass: 'itemSep'},
      {
        label: 'Выход', icon: 'pi pi-sign-out', styleClass: 'stickRight', routerLink: '/logout', command: (event) => {
        }
      }
    ];
    this.contactService.getContacts().subscribe({
      next: (data) => {
        this.allContacts = data;
        ymaps.ready().done(() => this.createMap(this.allContacts));
      },
    });
    this.activeItem = this.items[0];
  }

  isLoggedIn() {
    return this.storage.isLoggedIn() && this.storage.getLoaded();
  }

  getOrgName(): string {
    return Constants.ORG_NAME;
  }

  getOrgDeptName(): string {
    return Constants.ORG_DEPT_NAME;
  }

  getContactByName(name: string): Contact | null {
    let c = this.allContacts.filter(el => el.name === name);
    if (c.length > 0) {
        return  c[0];
    }

    return null;
  }

  contactSelected(selectedContactName: string) {
    this.contact = this.getContactByName(selectedContactName);
    let coordinates = this.contact?.coordinates;
    if (coordinates) {
      this.map.setCenter(coordinates);
      this.map.setZoom(15);
    }
    return this.contact
  }

  searchContact(e: any) {
    if (e.hasOwnProperty('query') && e['query'].length > 0) {
      this.contacts = this.allContacts.filter(el => {
        return el.name.toLowerCase().indexOf(e['query'].toLowerCase()) !== -1;
      }).map(el => {
        return el.name;
      });
    } else {
      this.contacts = this.allContacts.map(el => {
        return el.name;
      });
    }
  }

  searchControl: any
  private createMap(contacts: Contact[]):void {
    this.map = new ymaps.Map('map', {
      center: [55.753113, 37.622263],
      zoom: 9,
      controls: []
    });

    this.addClusterer(contacts);
  }
  
  addClusterer(contacts: Contact[]) {

    let placemarks: any[] = [];

    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].coordinates) {
        placemarks[i] = new ymaps.Placemark(contacts[i].coordinates, {
          iconCaption: contacts[i].name,
          balloonContentHeader: contacts[i].name,
          balloonContentBody: [
            '<div>', contacts[i].address, '</div>',
            '<div>', contacts[i].phoneReception, '</div>'
          ].join('')
        },
        {
          preset: 'islands#redMedicalIcon'
        });
      }
    }

    var clusterer = new ymaps.Clusterer({
      preset: 'islands#redClusterIcons'
    });

    this.map.geoObjects.add(clusterer);
    clusterer.add(placemarks);
  }
}
