<div class="h-container">
    <div [ngClass]="{'h-wrapper': isLoggedIn(), 'wrapper__logout': !isLoggedIn(), 'h-wrapper-max': currentUrl =='/contacts' }">
        <div *ngIf="currentUrl !=='/contacts'; else showLogoContacts" [ngClass]="{'header': isLoggedIn(), 'header__logout': !isLoggedIn() }">
            <div [ngClass]="{'name-container': isLoggedIn(), 'name-container__logout': !isLoggedIn()}">
                <img
                    [ngClass]="{'logo': isLoggedIn(), 'logo__imgLogout': !isLoggedIn() }"
                    src="/assets/images/rzd_logo.png"
                    alt="логотип"
                    routerLink="/home"
                >
                <p-divider [ngClass]="{'divider': isLoggedIn(), 'divider__logout': !isLoggedIn()}"
                           layout="vertical"></p-divider>
                <div class="rzd_name">
                    <div [ngClass]="{'rzd-title': isLoggedIn(), 'rzd-title__logout': !isLoggedIn() }">
                        {{getOrgName()}}
                    </div>
                    <div [ngClass]="{'rzd-subtitle': isLoggedIn(), 'rzd-subtitle__logout': !isLoggedIn()}">
                        {{getOrgDeptName()}}
                    </div>
                </div>
            </div>
            <button *ngIf="isLoggedIn()" class="call-button">
                <a class="h-phone" href="tel:88007000701">8-800-700-0-701</a>
            </button>
        </div>
        <ng-template #showLogoContacts>
            <div class="header">
                <img
                    class="logo-contacts"
                    src="/assets/images/rzd_logo.png"
                    alt="логотип"
                    routerLink="/home"
                >
            </div>
        </ng-template>
        <p-divider *ngIf="isLoggedIn() && currentUrl !=='/contacts'" class="divider-h"></p-divider>
        <app-menu></app-menu>
    </div>
</div>