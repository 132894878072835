
<div class="container">
  <button class="ref-back-btn" routerLink="/my-data">
    <img alt="logo" src="./assets/images/ArrowBack.png"/>
    <span class="pl-3 uppercase p-button-label">Назад</span>
  </button>
    <div *ngFor="let questionnaire of questionnaires">
      <div
        class="name"
        [routerLink]="['/my-data/questioning', questionnaire.id]"
      >
      {{questionnaire.title}}
      </div>
    </div> 
</div>