import {Component, OnInit} from '@angular/core';
import {SharedService} from "@app/services/shared.service";
import {UserService} from "@app/services/user.service";
import {BookRecord} from "@app/models/BookRecord";
import {H} from "@app/helpers/helper";
import {ConfirmationService} from "primeng/api";

@Component({
    selector: 'app-bookings',
    templateUrl: './bookings.component.html',
    styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {
    activeTabIndex: number = 0;
    bookingsUncompleted: BookRecord[] = [];
    bookingsCompleted: BookRecord[] = [];
    showRecord: boolean = false;
    activeRecord: BookRecord;
    isBookCanceled: boolean = false;

    limitUncompleted: number = 0;
    limitCompleted: number = 0;
    offsetUncompleted: number = 0;
    offsetCompleted: number = 0;
    limitStep: number = 0;

    constructor(
        private sharedService: SharedService,
        private confirmationService: ConfirmationService,
        private userService: UserService,
    ) {
    }

    ngOnInit(): void {
        this.limitStep = H.isMobile() ? 3 : 300;
        this.limitUncompleted = this.limitStep;
        this.limitCompleted = this.limitStep;

        this.sharedService.sendBackButtonRoute('/my-data');

        this.getBookingsUncompleted();
        this.getBookingsCompleted();
    }

    getBookingsUncompleted() {
        this.userService.getBookings(this.offsetUncompleted, this.limitUncompleted, false).subscribe({
            next: (_items) => {
                this.bookingsUncompleted = _items;
            }
        })

        this.offsetUncompleted = H.changeLimit(this.offsetUncompleted, this.limitStep);
    }

    getBookingsCompleted() {
        this.userService.getBookings(this.offsetCompleted, this.limitCompleted, true).subscribe({
            next: (_items) => {
                this.bookingsCompleted = _items;
            }
        })

        this.offsetCompleted = H.changeLimit(this.offsetCompleted, this.limitStep);
    }

    openRecord(record: BookRecord) {
        this.activeRecord = record;
        this.showRecord = true;
    }

    closeRecord() {
        this.showRecord = false;
        this.sharedService.sendBackButtonRoute('/my-data');
    }

    getDateTimeFormatted(s: string): string {
        return H.getDateFormatted(s, true);
    }

    cancel(book: BookRecord) {
        this.userService.cancelBooking(book).subscribe({
            next: () => {
                this.getBookingsUncompleted();
                this.getBookingsCompleted();
            }
        });
    }

    confirm(book: BookRecord) {
        this.confirmationService.confirm({
            message: 'Вы уверены, что хотите отменить запись?',
            accept: () => {
                this.cancel(book);
                this.isBookCanceled = true;
            },
            acceptLabel: 'Да',
            rejectLabel: 'Нет',
            acceptIcon: "false",
            rejectIcon: "false"
        });
    }

    onTabChanged(event:any) {
        this.activeTabIndex = event.index;
    }

    onClose() {
        this.isBookCanceled = false
    }

}
