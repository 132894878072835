import {Component, OnInit} from '@angular/core';
import {User} from "@app/models/User";
import {MenuItem} from "primeng/api";
import {Document} from "@app/models/Document";
import {UserService} from "@app/services/user.service";
import {ActivatedRoute} from "@angular/router";
import {StorageService} from "@app/services/storage.service";
import {SharedService} from "@app/services/shared.service";
import {H} from "@app/helpers/helper";
import {Constants} from "@app/helpers/constants";
import {environment} from "@environments/environment";

@Component({
  selector: 'app-conclusions',
  templateUrl: './conclusions.component.html',
  styleUrls: ['./conclusions.component.scss']
})
export class ConclusionsComponent implements OnInit{
  user: User;
  tabs: MenuItem[];
  documents: Document[];
  isPdfViewerEnabled: boolean = false;
  pdfSrc: string;

  constructor(private userService: UserService,
              private storage: StorageService,
              private sharedService: SharedService,
              private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    const caseId: number = this.storage.getUser().lastCaseId
    
    let selectedCaseId: number = 0
    this.activatedRoute.params.subscribe(params => {
        selectedCaseId = params['caseId'];
    });

    this.tabs = H.getMedkartaTabs();

    if (selectedCaseId) {
      this.userService.getCaseDocuments(selectedCaseId, Constants.DOCUMENT_TYPE_DECISIONS).subscribe({
        next: (docs) => {
          this.documents = docs;
        }
      });
    } else {
      this.userService.getCaseDocuments(caseId, Constants.DOCUMENT_TYPE_DECISIONS).subscribe({
        next: (docs) => {
          this.documents = docs;
        }
      });
    }
    this.sharedService.sendBackButtonRoute('/my-data');
  }

  backToList() {
    this.isPdfViewerEnabled = false;
  }

  openDocument(doc: Document) {
    this.isPdfViewerEnabled = true;
    this.pdfSrc = `${environment.api_endpoint}/api/documents/${doc.id}/file` + '?access_token=' + this.storage.getUser().token;
  }
}
