import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-copd',
  templateUrl: './copd.component.html',
  styleUrls: ['../calcStyle.component.scss'],
})
export class CopdComponent implements OnInit {
  form: FormGroup
  score: number = 0
  result: string = ''

  ngOnInit() {
    this.form = new FormGroup({
      cigarette: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]+(?!.)/)]),
      smoking_history: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]+(?!.)/)]),
    })
  }
  
  showResult(score: number) {
    if (score > 10) {
      this.result = 'У Вас имеется достоверный фактор развития ХОБЛ (хронической обструктивной болезни легких)'
    } else {
      this.result = 'Вы не входите в группу риска развития ХОБЛ (хронической обструктивной болезни легких)'
    }
    return this.result
  }

  submit() {
    if (this.form.valid) {
      const formData = {...this.form.value}
      this.score = formData.cigarette * formData.smoking_history / 20
    }
    this.showResult(this.score)
    return this.score
  }

}